import React, { useState } from "react";
import "../css/Aboutus.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";
import InnerFooter from "../utils/InnerFooter";

export default function AboutUs() {
  return (
    <>
      <NavBar />
      <div className="about-main">
        <h2>About Us</h2>
        <p>
          Sanj App is an engaging and interactive learning platform crafted to
          introduce children to the fundamentals of Punjabi and English
          languages. With vibrant visuals, immersive activities, and dynamic
          lessons, the app transforms language learning into an enjoyable and
          impactful experience for kids of all ages. Our current focus is to
          offer an intuitive and accessible way for children to grasp these
          languages, with plans to expand and enhance the content in the near
          future.
        </p>

        <h2>Update Announcement</h2>
        <p>Hey Dear,</p>
        <p>
          We are excited to announce an upcoming update that will significantly
          enhance your experience with our platform. This update includes:
        </p>

        <h3>1. Hindi Language Feature:</h3>
        <p>
          In the next update of our app, we are excited to introduce a{" "}
          <strong>Hindi language feature</strong>. This addition aims to make
          learning even more accessible and engaging for a broader range of
          users. With Hindi being one of the most widely spoken languages in the
          world, this update will provide interactive lessons, engaging visuals,
          and activities specifically designed to help children and learners of
          all ages grasp the basics of Hindi. We are committed to offering a
          seamless learning experience and making our app more inclusive and
          impactful.
        </p>

        <h3>2. Enhanced Content Quality:</h3>
        <ul>
          <li>
            More in-depth content and resources tailored to your interests.
          </li>
          <li>Improved accuracy and relevance of information.</li>
        </ul>

        <h3>3. Detailed Information:</h3>
        <ul>
          <li>
            Expanded details on topics to provide comprehensive understanding.
          </li>
          <li>
            Additional data and insights to support your research and decisions.
          </li>
          <li>
            Clearer, more structured presentation of information for easier
            reading and comprehension.
          </li>
        </ul>

        <p>
          We believe these updates will provide a richer, more engaging
          experience for all our users. The upcoming update will be published by
          the second half of November 2024. We appreciate your continued support
          and are eager to bring you these exciting new features.
        </p>

        <p>
          Thank you for being a valued member of <strong>Sanj App</strong>.
        </p>
      </div>
      <InnerFooter />
    </>
  );
}
