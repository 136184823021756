import React, { useState } from "react";
import { FaBars } from "react-icons/fa"; // Hamburger icon from react-icons
import "../css/NavBar.css"; // Custom CSS for styling
import instaLogo from "../assets/insta_black.png";
import youtube from "../assets/youtube_black.png";
import headerImg from "../assets/logo_header.png";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="navbar__left">
        <div className="logo-nav">
          {/* <h3>Sanj - Basic Learning App</h3> */}
          <img src={headerImg} />
        </div>
      </div>

      <div className="navbar__right">
        <div className="hamburger" onClick={toggleMenu}>
          <FaBars />
        </div>
        <ul className={isMobile ? "navbar__list active" : "navbar__list"}>
          <a href="/">
            <li> Home</li>
          </a>

          <a onClick={() => navigate("/Learning")}>
            <li>Learning</li>
          </a>

          {/* <a>
            <li>Games</li>
          </a> */}

          <a onClick={() => navigate("/Socialvibes")}>
            <li>Social Vibes</li>
          </a>

          <li>
            <a href="https://youtube.com/@sanj-basic-learning" target="_blank">
              <img src={youtube} alt="App Logo" class="nav-icon" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/sanj_learing_app?igsh=MWhyM2ltY2ZmZ3o4dg%3D%3D&utm_source=qr"
              target="_blank"
            >
              <img src={instaLogo} alt="App Logo" class="nav-icon" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
