import React from "react";
import "../css/Aboutus.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";

export default function ContactUs() {
  return (
    <>
      <NavBar />
      <div className="about-main">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms and Conditions, you can
          contact us at{" "}
          <a href="mailto:app92innovation@gmail.com">
            app92innovation@gmail.com
          </a>
        </p>
        <p>
          <h3>Credits:</h3>
        </p>
        <p>
          Developed by <b>Sarabjeet Singh</b>
          <br />
          Inspired by <b>Gursanj Kaur</b>
        </p>
        <br />
        <h3>
          <strong>Attributes:</strong>
        </h3>
        <p>Icons and graphics provided by:</p>
        <ul>
          <li>
            <a href="https://www.freepngimg.com" target="_blank">
              https://www.freepngimg.com
            </a>
          </li>
          <li>
            <a href="https://www.freepik.com" target="_blank">
              https://www.freepik.com
            </a>
          </li>
          <li>
            <a href="https://www.flaticon.com" target="_blank">
              https://www.flaticon.com
            </a>
          </li>
        </ul>

        <h3>
          <strong>Contact Us</strong>
        </h3>
        <p>
          We welcome your feedback, questions, and suggestions! If you have any
          inquiries or need assistance, please feel free to reach out to us via
          email at
          <a href="mailto:app92innovation@gmail.com">
            app92innovation@gmail.com
          </a>
          . We strive to respond to all inquiries promptly and look forward to
          hearing from you!
        </p>
      </div>
      <Footer />
    </>
  );
}
