import React from "react";
import "../css/Aboutus.css";

import NavBar from "../utils/NavBar";
import InnerFooter from "../utils/InnerFooter";

export default function TermsAndConditions() {
  return (
    <>
      <NavBar />
      <div className="about-main">
        <h2>Terms and Conditions</h2>
        <p>
          By using this app, you agree to comply with and be bound by the
          following terms and conditions of use. Please read these terms and
          conditions carefully before using this app.
        </p>

        <p>
          <h3>1. Acceptance of Terms</h3>
        </p>
        <p>
          By accessing or using this app, you agree to be bound by these Terms
          and Conditions and all applicable laws and regulations. If you do not
          agree with any of these terms, you are prohibited from using or
          accessing this app.
        </p>

        <p>
          <h3>2. Use License</h3>
        </p>
        <p>
          Permission is granted to temporarily download one copy of the app for
          personal, non-commercial transitory viewing only. This is the grant of
          a license, not a transfer of title, and under this license, you may
          not:
        </p>
        <ul>
          <li>Modify or copy the materials;</li>
          <li>
            Use the materials for any commercial purpose or for any public
            display (commercial or non-commercial);
          </li>
          <li>
            Attempt to decompile or reverse engineer any software contained in
            the app;
          </li>
          <li>
            Remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            Transfer the materials to another person or "mirror" the materials
            on any other server.
          </li>
        </ul>

        <p>
          <h3>3. Disclaimer</h3>
        </p>
        <p>
          The materials in this app are provided on an "as is" basis. The app
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties, including without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, or non-infringement of intellectual property or other
          violation of rights. Further, the app does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its app or otherwise
          relating to such materials or on any sites linked to this app.
        </p>

        <p>
          <h3>4. Limitations</h3>
        </p>
        <p>
          In no event shall the app be liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to
          business interruption) arising out of the use or inability to use the
          materials on the app, even if the app or an authorized representative
          has been notified orally or in writing of the possibility of such
          damage.
        </p>

        <p>
          <h3>5. Revisions and Errata</h3>
        </p>
        <p>
          The materials appearing in this app could include technical,
          typographical, or photographic errors. The app does not warrant that
          any of the materials on its app are accurate, complete, or current.
          The app may make changes to the materials contained in this app at any
          time without notice. The app does not, however, make any commitment to
          update the materials.
        </p>

        <p>
          <h3>6. Governing Law</h3>
        </p>
        <p>
          Any claim relating to the app shall be governed by the laws of the app
          owners country without regard to its conflict of law provisions.
        </p>

        <p>
          <h3>7. Modifications to Terms of Use</h3>
        </p>
        <p>
          The app owner may revise these terms of use for its app at any time
          without notice. By using this app, you are agreeing to be bound by the
          then-current version of these Terms and Conditions of Use.
        </p>

        <p>
          <h3>8. Contact Us</h3>
        </p>
        <p>
          If you have any questions about these Terms and Conditions, you can
          contact us at{" "}
          <a href="mailto:app92innovation@gmail.com">
            app92innovation@gmail.com
          </a>
        </p>

        <p>
          <h3>Credits:</h3>
        </p>
        <p>
          Developed by <b>Sarabjeet Singh</b>
          <br />
          Inspired by <b>Gursanj Kaur</b>
        </p>
        <br />
        <p>
          <h3>Attributes:</h3>
        </p>
        <p>Icons and graphics provided by:</p>
        <ul>
          <li>
            <a href="https://www.freepngimg.com" target="_blank">
              https://www.freepngimg.com
            </a>
          </li>
          <li>
            <a href="https://www.freepik.com" target="_blank">
              https://www.freepik.com
            </a>
          </li>
          <li>
            <a href="https://www.flaticon.com" target="_blank">
              https://www.flaticon.com
            </a>
          </li>
        </ul>
      </div>
      <InnerFooter />
    </>
  );
}
