import React from "react";
import abc from "../assets/ic_abc.png";
import appIcon from "../assets/app_icon.png";
import math from "../assets/ic_math.png";
import hindi from "../assets/ic_hindi.png";
import "../css/Learning.css";
import NavBar from "../utils/NavBar";
import { useNavigate } from "react-router-dom";
import Footer from "../utils/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Learning() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <NavBar />
      <div className="main-container-h">
        <div className="lower-container-h">
          <h3
            className="main-heading-h"
            style={{ marginTop: "20px", textAlign: "justify" }}
          >
            EXPLORE & LEARN
          </h3>

          <div className="box-containt-h">
            <div className="box-home" onClick={() => navigate("/Punjabi")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>Punjabi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("/Hindi")}>
              <img className="icon" src={hindi} alt="" />
              <h2>Hindi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("/English")}>
              <img className="icon" src={abc} alt="" />
              <h2>English</h2>
            </div>
            <div className="box-home" onClick={() => navigate("/Maths")}>
              <img className="icon" src={math} alt="" />
              <h2>Math</h2>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
