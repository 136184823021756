import React, { useEffect, useState } from "react";
import "../css/Wishes.css";
import InnerFooter from "../utils/InnerFooter";

import NavBar from "../utils/NavBar";

export default function Wish() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  // Step 1: Set up state to store the selected category
  const [selectCat, setSelectCat] = useState("birthdayWishes"); // Default to "fruits"

  // Step 2: Handle the click to change the category
  const handleSelecttab = (categoryId) => {
    setSelectCat(categoryId);
  };

  const [selectedCategory, setSelectedCategory] = useState("birthdayWishes");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    console.log(event.target.value);
  };

  const wishList = [
    { id: 1, msg: "Happy Birthday", category: "birthdayWishes" },
    { id: 2, msg: "Engagement", category: "engagementList" },
    { id: 3, msg: "Marriage", category: "marriageList" },
    { id: 4, msg: "Marriage Anniversary", category: "marriageAnniversaryList" },
    { id: 5, msg: "New Job", category: "jobList" },
    { id: 18, msg: "Diwali Wishes", category: "diwaliWishList" },
    { id: 6, msg: "Career Promotion", category: "promotionList" },
    { id: 7, msg: "New Home Blessing", category: "newHomeBlessingList" },
    { id: 8, msg: "Baby's Arrival", category: "babyArrivalList" },
    { id: 9, msg: "Retirement Celebration", category: "retirementWish" },
    { id: 10, msg: "Graduation Day", category: "graduationWish" },
    { id: 11, msg: "Wishing You Good Luck", category: "goodLuckWish" },
    { id: 12, msg: "Quick Recovery Wishes", category: "quickRecovery" },
    { id: 13, msg: "Farewell Party", category: "farewellParty" },
    { id: 14, msg: "Work Anniversary", category: "workAnniversary" },
    { id: 15, msg: "New Year's Eve", category: "newYearEve" },
    { id: 16, msg: "Christmas Wishes", category: "christmas" },
    { id: 17, msg: "Eid Mubarak", category: "eidMubarak" },
    { id: 19, msg: "Valentine's Day", category: "valentinesDay" },
    { id: 20, msg: "Heartfelt Thanks", category: "heartfeltThanks" },
  ];

  const categories = {
    //TODO Wishes
    birthdayWishes: [
      "Happy Birthday, xxxxxxxxxx! Wishing you an amazing day full of laughter, joy, and love. May this special day mark the beginning of an incredible year filled with success and happiness. Here's to creating unforgettable memories and achieving your dreams! 🎂.",
      "On your birthday, xxxxxxxxxx, I wish you all the best things life has to offer. May you be surrounded by love, laughter, and positive energy today and always. May this year bring you closer to your dreams and fill your life with unforgettable moments. 🎂.",
      "Happy Birthday to you, xxxxxxxxxx! May your day be filled with sweet moments, surrounded by friends and family who cherish you. Wishing you a year ahead full of happiness, health, and prosperity. Keep shining bright and making the world a better place. 🎂.",
      "Happy Birthday, xxxxxxxxxx! Today is a special day, just like you. May it be filled with laughter, love, and the warmth of family and friends. Here's wishing you a year ahead full of exciting adventures, happiness, and all the success you deserve. 🎂.",
      "Wishing you a very Happy Birthday, xxxxxxxxxx! May your day be as amazing as you are. Here's to a year filled with wonderful surprises, endless laughter, and great success in everything you do. Keep shining, and enjoy your special day to the fullest! 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope your day is filled with laughter, joy, and all the little things that make you smile. May this year bring you new opportunities, growth, and success. Never stop dreaming big and reaching for the stars. Enjoy every moment today! 🎂.",
      "Happy Birthday to a truly special person, xxxxxxxxxx! May your day be filled with happiness, warmth, and unforgettable memories. I hope this year brings you closer to your dreams and fills your life with love, health, and laughter. You deserve all the best! 🎂.",
      "To the most amazing person, xxxxxxxxxx, Happy Birthday! May your day be filled with sweet moments and laughter. Wishing you a year of success, good health, and love. Keep reaching for the stars and remember that you have the power to make your dreams come true. 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your special day be as bright and amazing as you are. Here's to a year filled with good times, great memories, and success in everything you set your mind to. Enjoy your day and all the blessings that come with it! 🎂.",
      "Wishing you the happiest of birthdays, xxxxxxxxxx! May this year bring you endless opportunities and exciting adventures. May you always be surrounded by love, joy, and people who appreciate you for the amazing person you are. Keep shining, and have an incredible year ahead! 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope this day brings you happiness and joy as bright as your smile. Here's to a year filled with exciting opportunities, personal growth, and love. May you achieve everything you’ve been working hard for. Enjoy every second of your special day! 🎂.",
      "On your special day, xxxxxxxxxx, I wish you love, happiness, and all the success you deserve. May this year be filled with countless blessings, wonderful memories, and exciting moments. Stay positive and continue to chase your dreams with all your heart. 🎂.",
      "Happy Birthday to you, xxxxxxxxxx! May your heart be filled with joy today and every day. Wishing you a year full of love, laughter, and success. Keep making beautiful memories and enjoying life to the fullest. Here’s to an amazing year ahead! 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope your day is as fantastic as you are. May this year bring you new opportunities, happiness, and success in every endeavor. Keep shining and spreading your kindness to those around you. Have a wonderful year ahead filled with joy! 🎂.",
      "To the incredible xxxxxxxxxx, Happy Birthday! May your day be filled with laughter and love. I wish you all the best as you embark on another year of adventures, learning, and growth. May you be surrounded by happiness, and may all your dreams come true! 🎂.",
      "Wishing you a birthday filled with sweet memories and joyful moments, xxxxxxxxxx! May you always stay true to yourself and follow your heart. May this year bring you closer to your goals and dreams. I hope it’s filled with happiness, success, and endless blessings! 🎂.",
      "Happy Birthday, xxxxxxxxxx! May you be surrounded by love, laughter, and everything that makes you smile. Here's wishing you a year full of health, wealth, and success. Keep dreaming big and never stop believing in yourself. Enjoy every moment of your special day! 🎂.",
      "Happy Birthday to a truly special person, xxxxxxxxxx! I wish you a year full of love, joy, and success in all areas of your life. May each day be filled with laughter, adventure, and beautiful moments. You deserve nothing but the best. Enjoy your day! 🎂.",
      "Happy Birthday, xxxxxxxxxx! On your special day, I wish you all the happiness in the world. May this year bring you growth, success, and many blessings. Keep shining your light and spreading joy to everyone you meet. Wishing you an unforgettable birthday! 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your day be filled with love, laughter, and all your favorite things. Wishing you a year full of amazing adventures, good health, and endless opportunities. Enjoy every moment of today and all that’s to come in the year ahead! 🎂.",
      "Wishing you the happiest birthday, xxxxxxxxxx! May this year be filled with new beginnings, personal growth, and exciting adventures. May you find success in everything you do and may your heart always be filled with joy. Here’s to a year of amazing moments! 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your day be filled with the love and happiness you bring to others. Wishing you a year ahead full of exciting opportunities, blessings, and new adventures. Keep being the incredible person you are and enjoy your special day! 🎂.",
      "Happy Birthday, xxxxxxxxxx! May today bring you endless happiness and wonderful memories. Here's to a year of growth, success, and everything you’ve always dreamed of. Enjoy every moment and celebrate the amazing person you are. Wishing you all the best today and always! 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope your day is filled with love, joy, and everything that makes you happy. May this year bring you peace, success, and all the love you deserve. Here's to another year of creating beautiful memories and making your dreams come true! 🎂.",
      "Happy Birthday, xxxxxxxxxx! Wishing you a day filled with love, joy, and laughter 🎂.",
      "May your special day be filled with happiness, xxxxxxxxxx. Happy Birthday 🎂!",
      "Here's to celebrating you, xxxxxxxxxx! Have a fantastic birthday 🎂!",
      "Wishing you all the best on your birthday, xxxxxxxxxx. Enjoy every moment 🎂!",
      "Happy Birthday, xxxxxxxxxx! May your year ahead be full of success and happiness 🎂.",
      "Cheers to you, xxxxxxxxxx! Hope your birthday is as amazing as you are 🎂.",
      "Wishing you a wonderful birthday, xxxxxxxxxx. May all your dreams come true 🎂!",
      "Happy Birthday to the one and only, xxxxxxxxxx! Enjoy your day to the fullest 🎂.",
      "May your birthday be as special as you, xxxxxxxxxx. Wishing you an unforgettable day 🎂!",
      "On your special day, xxxxxxxxxx, I wish you happiness, health, and a bright future ahead 🎂.",
      "Happy Birthday, xxxxxxxxxx! Here's to another year of great memories and success 🎂.",
      "Wishing you all the happiness in the world, xxxxxxxxxx. Enjoy every minute of your birthday 🎂!",
      "Another year older, but definitely another year wiser. Happy Birthday, xxxxxxxxxx! 🎂",
      "Happy Birthday, xxxxxxxxxx! May this year bring you everything you desire 🎂.",
      "To a wonderful person, xxxxxxxxxx, may your birthday be as amazing as you are 🎂!",
      "Wishing you a magical birthday, xxxxxxxxxx! May it be filled with joy and love 🎂.",
      "Happy Birthday to the amazing xxxxxxxxxx! May your year be full of adventure and growth 🎂.",
      "Here’s wishing you a birthday as special as you are, xxxxxxxxxx! 🎂",
      "Sending you a big birthday hug, xxxxxxxxxx! Have a fantastic celebration 🎂!",
      "Happy Birthday, xxxxxxxxxx! May your day be filled with love, joy, and laughter 🎂.",
      "On your special day, xxxxxxxxxx, I hope all your dreams come true. Happy Birthday 🎂!",
      "Happy Birthday, xxxxxxxxxx! Wishing you a year full of health and happiness 🎂.",
      "May your birthday be the start of a year filled with happiness and success, xxxxxxxxxx 🎂!",
      "Happy Birthday, xxxxxxxxxx! Here's to celebrating you and all that you do 🎂.",
      "Wishing you the happiest of birthdays, xxxxxxxxxx! May you have an amazing year ahead 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your day be as extraordinary as you are 🎂.",
      "To my dearest friend xxxxxxxxxx, wishing you the happiest birthday ever 🎂!",
      "Happy Birthday, xxxxxxxxxx! May the year ahead be full of blessings and joy 🎂.",
      "Another year, another adventure. Happy Birthday, xxxxxxxxxx! 🎂",
      "May your birthday be filled with sweet moments, xxxxxxxxxx. Enjoy every second 🎂!",
      "Happy Birthday, xxxxxxxxxx! Wishing you laughter, love, and everything you deserve 🎂.",
      "Have a wonderful birthday, xxxxxxxxxx! Here's to many more years of happiness 🎂.",
      "Happy Birthday to a truly special person, xxxxxxxxxx! Enjoy your big day 🎂!",
      "Wishing you the happiest of birthdays, xxxxxxxxxx! May all your wishes come true 🎂.",
      "Happy Birthday, xxxxxxxxxx! Here's to another year of incredible memories 🎂.",
      "On your birthday, xxxxxxxxxx, may you receive all the love and joy you give to others 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your year be filled with unforgettable moments 🎂.",
      "Wishing you an amazing birthday, xxxxxxxxxx! May all your dreams come true 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your day be as bright and beautiful as you are 🎂.",
      "Cheers to another fantastic year ahead, xxxxxxxxxx! Happy Birthday 🎂!",
      "Wishing you a birthday filled with love, laughter, and joy, xxxxxxxxxx! 🎂",
      "Happy Birthday, xxxxxxxxxx! Here's to new beginnings and exciting adventures 🎂.",
      "To my best friend xxxxxxxxxx, may your birthday be as wonderful as you are 🎂!",
      "Happy Birthday, xxxxxxxxxx! Here's to making more beautiful memories together 🎂.",
      "Wishing you a fabulous birthday, xxxxxxxxxx! Hope this year brings you happiness 🎂.",
      "Happy Birthday to someone who brings joy to everyone they meet, xxxxxxxxxx! 🎂",
      "Have a fantastic birthday, xxxxxxxxxx! May this year be your best one yet 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope this year brings you all the success and happiness 🎂.",
      "Wishing you a birthday filled with smiles and laughter, xxxxxxxxxx! 🎂",
      "Happy Birthday, xxxxxxxxxx! May you find success in all that you do this year 🎂.",
      "Sending you love and birthday wishes, xxxxxxxxxx! Have a wonderful day 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your heart always be full of happiness and love 🎂.",
      "Wishing you the happiest of birthdays, xxxxxxxxxx! Enjoy every moment 🎂.",
      "Happy Birthday to someone very special, xxxxxxxxxx! May all your wishes come true 🎂.",
      "To a wonderful friend, xxxxxxxxxx, may this birthday be as extraordinary as you 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope this year brings you everything you've been hoping for 🎂.",
      "Wishing you a birthday as amazing as you are, xxxxxxxxxx! Enjoy every minute 🎂.",
      "Happy Birthday, xxxxxxxxxx! May this year be filled with joy, peace, and prosperity 🎂.",
      "On your birthday, xxxxxxxxxx, I wish you a day full of laughter and celebration 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your year ahead be full of love and positivity 🎂.",
      "Cheers to you, xxxxxxxxxx! Wishing you a birthday as amazing as you are 🎂.",
      "Wishing you all the love and happiness in the world, xxxxxxxxxx. Happy Birthday 🎂!",
      "Happy Birthday to one of the best people I know, xxxxxxxxxx. Have an amazing day 🎂!",
      "To the most amazing person, xxxxxxxxxx, may your birthday be as bright as you are 🎂!",
      "Happy Birthday, xxxxxxxxxx! May this year bring you everything you've been hoping for 🎂.",
      "Wishing you the happiest birthday, xxxxxxxxxx! Enjoy your special day to the fullest 🎂.",
      "Happy Birthday, xxxxxxxxxx! Here's to another year of fun, laughter, and adventures 🎂.",
      "May your birthday be filled with love, joy, and unforgettable moments, xxxxxxxxxx 🎂.",
      "Happy Birthday to a true friend, xxxxxxxxxx! I hope this year brings you happiness 🎂.",
      "Wishing you a fantastic birthday, xxxxxxxxxx! Here's to many more years of friendship 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your day be filled with happiness and joy 🎂.",
      "To the wonderful xxxxxxxxxx, wishing you a birthday that’s just as amazing as you are 🎂.",
      "Happy Birthday, xxxxxxxxxx! May you continue to grow and shine in all that you do 🎂.",
      "Wishing you a day full of smiles and laughter, xxxxxxxxxx. Happy Birthday 🎂!",
      "Happy Birthday to someone who makes the world a better place, xxxxxxxxxx! 🎂",
      "May your birthday be just the beginning of a year filled with love, joy, and success 🎂.",
      "Happy Birthday, xxxxxxxxxx! I hope your day is as special as you are 🎂.",
      "Wishing you the most fantastic birthday, xxxxxxxxxx! May all your dreams come true 🎂.",
      "Happy Birthday, xxxxxxxxxx! Here's to another wonderful year ahead 🎂.",
      "Wishing you a birthday filled with all the things you love, xxxxxxxxxx! 🎂",
      "Happy Birthday, xxxxxxxxxx! May this year bring you everything you wish for 🎂.",
      "To my amazing friend xxxxxxxxxx, happy birthday! Wishing you an incredible year ahead 🎂.",
      "Happy Birthday, xxxxxxxxxx! Hope you have a day filled with love, fun, and laughter 🎂.",
      "Wishing you the happiest birthday, xxxxxxxxxx! Enjoy every moment of your special day 🎂.",
      "Happy Birthday to the incredible xxxxxxxxxx! May your day be as amazing as you are 🎂.",
      "To my dear friend, xxxxxxxxxx, happy birthday! Wishing you a wonderful year ahead 🎂.",
      "Happy Birthday, xxxxxxxxxx! May this day be filled with love, joy, and beautiful moments 🎂.",
      "Wishing you all the best on your birthday, xxxxxxxxxx! Enjoy your day to the fullest 🎂.",
      "Happy Birthday to the person who makes everything brighter, xxxxxxxxxx! 🎂",
      "Happy Birthday, xxxxxxxxxx! Wishing you a year filled with blessings and great memories 🎂.",
      "To the awesome xxxxxxxxxx, happy birthday! I hope you have an unforgettable day 🎂.",
      "Happy Birthday, xxxxxxxxxx! May your year be full of opportunities and new adventures 🎂.",
      "Wishing you the happiest of birthdays, xxxxxxxxxx! May this year be your best one yet 🎂.",
      "Happy Birthday, xxxxxxxxxx! Wishing you all the happiness and success in the world 🎂.",
    ],

    engagementList: [
      "Congratulations on your engagement, xxxxxxxxxx! Wishing you a lifetime of love and happiness together. May your journey be filled with wonderful memories and beautiful moments as you build your future together. ❤️.",
      "Happy Engagement, xxxxxxxxxx! May this be the beginning of a beautiful chapter in your lives. Wishing you both endless love, laughter, and an amazing future together. Here's to a lifetime of happiness! 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! You two make such a perfect pair. Wishing you both a wonderful journey ahead as you embark on this new adventure together. May your love continue to grow stronger every day. 💍.",
      "So happy for you on your engagement, xxxxxxxxxx! May your love always be as beautiful as it is today. Wishing you both a lifetime of happiness, laughter, and unforgettable moments together. 💍.",
      "Happy Engagement, xxxxxxxxxx! Your love story is just beginning, and I’m sure it’s going to be a beautiful one. Wishing you both a lifetime of joy, laughter, and endless love. Congratulations to both of you! 💍.",
      "Congrats on your engagement, xxxxxxxxxx! This is just the beginning of an incredible journey. May your love for each other continue to grow stronger with every passing day. Wishing you both a future full of love and happiness. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! Wishing you both a beautiful journey ahead filled with love, laughter, and all the happiness you deserve. Cheers to the wonderful life you are about to begin together! 💍.",
      "Happy Engagement to you both, xxxxxxxxxx! May this be the beginning of a wonderful journey filled with love, joy, and unforgettable memories. Wishing you all the happiness and blessings as you start this new chapter together. 💍.",
      "Cheers to your engagement, xxxxxxxxxx! You both are meant to be together. Wishing you a lifetime of love, joy, and laughter as you embark on this exciting new adventure together. 💍.",
      "So happy for you, xxxxxxxxxx, on your engagement! May the love between you two continue to flourish and bring you endless happiness. Wishing you both a future full of love, laughter, and beautiful moments. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! This is a moment to celebrate the love and bond you share. Wishing you both a lifetime of happiness, good health, and all the love in the world as you start this new chapter. 💍.",
      "Happy Engagement, xxxxxxxxxx! May your love for each other continue to grow with every passing year. Wishing you both a lifetime filled with happiness, adventure, and unforgettable memories together. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! May your love story continue to unfold with beautiful moments and cherished memories. Wishing you both a future filled with laughter, joy, and endless love. 💍.",
      "Wishing you both a lifetime of love and happiness, xxxxxxxxxx! Your engagement marks the beginning of an amazing journey. May your love continue to grow stronger with every passing day. 💍.",
      "Happy Engagement to the happy couple, xxxxxxxxxx! May your love for each other grow deeper with every passing day. Here's to building a beautiful life together filled with love, laughter, and adventures. 💍.",
      "Congrats on your engagement, xxxxxxxxxx! Wishing you both all the love and happiness in the world. May this be the beginning of a lifelong journey filled with beautiful memories and joy. 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both endless love and happiness as you begin this beautiful chapter of your lives. May your hearts always be full of love for each other. 💍.",
      "Congratulations, xxxxxxxxxx, on your engagement! May the future bring you both much joy, laughter, and love. Here's to a beautiful life together full of cherished memories and endless adventures. 💍.",
      "Wishing you both a lifetime of love, happiness, and beautiful moments, xxxxxxxxxx! Your engagement is just the beginning of an exciting adventure together. May your love continue to grow stronger every day. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! You two are the perfect match. Wishing you both a future full of love, laughter, and unforgettable moments. May this special time bring you closer to your dreams. 💍.",
      "So happy for you both on your engagement, xxxxxxxxxx! Wishing you a future filled with love, happiness, and success. May your love for each other continue to grow stronger each day. 💍.",
      "Happy Engagement, xxxxxxxxxx! Your love is inspiring, and I’m sure your future together will be just as beautiful. Wishing you both a lifetime of love, joy, and adventures together. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! Wishing you both a lifetime of love, laughter, and countless memories. May this new chapter in your life be everything you’ve ever dreamed of. 💍.",
      "Wishing you a lifetime of love, joy, and adventures, xxxxxxxxxx! May your engagement mark the beginning of an extraordinary journey filled with happiness, laughter, and love. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! Your love story is just beginning, and I can't wait to see all the amazing moments you will create together. Wishing you both a future full of joy and success. 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both a lifetime of love and happiness. May your hearts always be filled with warmth, and may your love continue to grow stronger each day. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! I’m so excited for you both. May this new chapter in your life be filled with laughter, love, and unforgettable memories. 💍.",
      "Wishing you both endless love and happiness on your engagement, xxxxxxxxxx! May the future bring you all the success, joy, and love that you deserve. Enjoy every moment of this special time together. 💍.",
      "Happy Engagement, xxxxxxxxxx! May this be the beginning of a beautiful life together, filled with love, joy, and all the wonderful things life has to offer. Wishing you both happiness now and always. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! May the road ahead be filled with love, joy, and endless memories that you will cherish forever. Here's to a bright future together! 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both a life full of love, laughter, and all the happiness in the world. May this be the start of something truly special. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! May the future bring you both much joy, laughter, and love. Here's to a beautiful life ahead filled with happiness and cherished memories. 💍.",
      "Cheers to your engagement, xxxxxxxxxx! May your life together be full of love, adventure, and beautiful moments. Here's to a future that is bright and full of happiness. 💍.",
      "Wishing you both a lifetime of love and happiness on your engagement, xxxxxxxxxx! Your love is inspiring, and I know you will build a beautiful future together. 💍.",
      "Congratulations, xxxxxxxxxx! May your engagement bring you joy, happiness, and all the love you deserve. Here's to building a lifetime of beautiful memories together. 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both a wonderful journey ahead filled with love, laughter, and adventure. May every moment be special and memorable. 💍.",
      "Wishing you a lifetime of love, laughter, and happiness, xxxxxxxxxx! May your engagement be the beginning of a beautiful future together. Cheers to your love and commitment! 💍.",
      "Happy Engagement, xxxxxxxxxx! May your future together be filled with all the love and happiness in the world. Wishing you both an amazing journey ahead. 💍.",
      "Congrats on your engagement, xxxxxxxxxx! May your love for each other continue to grow stronger with each passing day. Wishing you a lifetime of love, joy, and beautiful memories together. 💍.",
      "Happy Engagement, xxxxxxxxxx! I’m so excited for both of you as you begin this new chapter in your lives. Wishing you a lifetime of love, happiness, and beautiful moments to cherish forever. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! Your love for each other is truly inspiring. May the bond you share only grow stronger as you embark on this incredible journey together. 💍.",
      "Wishing you both a lifetime of happiness and love, xxxxxxxxxx! Your engagement is just the beginning of a wonderful adventure together. May your lives be filled with joy and beautiful memories. 💍.",
      "Happy Engagement, xxxxxxxxxx! May your love for each other continue to bloom and grow every single day. Here's to a future filled with love, laughter, and amazing adventures. 💍.",
      "Congratulations, xxxxxxxxxx, on your engagement! Wishing you both a lifetime of love, laughter, and beautiful memories. May this new chapter in your life be everything you’ve ever dreamed of. 💍.",
      "Wishing you a lifetime of love, joy, and adventures, xxxxxxxxxx! May your engagement mark the beginning of an extraordinary journey filled with happiness, laughter, and love. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! Your love story is just beginning, and I can't wait to see all the amazing moments you will create together. Wishing you both a future full of joy and success. 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both a lifetime of love and happiness. May your hearts always be filled with warmth, and may your love continue to grow stronger each day. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! I’m so excited for you both. May this new chapter in your life be filled with laughter, love, and unforgettable memories. 💍.",
      "Wishing you both endless love and happiness on your engagement, xxxxxxxxxx! May the future bring you all the success, joy, and love that you deserve. Enjoy every moment of this special time together. 💍.",
      "Happy Engagement, xxxxxxxxxx! May this be the beginning of a beautiful life together, filled with love, joy, and all the wonderful things life has to offer. Wishing you both happiness now and always. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! May the road ahead be filled with love, joy, and endless memories that you will cherish forever. Here's to a bright future together! 💍.",
      "Happy Engagement, xxxxxxxxxx! Wishing you both a life full of love, laughter, and all the happiness in the world. May this be the start of something truly special. 💍.",
      "Congratulations on your engagement, xxxxxxxxxx! May the future bring you both much joy, laughter, and love. Here's to a beautiful life ahead filled with happiness and cherished memories. 💍.",
    ],

    marriageList: [
      "Congratulations on your marriage, xxxxxxxxxx! May your wedding day be the beginning of a lifetime filled with love, laughter, and joy. 💍💐",
      "Wishing you both a lifetime of happiness and togetherness, xxxxxxxxxx! May your wedding day be just the start of a wonderful journey together. 💖💍",
      "Happy Wedding Day, xxxxxxxxxx! May your union be blessed with love, peace, and endless joy as you begin this new chapter together. 💐💍",
      "Congratulations on your marriage, xxxxxxxxxx! May the love you share today grow stronger and deeper with every passing year. 💍💖",
      "Wishing you a magical wedding day and a beautiful life ahead, xxxxxxxxxx! May your love for each other continue to grow and blossom forever. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! May your love for each other always shine as bright as it does today, and may you cherish each other forever. 💖💍",
      "Wishing you both endless happiness on your wedding day, xxxxxxxxxx! May your marriage be filled with love, laughter, and unforgettable moments. 💐💍",
      "Congratulations on your marriage, xxxxxxxxxx! May your wedding day be filled with joy and your life together be full of endless love and harmony. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! Today marks the start of your forever together. Wishing you both love, joy, and an amazing journey ahead. 💍💐",
      "Wishing you both a wonderful wedding day, xxxxxxxxxx! May the happiness you feel today continue throughout your entire married life. 💖💍",
      "Congratulations on your special day, xxxxxxxxxx! May your marriage be full of love, laughter, and all the beautiful moments life has to offer. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! May your marriage be a shining example of love, commitment, and understanding. Wishing you a lifetime of happiness together. 💍💖",
      "Wishing you a lifetime of love and happiness on your wedding day, xxxxxxxxxx! May your marriage be filled with unforgettable memories and endless joy. 💍💐",
      "Congratulations on your wedding, xxxxxxxxxx! May today be the beginning of a beautiful and everlasting love story. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May your marriage bring you both endless joy and fulfillment as you begin this exciting chapter of your lives. 💍💐",
      "Wishing you both the best on your wedding day, xxxxxxxxxx! May the love you share continue to grow and flourish every day of your life together. 💍💖",
      "Congratulations on your marriage, xxxxxxxxxx! May your wedding day be filled with love, laughter, and cherished memories that will last forever. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! May your marriage be blessed with love, joy, and all the things that make life beautiful. 💍💖",
      "Wishing you both a beautiful wedding day and a life filled with love, happiness, and togetherness, xxxxxxxxxx! May you continue to grow stronger together. 💍💐",
      "Congratulations on your wedding day, xxxxxxxxxx! May the years ahead be filled with love, joy, and incredible memories as you walk this journey together. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May your love story continue to unfold beautifully, and may your marriage be filled with endless happiness. 💍💐",
      "Wishing you a lifetime of happiness and love on your wedding day, xxxxxxxxxx! May your marriage be everything you’ve dreamed of and more. 💍💖",
      "Congratulations on your wedding, xxxxxxxxxx! Today is the start of your forever together, and I wish you both a lifetime of love, peace, and joy. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! Wishing you both an amazing life together filled with love, laughter, and wonderful memories. 💍💖",
      "Congratulations, xxxxxxxxxx, on your marriage! Wishing you both a happy and blessed life together, filled with love and harmony. 💍💐",
      "Wishing you both an unforgettable wedding day, xxxxxxxxxx! May your life together be full of joy, laughter, and all the love in the world. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May your marriage be a constant reminder of the beautiful love you share. Wishing you both a lifetime of happiness. 💍💐",
      "Congratulations, xxxxxxxxxx! May your wedding day be filled with cherished moments, and may your marriage be a lifelong journey of love and happiness. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! As you step into this new chapter together, may your hearts always be full of love and joy. 💍💐",
      "Wishing you both all the happiness in the world on your wedding day, xxxxxxxxxx! May your marriage be as wonderful and special as your love. 💍💖",
      "Congratulations on your wedding, xxxxxxxxxx! Wishing you both a future filled with love, joy, and all the blessings life has to offer. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! May your love and commitment to each other grow with every passing day. Wishing you a lifetime of happiness and success together. 💍💖",
      "Congratulations, xxxxxxxxxx, on your wedding day! May your love shine brighter than ever as you both embark on this beautiful journey together. 💍💐",
      "Wishing you both a beautiful and happy wedding day, xxxxxxxxxx! May your lives together be filled with love, joy, and unforgettable moments. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May your hearts always be full of love, and may your marriage bring you happiness and fulfillment beyond measure. 💍💐",
      "Congratulations on your marriage, xxxxxxxxxx! Wishing you a lifetime of love and happiness, and may your journey together be as wonderful as your love for each other. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! Wishing you both a marriage filled with love, laughter, and all the happiness in the world. 💍💐",
      "Congratulations on your wedding, xxxxxxxxxx! May your union be blessed with endless love and joy. Wishing you both a lifetime of happiness together. 💍💖",
      "Wishing you both a magical wedding day, xxxxxxxxxx! May your love for each other grow deeper with each passing day. 💍💐",
      "Congratulations, xxxxxxxxxx, on your wedding day! Wishing you both a lifetime of love, laughter, and many more beautiful moments. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May the love you share today only grow stronger and brighter as the years go by. 💍💐",
      "Wishing you both an amazing wedding day, xxxxxxxxxx! May your marriage be full of love, adventure, and endless happiness. 💍💖",
      "Congratulations on your wedding, xxxxxxxxxx! May today be the beginning of a long and happy journey together. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! Wishing you both a future filled with love, laughter, and beautiful memories. 💍💖",
      "Wishing you both all the best on your wedding day, xxxxxxxxxx! May your life together be filled with love and happiness. 💍💐",
      "Happy Wedding Day, xxxxxxxxxx! May your love for each other continue to grow and strengthen with every passing day. 💍💖",
      "Congratulations, xxxxxxxxxx! Wishing you a lifetime of happiness and love on your wedding day. 💍💐",
      "Wishing you both a beautiful wedding day, xxxxxxxxxx! May your marriage bring you endless joy and unforgettable memories. 💍💖",
      "Happy Wedding Day, xxxxxxxxxx! May your love continue to shine bright and bring joy to everyone around you. 💍💐",
    ],
    marriageAnniversaryList: [
      "Happy Anniversary, xxxxxxxxxx! May your love continue to grow stronger with each passing year. 💖",
      "Wishing you both a lifetime of happiness together. Happy Anniversary, xxxxxxxxxx! 💍💐",
      "Happy Anniversary, xxxxxxxxxx! May your love story continue to inspire those around you. 💖✨",
      "Cheers to another year of love, laughter, and happiness! Happy Anniversary, xxxxxxxxxx! 🥂💐",
      "Happy Anniversary to a couple who truly knows the meaning of love. Wishing you many more years of happiness, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your bond continue to shine brighter every year. 💖💐",
      "Wishing you both endless love and joy. Happy Anniversary, xxxxxxxxxx! 💍💐",
      "Happy Anniversary to a beautiful couple. Your love is a true inspiration, xxxxxxxxxx! 💖💍",
      "May your anniversary be filled with love and happiness, xxxxxxxxxx! Here's to many more years together. 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to bloom beautifully with each passing year. 💐💖",
      "Wishing you both a very happy anniversary, xxxxxxxxxx! May your love story continue forever. 💍💖",
      "Happy Anniversary to a couple that truly deserves the best in life, xxxxxxxxxx! 💍💐",
      "Here's to another year of making beautiful memories together. Happy Anniversary, xxxxxxxxxx! 💖✨",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to grow and inspire. 💍💖",
      "Wishing you both a wonderful anniversary filled with all the love and happiness you deserve, xxxxxxxxxx! 💐💖",
      "Happy Anniversary, xxxxxxxxxx! May your love be forever strong and beautiful. 💍💖",
      "Wishing you both a lifetime of love and joy on your special day. Happy Anniversary, xxxxxxxxxx! 💖💍",
      "Happy Anniversary to the most amazing couple, xxxxxxxxxx! Wishing you both all the happiness in the world. 💍💐",
      "May your love always stay as beautiful as it is today. Happy Anniversary, xxxxxxxxxx! 💖💍",
      "Happy Anniversary, xxxxxxxxxx! Wishing you both many more years of love and laughter. 💍💖",
      "Cheers to your everlasting love, xxxxxxxxxx! Wishing you a happy and blessed anniversary. 💐💍",
      "Happy Anniversary, xxxxxxxxxx! May your love grow deeper with each passing year. 💖💐",
      "Wishing you both an amazing anniversary filled with love and joy, xxxxxxxxxx! 💍💖",
      "Happy Anniversary to two people who are perfect for each other, xxxxxxxxxx! May your love last forever. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! May your hearts always beat as one. 💖💐",
      "Wishing you both a lifetime of happiness and love. Happy Anniversary, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May you continue to build beautiful memories together. 💐💖",
      "Wishing you both an extraordinary anniversary and many more years of love and happiness, xxxxxxxxxx! 💍💖",
      "Happy Anniversary to a couple who truly deserves all the best in life, xxxxxxxxxx! 💖💍",
      "Happy Anniversary, xxxxxxxxxx! Wishing you both many more years of love and adventure. 💐💖",
      "To the most wonderful couple, Happy Anniversary, xxxxxxxxxx! May your love continue to grow stronger. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! May your marriage be blessed with love, joy, and fulfillment forever. 💍💖",
      "Cheers to another year of love and laughter. Happy Anniversary, xxxxxxxxxx! 💖💐",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to shine bright and fill your life with happiness. 💍💖",
      "Wishing you both a very special anniversary filled with love and joy, xxxxxxxxxx! 💐💍",
      "Happy Anniversary to two beautiful souls, xxxxxxxxxx! May you continue to grow together in love and happiness. 💍💖",
      "Happy Anniversary, xxxxxxxxxx! Here's to many more years of happiness and love together. 💐💍",
      "Wishing you both a lifetime of happiness, laughter, and unforgettable memories. Happy Anniversary, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May the years ahead be filled with endless love and joy. 💖💐",
      "Wishing you both a beautiful anniversary filled with love and blessings, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to grow stronger with each passing day. 💐💖",
      "Wishing you both an incredible anniversary, xxxxxxxxxx! May your love forever remain true and strong. 💍💖",
      "Happy Anniversary to a couple who is truly meant to be, xxxxxxxxxx! Wishing you both endless happiness. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! May your love story continue to inspire and grow with every year. 💐💍",
      "Wishing you both all the happiness in the world on your anniversary, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! Here's to many more years of making beautiful memories together. 💖💐",
      "Congratulations on your anniversary, xxxxxxxxxx! Wishing you both a lifetime of love and happiness. 💍💖",
      "Happy Anniversary to a couple who makes love look so easy, xxxxxxxxxx! 💖💍",
      "Wishing you both a beautiful anniversary filled with love, joy, and special moments, xxxxxxxxxx! 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to grow and blossom year after year. 💖💐",
      "Wishing you both a magical anniversary and many more to come, xxxxxxxxxx! 💍💖",
      "Happy Anniversary to the most wonderful couple, xxxxxxxxxx! May your love be endless. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! Here's to love, laughter, and all the beautiful moments ahead. 💍💐",
      "Wishing you both a very happy anniversary, xxxxxxxxxx! May your love continue to flourish forever. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! May your life together always be filled with love, joy, and laughter. 💍💖",
      "Congratulations on another year of love and happiness, xxxxxxxxxx! Wishing you both an incredible anniversary. 💐💖",
      "Happy Anniversary, xxxxxxxxxx! May you both continue to write your beautiful love story for many more years to come. 💍💖",
      "Wishing you both an amazing anniversary, xxxxxxxxxx! Here's to love, laughter, and many more wonderful years together. 💖💍",
      "Happy Anniversary to the perfect couple, xxxxxxxxxx! Wishing you endless love and happiness. 💍💐",
      "Happy Anniversary, xxxxxxxxxx! May your love for each other never fade, and may your marriage grow stronger every year. 💖💍",
      "Wishing you both a lifetime of happiness on your anniversary, xxxxxxxxxx! 💍💐",
      "Happy Anniversary, xxxxxxxxxx! May your love always be your strength and your guiding light. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! Wishing you many more years of happiness and love. 💍💖",
      "Congratulations on another year of love and joy! Happy Anniversary, xxxxxxxxxx! 💖💐",
      "Happy Anniversary, xxxxxxxxxx! May your love always bring you joy and happiness, year after year. 💍💖",
      "Wishing you both an amazing anniversary, xxxxxxxxxx! May your love only grow deeper with time. 💖💍",
      "Happy Anniversary, xxxxxxxxxx! Wishing you both a lifetime of cherished moments and endless love. 💐💍",
      "Happy Anniversary to an amazing couple, xxxxxxxxxx! Wishing you both nothing but the best on this special day. 💍💖",
      "Wishing you both a joyful and beautiful anniversary, xxxxxxxxxx! Here's to many more years of love. 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your love continue to light up your lives and inspire others around you. 💍💖",
      "Happy Anniversary, xxxxxxxxxx! May your love always be full of light, laughter, and happiness. 💐💍",
      "Wishing you both a beautiful anniversary, xxxxxxxxxx! May your love always guide you through life. 💍💖",
      "Happy Anniversary, xxxxxxxxxx! Wishing you both many more years of love and happiness together. 💍💖",
      "Wishing you a lifetime of love and happiness on your anniversary, xxxxxxxxxx! 💐💖",
      "Happy Anniversary, xxxxxxxxxx! May your hearts always remain as one. 💍💖",
    ],

    jobList: [
      "Congratulations on your new job, xxxxxxxxxx! Wishing you all the best in this exciting new chapter! 🎉",
      "So happy for you on your new job, xxxxxxxxxx! Wishing you great success and fulfillment in your new role. 💼",
      "Congrats on landing the new job, xxxxxxxxxx! May it bring you happiness, growth, and success. 🌟",
      "Wishing you all the best in your new role, xxxxxxxxxx! Congrats on the new job! 👏",
      "Congratulations, xxxxxxxxxx, on your new job! Wishing you success and lots of new adventures. 🌈",
      "A big congratulations on your new job, xxxxxxxxxx! Here's to exciting new beginnings! 🎉",
      "Best of luck in your new job, xxxxxxxxxx! Wishing you all the success and joy in this new journey. 🌟",
      "You did it, xxxxxxxxxx! Congratulations on your new job! May it be everything you hope for and more. 🎉",
      "Congrats on the new job, xxxxxxxxxx! Wishing you all the success and happiness in this new adventure! 🚀",
      "So proud of you, xxxxxxxxxx! Wishing you a smooth transition into your new role. Good luck! 🍀",
      "Wishing you great success and happiness in your new job, xxxxxxxxxx! Congratulations on this amazing achievement! 🎉",
      "Congratulations on your new job, xxxxxxxxxx! May this new opportunity bring you amazing growth. 🌱",
      "So thrilled for you, xxxxxxxxxx! Wishing you an incredible experience in your new job. Best of luck! 💼",
      "Congratulations, xxxxxxxxxx, on your new job! May your hard work and dedication lead you to great things. 🌟",
      "Best wishes for your new job, xxxxxxxxxx! May it bring you exciting challenges and incredible opportunities. 💼",
      "Cheers to your new job, xxxxxxxxxx! Wishing you all the best as you begin this exciting new chapter in your career. 🥂",
      "Congratulations on the new job, xxxxxxxxxx! Your hard work and dedication are paying off. Best of luck! 🌟",
      "So proud of you, xxxxxxxxxx! Wishing you much success and happiness in your new role. 🎉",
      "Wishing you all the best as you embark on this new journey in your career, xxxxxxxxxx! Congrats! 🎉",
      "Congrats on the new job, xxxxxxxxxx! May it bring you joy, fulfillment, and lots of success! 💼",
      "Congratulations on your new job, xxxxxxxxxx! Best of luck with all the new opportunities and challenges ahead. 🌈",
      "Wishing you all the best in your new job, xxxxxxxxxx! You've got this! 💪",
      "Congratulations on your new job, xxxxxxxxxx! May it bring you success, happiness, and personal growth. 💼",
      "Wishing you great things in your new job, xxxxxxxxxx! May this be the start of something wonderful! 🎉",
      "Cheers to your new job, xxxxxxxxxx! Wishing you a smooth transition and lots of exciting new challenges. 🌟",
      "Congratulations on the new job, xxxxxxxxxx! Here’s to achieving all your career goals and more. 🚀",
      "You’re going to do amazing things in your new job, xxxxxxxxxx! Congratulations and good luck! 💼",
      "Congrats on your new job, xxxxxxxxxx! Wishing you all the best as you take on new challenges and grow! 🎉",
      "So proud of you, xxxxxxxxxx! Your new job is the perfect next step. Best of luck! 💪",
      "Congratulations on your new job, xxxxxxxxxx! May this be the start of an exciting and successful journey. 🎉",
      "Best of luck with your new job, xxxxxxxxxx! You’ll do great things, I’m sure of it! 💼",
      "Congratulations on the new job, xxxxxxxxxx! May this new chapter bring you growth and happiness. 🌱",
      "Wishing you success in your new job, xxxxxxxxxx! You’ve got what it takes to thrive! 💼",
      "Congrats on your new job, xxxxxxxxxx! Here’s to new beginnings and exciting challenges ahead. 🎉",
      "Cheers to your new role, xxxxxxxxxx! Wishing you all the best in this new and exciting chapter of your career. 🥂",
      "Wishing you all the best in your new job, xxxxxxxxxx! You’re going to do amazing things! 🌟",
      "Congratulations, xxxxxxxxxx! May your new job bring you joy, success, and endless opportunities! 🎉",
      "Congrats on the new job, xxxxxxxxxx! May this next phase of your career bring you everything you desire. 💼",
      "Wishing you great things in your new role, xxxxxxxxxx! Congratulations and best of luck with this new opportunity! 🎉",
      "So excited for you, xxxxxxxxxx! Congratulations on your new job. May it bring you everything you’ve been working for. 🌟",
      "Congratulations on your new job, xxxxxxxxxx! I have no doubt you’ll make a big impact. Good luck! 💼",
      "Best of luck in your new job, xxxxxxxxxx! Wishing you tons of success and growth in this new chapter. 💼",
      "Congrats on your new job, xxxxxxxxxx! I’m sure you’re going to make a huge difference in this new role. 💪",
      "Wishing you all the best in your new job, xxxxxxxxxx! May it bring you joy and success. 💼",
      "Congratulations on your new job, xxxxxxxxxx! May your new role bring you excitement, fulfillment, and growth. 🌱",
      "Wishing you all the success and happiness in your new job, xxxxxxxxxx! Congrats on this exciting milestone! 🎉",
      "Congrats on your new job, xxxxxxxxxx! Wishing you a smooth transition and lots of success in your new role. 💼",
      "Congratulations, xxxxxxxxxx, on your new job! I’m so happy for you and wish you all the best! 🎉",
      "Best of luck with your new job, xxxxxxxxxx! I’m sure you’ll do amazing things and make an impact. 💼",
      "So proud of you, xxxxxxxxxx! Wishing you success and happiness in your new job. Congratulations! 🎉",
      "Congrats on your new job, xxxxxxxxxx! May this new role bring you the success and happiness you deserve. 💼",
      "Wishing you all the best on your new job, xxxxxxxxxx! Here’s to new beginnings and exciting opportunities. 🌟",
      "Congrats, xxxxxxxxxx! May your new job be everything you hope for and more. Best of luck! 💼",
      "Wishing you all the best in your new job, xxxxxxxxxx! May your hard work and dedication shine. 💼",
      "Cheers to your new job, xxxxxxxxxx! Wishing you great success and lots of fulfillment. 🎉",
      "Congratulations on your new job, xxxxxxxxxx! May you continue to grow and excel in your career. 🌟",
      "Wishing you success, happiness, and fulfillment in your new job, xxxxxxxxxx! 🎉",
      "Best of luck in your new role, xxxxxxxxxx! Wishing you amazing opportunities and lots of growth. 💼",
      "Congratulations, xxxxxxxxxx, on your new job! Wishing you success and joy in this next chapter of your career. 🎉",
      "Congrats on the new job, xxxxxxxxxx! May your journey be full of exciting challenges and achievements. 💼",
      "Wishing you a successful career in your new job, xxxxxxxxxx! I know you’re going to do great things. 🌟",
      "Happy to hear about your new job, xxxxxxxxxx! Congratulations and best wishes for your new adventure! 💼",
      "Congrats on your new role, xxxxxxxxxx! May it bring you all the success and happiness you deserve. 🌟",
      "Wishing you a fulfilling and exciting journey ahead, xxxxxxxxxx! Congratulations on your new job! 🎉",
      "So excited for you, xxxxxxxxxx! Wishing you all the best in your new job! 💼",
      "Wishing you success in your new job, xxxxxxxxxx! Congratulations on reaching this exciting milestone. 🎉",
      "Congratulations, xxxxxxxxxx! May your new job bring you endless success and fulfillment. 💼",
      "Best of luck with your new role, xxxxxxxxxx! You’re going to do amazing things! 💪",
      "Wishing you a smooth transition and much success in your new job, xxxxxxxxxx! Congrats! 🎉",
      "Congratulations on your new job, xxxxxxxxxx! Wishing you a fulfilling career ahead. 🌟",
      "Congrats on the new job, xxxxxxxxxx! Wishing you success and happiness in your new role! 💼",
      "Wishing you all the best on your new career journey, xxxxxxxxxx! You’ve got this! 💪",
      "Cheers to your new job, xxxxxxxxxx! May this be the start of an incredible new adventure. 💼",
      "Congrats on your new job, xxxxxxxxxx! Best wishes for success and happiness as you embark on this new journey. 🎉",
      "Wishing you all the best in your new job, xxxxxxxxxx! May this role bring you great success. 🌟",
      "Congratulations on the new job, xxxxxxxxxx! Wishing you all the best in your new role. 🌟",
    ],

    promotionList: [
      "Congratulations on your well-deserved promotion, xxxxxxxxxx! Wishing you even more success in this new role. 🎉",
      "So proud of you, xxxxxxxxxx! A promotion well earned. Wishing you all the best in this exciting new chapter of your career. 🌟",
      "Huge congratulations on your promotion, xxxxxxxxxx! You’ve worked so hard for this, and I know you’ll continue to shine. 💼",
      "Cheers to your well-earned promotion, xxxxxxxxxx! Wishing you success and growth in this new role. 🥂",
      "Congratulations on your promotion, xxxxxxxxxx! You truly deserve this, and I can’t wait to see you reach even greater heights. 🎉",
      "So happy for you, xxxxxxxxxx! A big congratulations on your promotion! Wishing you continued success in this new position. 🌟",
      "Congrats on your promotion, xxxxxxxxxx! This is a true testament to your hard work and dedication. Wishing you all the best! 💼",
      "Congratulations on your career promotion, xxxxxxxxxx! Your commitment and excellence have paid off. Wishing you even more success ahead! 🌱",
      "You did it, xxxxxxxxxx! Congratulations on your promotion! May this new role bring you even more challenges and opportunities for growth. 🎉",
      "Well done on your promotion, xxxxxxxxxx! You truly deserve this recognition. Wishing you continued success in all that you do. 🌟",
      "Congrats on your promotion, xxxxxxxxxx! Your dedication, hard work, and passion have made this moment possible. Best of luck in your new role! 💼",
      "What a fantastic achievement, xxxxxxxxxx! Congratulations on your promotion! Wishing you a future filled with success and satisfaction. 🌱",
      "So proud of you, xxxxxxxxxx! This promotion is a testament to your hard work and determination. Wishing you great success ahead! 💪",
      "Congratulations on this huge step forward in your career, xxxxxxxxxx! Wishing you even more accomplishments in your new role. 🌟",
      "Best wishes as you take on your new role, xxxxxxxxxx! Congratulations on your promotion, and may your career continue to soar! 🎉",
      "Congrats, xxxxxxxxxx, on your promotion! May you continue to be an inspiration to others as you lead in this new role. 💼",
      "Congratulations on your well-deserved promotion, xxxxxxxxxx! Your hard work and dedication have truly paid off. 🌟",
      "So happy to hear about your promotion, xxxxxxxxxx! Wishing you all the best as you step into this new chapter in your career. 🎉",
      "Congrats, xxxxxxxxxx! Your promotion is a reflection of your hard work, talent, and perseverance. Wishing you continued success! 💼",
      "What an incredible achievement, xxxxxxxxxx! Congratulations on your promotion! May this new role bring you even greater opportunities. 🌱",
      "Congratulations on your promotion, xxxxxxxxxx! Your talent and hard work are truly recognized and appreciated. Best of luck in your new role! 🌟",
      "You’ve earned every bit of this promotion, xxxxxxxxxx! Congratulations and all the best in this new chapter of your career. 💼",
      "A huge congratulations on your promotion, xxxxxxxxxx! May this milestone be the beginning of even greater success! 🎉",
      "So proud of you, xxxxxxxxxx! Your promotion is a testament to your dedication and skill. Best of luck as you take on this new role! 💪",
      "Congratulations on your promotion, xxxxxxxxxx! May this new chapter in your career be filled with even more amazing opportunities. 🌟",
      "Way to go, xxxxxxxxxx! Your promotion is so well deserved. Wishing you continued success and joy in this new role. 💼",
      "You did it, xxxxxxxxxx! Congratulations on your promotion, and I wish you all the best in your exciting new role! 🎉",
      "Cheers to your success, xxxxxxxxxx! Congrats on your promotion, and I hope this new role brings you even more success and fulfillment. 🥂",
      "Wishing you all the best on your promotion, xxxxxxxxxx! Your hard work has definitely paid off, and I’m so proud of you! 💼",
      "Congratulations on your promotion, xxxxxxxxxx! Here’s to new challenges, new goals, and even greater success in this next phase of your career. 🌟",
      "Well deserved, xxxxxxxxxx! Congratulations on your promotion, and may your career continue to grow and flourish. 💼",
      "Cheers to your promotion, xxxxxxxxxx! Wishing you all the best as you embark on this exciting new role. 🎉",
      "Congrats, xxxxxxxxxx! Your promotion is a well-earned recognition of your incredible work. Wishing you all the best in your new position! 🌱",
      "A big congratulations on your promotion, xxxxxxxxxx! Your dedication and hard work have led you to this amazing opportunity. 🌟",
      "Wishing you all the best, xxxxxxxxxx, as you take on this new role. Congratulations on your promotion, and may your future be even brighter! 🎉",
      "Congratulations, xxxxxxxxxx! Your promotion is well deserved, and I can’t wait to see you thrive in this new role. 💼",
      "You’ve worked so hard for this, xxxxxxxxxx! Congratulations on your promotion, and I wish you continued success in your career! 🌟",
      "Congratulations, xxxxxxxxxx! Wishing you an amazing journey in your new role. This promotion is just the beginning of even more success. 🎉",
      "Your promotion is a true reflection of your commitment and hard work, xxxxxxxxxx! Wishing you continued growth and success! 💼",
      "Big congrats on your promotion, xxxxxxxxxx! May this new role bring you even more fulfillment and success in your career. 🌱",
      "What a milestone, xxxxxxxxxx! Congratulations on your promotion, and I’m excited to see all the amazing things you’ll do next! 💼",
      "So happy for you, xxxxxxxxxx! Your promotion is a testament to your dedication. Wishing you all the best in this exciting new chapter! 🎉",
      "Cheers to your promotion, xxxxxxxxxx! Wishing you success, happiness, and fulfillment in your new role. 🌟",
      "Congratulations on your promotion, xxxxxxxxxx! Your hard work has truly paid off. Wishing you continued success in all that you do. 💼",
      "Congrats on your promotion, xxxxxxxxxx! You’ve earned it, and I know you’ll continue to achieve great things. Best of luck! 🌱",
      "Well deserved, xxxxxxxxxx! Congratulations on your promotion, and may this new chapter bring even greater accomplishments! 🎉",
      "What an accomplishment, xxxxxxxxxx! Congratulations on your promotion, and I’m sure this new role will be just the beginning of your success. 💼",
      "Congrats on your promotion, xxxxxxxxxx! I’m so proud of everything you’ve achieved. Wishing you the best in your new role! 🌟",
      "Congratulations, xxxxxxxxxx! May this promotion be the stepping stone to even bigger things in your career. 🎉",
      "Wishing you continued success in your career, xxxxxxxxxx! Congratulations on your promotion, and here’s to even greater things ahead. 💼",
      "Cheers to you, xxxxxxxxxx! Your promotion is so well deserved. Wishing you all the best as you take on this exciting new challenge. 🌱",
      "Congratulations, xxxxxxxxxx, on your promotion! May this new role bring you even more growth and satisfaction. 🎉",
      "Well done, xxxxxxxxxx! Congratulations on your promotion! I know this new role will be full of exciting opportunities for you. 💼",
      "Congrats, xxxxxxxxxx! You’ve earned this promotion with all your hard work. Wishing you continued success and happiness in your new role. 🌟",
      "Wishing you all the best in your new role, xxxxxxxxxx! Congratulations on your promotion and on reaching this incredible milestone. 🎉",
      "You’ve worked so hard for this, xxxxxxxxxx! Congrats on your promotion, and may it bring you success and fulfillment. 💼",
      "Congratulations on your promotion, xxxxxxxxxx! Your dedication and commitment are inspiring. Wishing you even more success! 🌟",
      "Way to go, xxxxxxxxxx! Congrats on your promotion. I know you’re going to thrive in this new role! 🎉",
      "Wishing you all the best in this next phase of your career, xxxxxxxxxx! Congratulations on your promotion. 💼",
      "You’ve earned every bit of this promotion, xxxxxxxxxx! Congratulations and best of luck in this exciting new role. 🌟",
      "Congrats on your promotion, xxxxxxxxxx! Your hard work has truly paid off. Best of luck in your new role! 💼",
      "Congratulations on your promotion, xxxxxxxxxx! May this be the start of even more success and personal growth. 🌱",
      "Best wishes on your promotion, xxxxxxxxxx! I know you’ll do amazing things in this new role. 💼",
      "Congrats, xxxxxxxxxx! Wishing you all the best as you take on this exciting new role in your career. 🌟",
      "Congratulations, xxxxxxxxxx! Your promotion is a well-deserved recognition of all your hard work and dedication. 🎉",
      "What a great achievement, xxxxxxxxxx! Congrats on your promotion, and I can’t wait to see all you accomplish in your new role. 💼",
      "Cheers to your success, xxxxxxxxxx! Congratulations on your promotion. Wishing you even greater success in this new chapter of your career. 🌱",
      "Congrats on your promotion, xxxxxxxxxx! You’ve earned it, and I’m sure this new position will bring you continued success. 🎉",
      "Wishing you all the best in your new role, xxxxxxxxxx! Congratulations on your promotion! 💼",
    ],

    newHomeBlessingList: [
      "Congratulations on your new home, xxxxxxxxxx! May it be filled with love, laughter, and endless happiness. 🏡",
      "Wishing you a lifetime of love, peace, and joy in your new home, xxxxxxxxxx. Congratulations! 🌟",
      "May your new home bring you peace, joy, and prosperity, xxxxxxxxxx. Congratulations on this wonderful new chapter! 🏡",
      "Cheers to new beginnings! May your new home be a place of comfort, love, and happiness, xxxxxxxxxx. 🏠",
      "Wishing you all the best as you settle into your new home, xxxxxxxxxx. May it be a haven of peace and happiness. 🎉",
      "Congratulations on your new home, xxxxxxxxxx! May it always be filled with warmth, laughter, and love. 🏡",
      "A new home is a fresh start. Wishing you many happy memories in your new space, xxxxxxxxxx. 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it be filled with love, laughter, and everything beautiful. 💫",
      "May your new home bring you joy, peace, and endless blessings, xxxxxxxxxx. Enjoy every moment in your new space! 🏡",
      "Best wishes for many happy memories in your new home, xxxxxxxxxx. May it be a place of comfort and peace. 🌸",
      "Congratulations on your new home, xxxxxxxxxx! May it be a place where you always feel safe, loved, and at peace. 🏠",
      "May your new home be filled with love, laughter, and cherished moments. Congratulations, xxxxxxxxxx! 🏡",
      "Wishing you all the best in your new home, xxxxxxxxxx. May it bring you nothing but happiness and joy! 🌟",
      "Congratulations on your new home, xxxxxxxxxx! May your new space bring you peace, comfort, and great memories. 🏠",
      "A new home is the perfect place to create memories. Congratulations, xxxxxxxxxx! Wishing you a lifetime of happiness. 🏡",
      "Wishing you peace, love, and prosperity in your new home, xxxxxxxxxx. Congratulations and best of luck! 🌸",
      "Congratulations on your new home, xxxxxxxxxx! May it be filled with warmth, love, and laughter for many years to come. 🏠",
      "May your new home be a place of peace, joy, and warmth. Congratulations, xxxxxxxxxx! 🏡",
      "Wishing you all the happiness and success as you start this new chapter in your beautiful new home, xxxxxxxxxx! 🌸",
      "May your new home bring you everything you’ve dreamed of and more, xxxxxxxxxx. Congratulations! 🏠",
      "Wishing you all the best as you settle into your new home, xxxxxxxxxx. May it be a place of warmth and joy. 🏡",
      "Congratulations on your new home, xxxxxxxxxx! May it be filled with love, laughter, and unforgettable memories. 🏠",
      "A new home means new beginnings. Wishing you endless happiness in your beautiful new space, xxxxxxxxxx. 🏡",
      "Congratulations on your new home, xxxxxxxxxx! May it be a place where you feel loved, safe, and cherished. 🌟",
      "May your new home bring you peace, comfort, and happiness, xxxxxxxxxx. Congratulations and best wishes! 🏠",
      "Wishing you a lifetime of love, happiness, and peace in your new home, xxxxxxxxxx! 🏡",
      "Congrats on your new home, xxxxxxxxxx! May it bring you all the happiness, peace, and love in the world. 🌸",
      "Wishing you a future full of happy moments and cherished memories in your new home, xxxxxxxxxx. 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May every room be filled with joy, peace, and love. 🏡",
      "Here’s to new beginnings and wonderful memories in your new home, xxxxxxxxxx. Congratulations! 🌸",
      "Wishing you all the best in your new home, xxxxxxxxxx! May your new space be filled with love and joy. 🏡",
      "Congratulations on your beautiful new home, xxxxxxxxxx! May it be a place where your dreams come true. 🏠",
      "Wishing you warmth, peace, and happiness in your new home, xxxxxxxxxx. Congratulations! 🌟",
      "May your new home be filled with love and happiness, xxxxxxxxxx. Enjoy every moment of this new journey! 🏡",
      "Congratulations on your new home, xxxxxxxxxx! Wishing you peace, love, and all the best in your new space. 🏠",
      "Wishing you all the best as you begin this new chapter in your beautiful new home, xxxxxxxxxx! 🌸",
      "May your new home be filled with memories that last a lifetime, xxxxxxxxxx. Congratulations! 🏡",
      "Congratulations, xxxxxxxxxx! May your new home bring you love, joy, and endless happiness. 🏠",
      "Wishing you peace, love, and warmth in your new home, xxxxxxxxxx. Enjoy every moment of this beautiful new space! 🏡",
      "Cheers to new beginnings and a wonderful new home, xxxxxxxxxx! May it be filled with love and joy. 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it always be a place of peace, love, and happiness. 🏡",
      "Wishing you the very best as you settle into your new home, xxxxxxxxxx. May it bring you endless joy and happiness! 🌸",
      "Congrats on your new home, xxxxxxxxxx! May it be a place where you create amazing memories and live your best life. 🏠",
      "May your new home bring you everything you’ve hoped for and more, xxxxxxxxxx. Congratulations! 🏡",
      "Congratulations, xxxxxxxxxx! May your new home be filled with happiness, peace, and love. 🌟",
      "Wishing you all the best as you begin this exciting new chapter in your new home, xxxxxxxxxx! 🏡",
      "Congrats on your new home, xxxxxxxxxx! May it be a place where your heart is full and your dreams come true. 🏠",
      "May your new home be a place of joy, love, and comfort, xxxxxxxxxx. Congratulations and best wishes! 🏡",
      "Wishing you peace, love, and happiness as you settle into your new home, xxxxxxxxxx. Congratulations! 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it bring you warmth, joy, and all the things you love most. 🏡",
      "May your new home be the perfect setting for many happy moments and cherished memories, xxxxxxxxxx. 🎉",
      "Congratulations, xxxxxxxxxx! Wishing you all the best as you start this exciting new journey in your new home. 🏡",
      "Here’s to a new chapter in your life, xxxxxxxxxx! May your new home bring you nothing but happiness and peace. 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it be a place where you make countless happy memories. 🌸",
      "May your new home bring you comfort, peace, and endless joy, xxxxxxxxxx. Congratulations! 🏡",
      "Wishing you all the best as you settle into your new space, xxxxxxxxxx! May it be a place filled with love and happiness. 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it be a place where dreams come true and memories are made. 🌟",
      "Congrats on your new home, xxxxxxxxxx! May it bring you all the joy, peace, and happiness you deserve. 🏡",
      "Wishing you all the best as you start this exciting new chapter in your new home, xxxxxxxxxx! 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it always be a place where you feel happy and at peace. 🌸",
      "Wishing you warmth, love, and happiness in your new home, xxxxxxxxxx. Enjoy every moment! 🏡",
      "Congratulations on your new home, xxxxxxxxxx! May it bring you comfort, joy, and peace for many years to come. 🏠",
      "Wishing you the best of everything in your new home, xxxxxxxxxx! May it be filled with warmth and joy. 🌟",
      "May your new home be a place where your heart feels at ease, xxxxxxxxxx. Congratulations and best wishes! 🏡",
      "Congratulations on your new home, xxxxxxxxxx! May this space be filled with love, laughter, and joy. 🏠",
      "Wishing you endless happiness in your new home, xxxxxxxxxx. May it be a place full of love and peace. 🌸",
      "Congrats on your new home, xxxxxxxxxx! May your new space bring you all the happiness you deserve. 🏡",
      "Congratulations, xxxxxxxxxx! Wishing you all the best in your beautiful new home. 🏠",
      "May your new home be filled with love, joy, and all the good things in life, xxxxxxxxxx. Congratulations! 🏡",
      "Wishing you all the happiness in the world as you settle into your new home, xxxxxxxxxx! 🏠",
      "Congratulations on your new home, xxxxxxxxxx! May it bring you endless joy, peace, and love. 🏡",
    ],

    babyArrivalList: [
      "Congratulations on the arrival of your little bundle of joy, xxxxxxxxxx! May your baby bring you endless happiness and love. 👶🎉",
      "Welcome to the world, baby! Congrats, xxxxxxxxxx, on your precious new addition to the family! 💕",
      "Wishing your family all the best with the arrival of your beautiful baby! Congratulations, xxxxxxxxxx! 👶",
      "A baby is a gift from heaven. Congratulations, xxxxxxxxxx, on the arrival of your little one! 🌟👶",
      "Your baby is a true blessing. Wishing your family lots of love, laughter, and happiness! Congratulations, xxxxxxxxxx! 👶💖",
      "Congrats, xxxxxxxxxx! May your baby bring you a lifetime of joy and love. 👶🎀",
      "A little angel has arrived. Congratulations, xxxxxxxxxx, on the birth of your beautiful baby! 😇💖",
      "Wishing you all the best as you welcome your new baby into the world. Congratulations, xxxxxxxxxx! 👶💫",
      "Congratulations on the arrival of your baby, xxxxxxxxxx! May your journey into parenthood be filled with love and joy. 👶🌸",
      "A baby’s arrival brings a smile to every heart. Congrats, xxxxxxxxxx, on your beautiful little one! 👶💖",
      "Your new baby is finally here! May they bring joy and happiness to your lives, xxxxxxxxxx. Congratulations! 🎉👶",
      "Congrats, xxxxxxxxxx! Your baby is here, and the world is a better place with them in it. 👶✨",
      "Sending you all the best as you welcome your little one into the world, xxxxxxxxxx! Congratulations on becoming parents! 👶🎀",
      "A new baby is like the beginning of all things – wonder, hope, a dream of possibilities. Congratulations, xxxxxxxxxx! 👶💖",
      "Your baby is a true blessing! Wishing you and your family all the best as you embark on this beautiful journey. Congratulations, xxxxxxxxxx! 👶🎉",
      "Sending you and your precious new baby lots of love and happiness! Congratulations, xxxxxxxxxx! 💕👶",
      "Congratulations on your little one’s arrival! May your baby bring you all the happiness in the world, xxxxxxxxxx! 👶💖",
      "Wishing you a lifetime of joy and love with your new baby, xxxxxxxxxx. Congratulations on your growing family! 👶💫",
      "A new baby is such a precious gift. Congrats, xxxxxxxxxx, on the arrival of your little bundle of joy! 👶🎉",
      "Welcome to the world, little one! Congratulations, xxxxxxxxxx, on becoming parents to such a beautiful baby! 👶💖",
      "Congrats, xxxxxxxxxx! Your new baby is here, and we couldn’t be happier for you. Wishing you all the love and joy in the world! 👶🎀",
      "Sending heartfelt congratulations to you and your family on the arrival of your baby, xxxxxxxxxx! 👶💕",
      "A beautiful baby is a blessing. Congratulations, xxxxxxxxxx, on the arrival of your little angel! 👶🌸",
      "Cheers to new beginnings and new joys! Congrats, xxxxxxxxxx, on the birth of your precious baby! 👶💖",
      "Wishing your little one a lifetime of health and happiness. Congratulations, xxxxxxxxxx! 👶💫",
      "Your baby is here, and they are a true blessing! Congrats, xxxxxxxxxx! Sending you lots of love and best wishes. 👶💕",
      "Sending love and blessings to your newborn and family. Congratulations, xxxxxxxxxx, on the arrival of your little one! 👶🎉",
      "Your family just got a whole lot sweeter! Congrats, xxxxxxxxxx, on your new baby! 👶💖",
      "May your baby’s arrival bring endless joy and love into your home. Congratulations, xxxxxxxxxx! 👶💫",
      "Welcome to parenthood, xxxxxxxxxx! Congratulations on your beautiful baby! 👶🎀",
      "Congratulations on the arrival of your baby, xxxxxxxxxx! Wishing you lots of happiness and unforgettable memories! 👶💖",
      "A little one has arrived! Congrats, xxxxxxxxxx! May your baby fill your home with joy and laughter. 👶🎉",
      "Congrats, xxxxxxxxxx! Your new baby is sure to fill your life with love, laughter, and joy! 👶💕",
      "Wishing you and your little one all the best! Congratulations, xxxxxxxxxx, on becoming parents! 👶💫",
      "Welcome to the world, baby! Congrats, xxxxxxxxxx, on your beautiful new addition to the family! 👶🎀",
      "A new baby means endless happiness and love. Congratulations, xxxxxxxxxx, on your bundle of joy! 👶💖",
      "Wishing you and your little one all the love and joy in the world! Congratulations, xxxxxxxxxx! 👶💫",
      "Your little angel is finally here! Congrats, xxxxxxxxxx, on becoming parents to such a precious baby! 👶🎉",
      "Wishing your family a lifetime of happiness with your beautiful new baby. Congratulations, xxxxxxxxxx! 👶💖",
      "Congratulations, xxxxxxxxxx, on your baby’s arrival! May your little one bring joy and love into your life. 👶💫",
      "Sending you and your baby lots of love and blessings. Congratulations on the arrival of your precious little one, xxxxxxxxxx! 👶🎀",
      "The wait is over! Congratulations, xxxxxxxxxx, on your beautiful baby’s arrival! May your days be filled with love and laughter. 👶💖",
      "Wishing you endless joy and happiness as you welcome your little one into your arms. Congratulations, xxxxxxxxxx! 👶🎉",
      "Congrats, xxxxxxxxxx! Your little one has arrived, and we couldn’t be happier for you! Wishing you all the best on this new journey. 👶💖",
      "Sending you love and warm wishes for your new baby, xxxxxxxxxx. May your little one bring you endless joy! 👶🎀",
      "A new baby means a new world of love, laughter, and happiness. Congrats, xxxxxxxxxx, on the birth of your little one! 👶💫",
      "Congratulations, xxxxxxxxxx, on your baby’s arrival! May your home be filled with warmth, joy, and all the love in the world. 👶💕",
      "A bundle of joy is here! Congrats, xxxxxxxxxx, on your beautiful baby. Wishing you all the happiness in the world! 👶🎉",
      "Your little one has arrived, and we couldn’t be more excited for you! Congratulations, xxxxxxxxxx! 👶💖",
      "Wishing you a lifetime of love and joy with your little one, xxxxxxxxxx. Congratulations on your baby’s arrival! 👶🎀",
      "A new baby brings a new level of joy to your life. Congratulations, xxxxxxxxxx, on your little one’s arrival! 👶💫",
      "Welcome to parenthood, xxxxxxxxxx! May your baby bring you a lifetime of happiness and love. 👶💖",
      "Congratulations, xxxxxxxxxx, on the arrival of your little angel! Wishing you all the best as you begin this exciting journey. 👶🎉",
      "Sending you heartfelt congratulations on your new baby! May your little one bring you all the love in the world, xxxxxxxxxx. 👶💖",
      "Wishing you a world of joy and love as you welcome your new baby, xxxxxxxxxx! Congratulations! 👶💫",
      "Your baby is a true gift! Congratulations, xxxxxxxxxx, on this beautiful new chapter. 👶💕",
      "Congrats on your precious little one! Wishing you and your family all the best on this new adventure, xxxxxxxxxx! 👶🎀",
      "Wishing you and your family all the joy and love that your new baby will bring. Congratulations, xxxxxxxxxx! 👶💖",
      "A baby is the most precious gift. Congrats, xxxxxxxxxx, on the arrival of your little one! 👶💫",
      "Sending love and blessings to you and your newborn, xxxxxxxxxx. May your baby’s arrival fill your home with joy! 👶🎉",
      "Congratulations on the birth of your beautiful baby, xxxxxxxxxx! May your little one bring you endless happiness and love. 👶💖",
      "Wishing you and your family a lifetime of happiness with your new baby! Congratulations, xxxxxxxxxx! 👶🎀",
      "Welcome to the world, little one! Congratulations, xxxxxxxxxx, on your precious baby’s arrival! 👶💫",
      "Congrats on your new baby, xxxxxxxxxx! May they bring you endless joy and happiness in the years to come. 👶🎉",
      "Congratulations, xxxxxxxxxx! Wishing you all the best as you begin this beautiful journey with your new baby. 👶💕",
      "Your new baby is a true blessing. May their arrival bring you all the love and joy in the world, xxxxxxxxxx. 👶💫",
      "Wishing you a lifetime of happiness, love, and laughter with your new baby! Congratulations, xxxxxxxxxx! 👶🎉",
    ],

    retirementWish: [
      "Congratulations on your well-deserved retirement! Wishing you all the best in this new chapter of life.",
      "Happy retirement! Enjoy the freedom and relaxation that comes with this next phase of life.",
      "Wishing you a retirement filled with joy, adventure, and the opportunity to enjoy every moment!",
      "Congrats on your retirement! May your days ahead be filled with endless happiness and new experiences.",
      "Cheers to a retirement filled with good health, great memories, and exciting adventures!",
      "Your hard work has paid off—wishing you a fulfilling and joyful retirement!",
      "Happy retirement! May you find joy in every new day and fulfillment in everything you do.",
      "Wishing you all the happiness in the world as you retire and embark on this exciting new journey.",
      "Congratulations on your retirement! Time to relax and enjoy the rewards of all your hard work.",
      "Enjoy your retirement, you've earned it! May the future bring you lots of happiness and peace.",
      "Happy retirement! May your days ahead be full of laughter, love, and everything you enjoy.",
      "As you retire, may the next chapter be filled with new experiences and all the things you've dreamed of doing.",
      "Wishing you a wonderful retirement! May it be everything you've hoped for and more.",
      "Cheers to your retirement! May the next chapter bring you all the happiness and relaxation you deserve.",
      "Happy retirement! May this next chapter bring you exciting opportunities and plenty of time for yourself.",
      "Congratulations on your retirement! Wishing you a relaxing and enjoyable time ahead, full of happiness and fun.",
      "Wishing you a retirement that's as special as you are! Enjoy every moment to the fullest.",
      "Happy retirement! May this next chapter bring you all the joy and relaxation you’ve worked hard to earn.",
      "Your retirement is well-deserved—may it bring you happiness, peace, and lots of fun!",
      "Congratulations on reaching this incredible milestone! Enjoy the new adventures and opportunities that retirement brings.",
      "Wishing you the best in your retirement! May you find joy and contentment in everything you do.",
      "Happy retirement! May you find happiness in the little things and joy in every new day.",
      "Here’s to the start of a new adventure! Wishing you all the best in your well-deserved retirement.",
      "Congratulations on your retirement! May it be filled with peace, relaxation, and everything you’ve been dreaming of.",
      "Wishing you a happy and fulfilling retirement—time to enjoy all the things you love most!",
      "As you retire, may the days ahead be as bright and exciting as the ones you’ve worked so hard for.",
      "Happy retirement! May you enjoy your time off and make the most of this new chapter in life.",
      "Congrats on your retirement! Wishing you endless days of relaxation and enjoyment.",
      "Retirement is the perfect time to reflect on all you've achieved and look forward to all that's to come. Enjoy every minute!",
      "Wishing you a retirement filled with joy, relaxation, and plenty of time to do what you love.",
      "Enjoy your retirement and all the adventures that await you. You’ve earned it!",
      "Happy retirement! May your days be filled with happiness, fun, and all the things you've always wanted to do.",
      "Wishing you a joyful and relaxing retirement full of new adventures and exciting opportunities.",
      "Congrats on your retirement! Now it’s time for you to enjoy the fruits of your labor.",
      "Here’s to a new beginning! Enjoy your retirement and the freedom to do whatever you want.",
      "Happy retirement! May you find endless joy, relaxation, and fulfillment in the years to come.",
      "Retirement is a time for reflection, relaxation, and adventure. Enjoy the journey ahead!",
      "Congratulations on reaching this amazing milestone! Wishing you a retirement full of happiness and adventure.",
      "Enjoy your retirement, you deserve it! May this next chapter bring you nothing but joy.",
      "Happy retirement! May your days ahead be full of peace, joy, and all the things you love.",
      "Wishing you all the best as you retire and embark on this exciting new phase of life.",
      "Cheers to your retirement! May it bring you relaxation, happiness, and lots of time to enjoy yourself.",
      "Happy retirement! Enjoy every moment and make the most of this exciting new chapter in your life.",
      "May your retirement be as rewarding and fulfilling as your career has been. Enjoy every moment!",
      "Congratulations on retiring! May this new chapter be full of relaxation, fun, and everything you’ve always dreamed of doing.",
      "Wishing you a wonderful retirement filled with good health, joy, and lots of laughter.",
      "Retirement is just the beginning of a whole new adventure. Enjoy every minute of it!",
      "Happy retirement! May the days ahead be filled with new experiences and lots of fun.",
      "Congrats on your retirement! Now you can take the time to do everything you’ve always wanted to do.",
      "Wishing you a well-deserved retirement filled with rest, relaxation, and fun adventures!",
      "Happy retirement! May you enjoy all the freedom and joy this new chapter brings.",
      "Congrats on your retirement! May it be a time of joy, reflection, and lots of fun.",
      "Wishing you the best in your retirement! May the years ahead bring you happiness, peace, and all the things you love.",
      "Happy retirement! May you enjoy each day to the fullest and find joy in the little things.",
      "Retirement is the perfect time to rest, relax, and enjoy all the things you’ve put off for years.",
      "Congratulations on your retirement! May your next chapter be filled with adventures, fun, and relaxation.",
      "Wishing you a happy retirement filled with peace, joy, and all the things you love to do.",
      "Happy retirement! May this next phase of your life be even better than the last.",
      "Cheers to your retirement! May it be everything you’ve dreamed of and more.",
      "Wishing you a peaceful and joyful retirement. You’ve earned it after all your hard work!",
      "Happy retirement! May your new life bring you excitement, relaxation, and lots of new memories.",
      "Retirement is the time to enjoy the fruits of your labor. May it be filled with everything you love!",
      "Congratulations on your retirement! Wishing you nothing but happiness and adventure ahead.",
      "Enjoy your well-earned retirement and make the most of every moment. You’ve earned it!",
      "Wishing you all the best in your retirement! May it bring you relaxation, happiness, and fun.",
      "Happy retirement! May you find joy in every new adventure that comes your way.",
      "Congratulations on reaching this incredible milestone! May your retirement be full of peace and fulfillment.",
      "Wishing you a retirement filled with joy, laughter, and endless possibilities.",
      "Enjoy the next chapter of your life! May your retirement be filled with joy, relaxation, and happiness.",
      "Happy retirement! May this next phase of your life be everything you’ve dreamed of and more.",
      "Congrats on your retirement! Now it’s time to enjoy all the things you never had time for.",
      "Wishing you a well-deserved and peaceful retirement. Enjoy every moment of it!",
      "Happy retirement! May your days ahead be filled with new opportunities and exciting adventures.",
      "Wishing you a retirement full of fun, relaxation, and the freedom to do whatever makes you happy.",
      "Congratulations on your retirement! May your days be filled with joy, relaxation, and everything you enjoy.",
      "Retirement is just the beginning of a new adventure. Enjoy it to the fullest!",
      "Happy retirement! May this new chapter bring you all the peace and joy you deserve.",
      "Wishing you a retirement filled with happiness, health, and lots of fun!",
      "Congrats on retiring! May your next adventure bring you excitement and fulfillment.",
      "Retirement is the reward for all your hard work. Enjoy it to the fullest!",
      "Wishing you all the best as you step into this exciting new chapter. Happy retirement!",
      "Happy retirement! May this next chapter bring you all the happiness you’ve worked so hard for.",
      "Congrats on your retirement! Wishing you a future filled with joy, peace, and adventure.",
      "Wishing you a retirement filled with fun, relaxation, and new adventures.",
      "Happy retirement! Enjoy the freedom and joy that this new chapter brings.",
      "Congratulations on your retirement! Wishing you all the best as you begin this exciting new chapter.",
      "Wishing you a retirement full of rest, relaxation, and the chance to explore new passions.",
      "Happy retirement! May the future bring you joy, fulfillment, and plenty of time to enjoy the little things.",
      "Cheers to a well-deserved retirement! May this new chapter bring you peace and happiness.",
    ],

    graduationWish: [
      "Congratulations on your graduation! Wishing you all the best as you embark on this new journey.",
      "The future is yours! Congratulations on your graduation and all the best in your next chapter.",
      "Your hard work and determination have paid off! Wishing you success and happiness on your graduation day!",
      "Congrats on graduating! May this be the beginning of an exciting and fulfilling new chapter in your life.",
      "Congratulations! Your graduation is just the beginning of a new adventure. Wishing you all the best in your future endeavors.",
      "Your dedication has finally paid off! Congrats on your graduation and may your future be bright and successful.",
      "Happy graduation day! This is just the start of something amazing. Keep shining bright!",
      "Well done, graduate! Your hard work, determination, and persistence have led you here. Congrats!",
      "Wishing you all the best on your graduation day! The world is waiting for you to make your mark.",
      "The tassel was worth the hassle! Congratulations on your graduation and best wishes for the future!",
      "Your education has been a stepping stone to greatness. Congratulations on your graduation!",
      "Congrats on your big achievement! May the future bring you endless opportunities and success.",
      "Happy Graduation! May you always strive for greatness and continue to shine in everything you do.",
      "A huge congratulations on your graduation! Your hard work has truly paid off.",
      "Congratulations, graduate! May your future be filled with success, joy, and endless possibilities.",
      "Wishing you a wonderful graduation day and a bright future ahead. You've worked hard to get here!",
      "Your graduation is an accomplishment you can always be proud of. Congrats and best wishes!",
      "This is just the beginning of the amazing things you're going to achieve. Congrats on your graduation!",
      "Your degree is just a ticket to your next big adventure. Congratulations and all the best!",
      "You've worked so hard to get here. Congratulations on your graduation and wishing you continued success!",
      "The future is full of possibilities. Congratulations on your graduation and best of luck ahead!",
      "Congratulations on your graduation! May your future be as bright as your achievements.",
      "Happy graduation! May all your dreams come true as you start this exciting new chapter.",
      "Wishing you a big congratulations on your graduation! You’ve earned every bit of success.",
      "Your dedication and commitment have paid off. Congratulations and best wishes for the future!",
      "Happy Graduation Day! Your hard work has paid off and now it's time to enjoy the rewards.",
      "Congratulations, graduate! May your future be filled with new opportunities and exciting challenges.",
      "Cheers to your graduation! You’ve earned it with your hard work and perseverance.",
      "The road ahead is yours to travel. Congratulations on your graduation and best of luck on your journey!",
      "Wishing you all the best as you graduate! May this day mark the beginning of a lifetime of success.",
      "Congratulations on your graduation! The world is yours to conquer. Go out and make your dreams come true.",
      "Your graduation is the beginning of the next chapter of your life. Congrats and best of luck ahead!",
      "Congratulations! The journey was long, but your graduation proves it was worth it.",
      "So proud of you for reaching this milestone. Your graduation marks the start of an amazing future.",
      "Wishing you a successful and fulfilling future ahead. Congrats on your graduation day!",
      "You did it! Your graduation is the result of all your hard work and determination. Congrats!",
      "Graduation is the key to unlocking new adventures and challenges. Wishing you success in all your future endeavors!",
      "Your dedication and hard work have paid off. Wishing you all the best as you step into the future.",
      "Congrats on your graduation! May you be filled with pride and joy as you start this new chapter.",
      "Your graduation is the first step toward making your dreams a reality. Wishing you success in everything you do.",
      "Today is a day to celebrate your hard work and achievement. Congratulations on your graduation!",
      "Graduation day is a big milestone, but it's only the beginning. Wishing you success and happiness in everything you do!",
      "Congratulations on your graduation! May this achievement be just the start of many more to come.",
      "Wishing you all the success and happiness in the world as you move on to new adventures after graduation!",
      "Congrats on your graduation! May you continue to shine brightly and chase your dreams.",
      "You’ve worked hard and earned your success. Congratulations on your graduation!",
      "Wishing you all the best as you take your next steps in life after graduation. You've got this!",
      "Congratulations on your graduation! May you find joy and fulfillment in everything you do in the future.",
      "The sky's the limit! Congratulations on your graduation and best of luck with your next steps.",
      "You’ve made it! Congratulations on your graduation and may all your dreams come true.",
      "The world is waiting for you to make your mark. Congratulations on your graduation!",
      "Your graduation is a reflection of your hard work and determination. Wishing you success in everything you do.",
      "Congrats, graduate! May you continue to succeed and reach for the stars in everything you do.",
      "Happy Graduation! May your future be as bright as the joy in your heart today.",
      "You’ve earned it all! Congratulations on your graduation and wishing you the best as you move forward.",
      "This is just the beginning. Congrats on your graduation and wishing you success in your next adventure!",
      "May your graduation be the first step to an exciting and rewarding future. Congrats!",
      "Wishing you a wonderful graduation day and an even brighter future ahead!",
      "Congratulations on your graduation! The future is yours for the taking.",
      "Your graduation is just the beginning of a lifelong journey. Wishing you success and happiness along the way.",
      "The hard work you put in has paid off. Congratulations on your graduation and best wishes for the future!",
      "You’ve crossed a big milestone today. Congratulations on your graduation and best of luck ahead!",
      "May your graduation be just the beginning of many more accomplishments and successes in your life.",
      "Happy Graduation! Your future is bright, and we can't wait to see where life takes you.",
      "Your graduation is an incredible achievement! Wishing you all the best in the next chapter of your life.",
      "Congratulations on this major milestone! Wishing you all the best as you move forward in life.",
      "Happy Graduation! May the road ahead be filled with new opportunities and incredible adventures.",
      "You’ve worked hard and earned this moment. Congratulations and best wishes for your bright future.",
      "Graduation is the beginning of your next great adventure. Wishing you success in all you do!",
      "Congrats on your graduation! May you be filled with pride and excitement as you embark on the next chapter of your journey.",
      "Wishing you all the best in the next chapter of your life! Congrats on your graduation!",
      "Today marks a new chapter in your life. Congratulations on your graduation and best of luck with your next steps!",
      "Cheers to your graduation! The hard work has paid off, and now it’s time for even bigger dreams.",
      "Wishing you a lifetime of happiness and success after graduation! You've earned it.",
      "Happy graduation! The world is waiting for you—go out and make a difference.",
      "Wishing you success and happiness on your graduation day and in all the days to come.",
      "Congrats on graduating! You have accomplished something amazing. May your future be just as bright.",
      "Graduation is a time for celebration and new beginnings. Wishing you a future full of opportunities!",
      "Congrats, graduate! Wishing you all the success and happiness as you step into your future.",
      "May your graduation day be the beginning of all your dreams coming true. Congrats!",
      "Congratulations! May this milestone mark the start of many more accomplishments in your life.",
      "Happy graduation day! Wishing you endless success and happiness in the future.",
      "Congratulations on your graduation! You’ve worked hard, and now the future is yours to shape.",
      "Happy Graduation! Wishing you all the best in this exciting new chapter of your life.",
      "Cheers to your graduation! May the future hold all the opportunities and success you deserve.",
      "Congratulations, graduate! May you find success, fulfillment, and happiness in everything you pursue.",
      "Wishing you a lifetime of success, happiness, and fulfillment after graduation!",
      "Congrats on your graduation! May this day bring joy and new opportunities for you in the future.",
      "The journey may be over, but the adventure is just beginning. Congratulations on your graduation!",
      "Wishing you the best as you graduate and begin the next exciting chapter of your life.",
      "Congratulations on your graduation! May all your dreams come true as you step into the future.",
      "Your graduation is a reflection of all your hard work and perseverance. Wishing you all the best moving forward.",
      "Wishing you a future full of success and happiness after graduation!",
      "Happy graduation! May your next adventure bring you all the success and happiness you’ve dreamed of.",
    ],

    goodLuckWish: [
      "Wishing you the best of luck in all that you do. You've got this!",
      "Good luck on your journey! May success follow you wherever you go.",
      "Sending positive vibes your way! Best of luck with everything you do.",
      "Good luck! I believe in you and know you will achieve great things.",
      "Wishing you all the best of luck! May everything you do turn out perfectly.",
      "Good luck on this new adventure! May it bring you happiness and success.",
      "Best of luck with your endeavors! You've got everything you need to succeed.",
      "Wishing you good fortune and success in everything you do. Best of luck!",
      "Good luck on your new chapter! I’m sure you will do amazing things.",
      "Sending you all my best wishes for your new journey. Good luck!",
      "I’m wishing you tons of good luck and positive energy. You've got this!",
      "May luck be with you every step of the way. Best of luck to you!",
      "Good luck on your next big step! I’m cheering you on all the way.",
      "Wishing you the best of luck! May your dreams come true in everything you do.",
      "Good luck and success in all that you try. You’re going to do great!",
      "Best of luck with everything! May each step bring you closer to your goals.",
      "Wishing you all the best and good luck on your new adventure ahead!",
      "Good luck on this new endeavor. I know you will make the most of it!",
      "Wishing you luck, success, and happiness in everything you do.",
      "Good luck on your journey! May it be filled with blessings and good times.",
      "Wishing you good luck and all the success you deserve. You’ve got this!",
      "Sending you good luck and warm wishes for every step you take. All the best!",
      "Good luck with your new endeavor! I hope it brings you much success and joy.",
      "Wishing you good fortune and success in every new challenge that comes your way.",
      "Good luck! May all your hard work and effort pay off beautifully.",
      "I’m wishing you all the best of luck in everything you set out to do.",
      "Good luck and success on your journey! May every step bring you closer to your dreams.",
      "Wishing you nothing but the best of luck! Keep pushing forward, success is near.",
      "Good luck with everything you are about to start. I know you will succeed!",
      "May good luck be with you in all your endeavors. Wishing you success and happiness.",
      "Good luck! I'm excited to see you achieve great things in your journey ahead.",
      "Wishing you a smooth and successful journey ahead. Good luck with everything!",
      "Good luck! May you always find the strength and courage to follow your dreams.",
      "Wishing you the best of luck and may success be your constant companion.",
      "Sending you good luck and best wishes for every challenge you take on.",
      "Good luck! I know that your hard work will pay off and great things are coming your way.",
      "Best of luck in everything you do. You have what it takes to achieve great things.",
      "Wishing you success and good fortune in all your future endeavors. Good luck!",
      "May good luck follow you wherever you go. Best of luck with everything!",
      "Good luck to you! Wishing you success, joy, and fulfillment in everything you pursue.",
      "I’m sending you positive energy and all the best of luck for your future.",
      "Good luck on your journey ahead! May your path be filled with success and joy.",
      "Wishing you the best of luck in all your pursuits. You've got everything it takes to succeed!",
      "Good luck! May your hard work and efforts be rewarded with success and happiness.",
      "Wishing you good luck and all the success in the world as you take on new challenges.",
      "Good luck with your goals! I believe in your ability to achieve greatness.",
      "Sending you all the best as you take on this new adventure. Good luck!",
      "Good luck and best wishes as you continue to chase your dreams. You've got this!",
      "Wishing you good luck and success with all that you strive for in life.",
      "Best of luck! May you find joy and success in everything you do.",
      "Good luck! I hope all your endeavors turn out just as you envision them.",
      "Wishing you nothing but success and good fortune. Good luck with everything!",
      "Good luck and best wishes on your exciting new journey ahead!",
      "Wishing you the best of luck in your next chapter. Success is just around the corner.",
      "Sending all my best wishes and good luck as you embark on your next great adventure.",
      "Good luck! May every new beginning bring you closer to your dreams and goals.",
      "Good luck! I’m confident that you will accomplish great things in your future.",
      "Wishing you the best of luck as you take your next step toward greatness.",
      "Good luck with everything you are about to take on. I know you will do amazing things!",
      "Wishing you the very best of luck as you chase after your dreams and ambitions.",
      "Good luck on your path! May it bring you peace, success, and everything you desire.",
      "Wishing you the best of luck and success as you embark on this exciting journey.",
      "Good luck! You’re going to do amazing things, I’m sure of it!",
      "May luck and fortune always be on your side as you venture forward. Best of luck!",
      "Good luck! Wishing you all the best as you move toward success and greatness.",
      "Wishing you the best of luck and joy in everything you set out to do.",
      "Good luck in everything you do! Keep believing in yourself and all that you are capable of.",
      "Wishing you the best of luck on your way to achieving all your goals and dreams.",
      "Good luck! I know your hard work and determination will lead you to great success.",
      "Wishing you luck and happiness in everything you do. You've got this!",
      "Good luck! I’m excited to see the amazing things you’ll accomplish in the future.",
      "Wishing you good luck in all your endeavors, may success follow you every step of the way.",
      "Good luck with everything you do! Keep shining and never stop chasing your dreams.",
      "Wishing you good luck and all the success in the world! You’ve got this!",
      "Best of luck on your journey! May it bring you the success and happiness you deserve.",
      "Good luck! May the stars align for you and your dreams come true.",
      "Wishing you good luck and positive results in everything you strive for.",
      "Good luck with all your future endeavors! May success be your constant companion.",
      "Good luck! I believe in you and know that you will achieve everything you set your mind to.",
      "Wishing you all the best of luck as you take on new challenges and opportunities.",
      "Good luck in everything you do! May it bring you growth, success, and happiness.",
      "Best of luck! You’ve already achieved so much, and I’m excited to see what’s next for you.",
      "Good luck with your dreams and goals! You have what it takes to succeed.",
      "Sending you my best wishes and good luck as you begin this exciting chapter in your life.",
      "Good luck! May all your hard work and dedication lead to amazing results.",
      "Wishing you nothing but success and happiness as you chase after your dreams.",
      "Good luck with everything! You’ve got all the skills and talents to succeed.",
      "Good luck! I know your determination and passion will lead you to great things.",
      "Wishing you good luck and great success as you take on new challenges in life.",
      "Good luck on this journey! May it bring you fulfillment, joy, and everything you wish for.",
      "Wishing you all the best of luck as you set out to achieve your goals and dreams.",
      "Good luck! May all the opportunities you encounter bring you closer to your dreams.",
      "Best of luck! You have what it takes to conquer everything you put your mind to.",
      "Good luck! Wishing you all the success and happiness as you take your next steps in life.",
      "Wishing you good luck in everything you do! The world is yours to conquer.",
    ],

    quickRecovery: [
      "Wishing you a speedy recovery! Take good care of yourself and get well soon.",
      "Get well soon! I’m sending healing thoughts your way for a quick recovery.",
      "Take your time to rest and recover. Wishing you strength and a speedy healing process.",
      "Hope you feel better soon! Take it easy and let your body heal properly.",
      "Wishing you all the best for a quick recovery. Take care and get well soon!",
      "Sending positive thoughts and prayers for your fast recovery. Get well soon!",
      "I hope each new day brings you closer to a full recovery. Get well soon!",
      "Wishing you strength and patience as you heal. I’m praying for your quick recovery!",
      "Rest up and take care! I hope you feel better with each passing day.",
      "Sending warm thoughts your way for a speedy recovery. Get well soon!",
      "Take good care of yourself and let your body heal. Wishing you a quick recovery!",
      "I’m hoping for a smooth and speedy recovery for you. Get well soon!",
      "Wishing you a fast recovery and a return to good health soon. You’ve got this!",
      "I’m thinking of you and wishing you a speedy recovery. Take care of yourself!",
      "Take all the time you need to get well. Wishing you a full and quick recovery!",
      "Hope you’re feeling better each day. Get well soon and take care of yourself.",
      "May each day bring you more strength and a faster recovery. Get well soon!",
      "Sending healing thoughts your way for a quick recovery. Wishing you strength and peace.",
      "Hoping that each day brings you closer to a full and speedy recovery.",
      "Take it easy and get plenty of rest. Wishing you a quick recovery!",
      "I’m sending you positive thoughts and wishing you strength for a speedy recovery.",
      "Wishing you the best of health and a speedy recovery. Take care and feel better soon!",
      "I hope you’re up and feeling better soon. Sending you my best wishes for a quick recovery.",
      "Rest and heal! I’m praying for your quick and complete recovery.",
      "Sending healing vibes your way. Get well soon and take good care of yourself!",
      "Wishing you a speedy recovery and strength to overcome any obstacles in your path.",
      "You’re in my thoughts and prayers. Wishing you a fast and full recovery!",
      "Sending you healing energy and positive thoughts for a quick recovery!",
      "Take care of yourself and get well soon. Wishing you a speedy recovery!",
      "Wishing you all the best for a quick recovery and strength in the days ahead.",
      "Sending you my best wishes for a speedy recovery. Take good care of yourself!",
      "Get well soon! Take your time and rest. Your health is the most important thing.",
      "Sending you lots of love and warm wishes for a quick and easy recovery.",
      "Get well soon and don’t forget to take care of yourself. You’ll be back on your feet in no time!",
      "I’m praying for your swift recovery and sending you lots of healing thoughts.",
      "Hope you’re feeling better each day. Take it slow and recover fully.",
      "Take it one step at a time and rest up. Wishing you a fast recovery.",
      "May you find strength in this time of recovery. Wishing you a speedy and complete recovery.",
      "Wishing you a quick recovery and hoping that you’re back to feeling your best soon!",
      "Get well soon! I know that with time and rest, you will feel better than ever.",
      "Take it easy and don’t rush your recovery. Wishing you all the best!",
      "Sending healing energy your way. I hope you feel better with every passing day.",
      "I hope you have a swift and smooth recovery. Wishing you strength and good health.",
      "Wishing you a speedy recovery. Take care of yourself and let your body heal.",
      "Sending you warm thoughts and prayers for your quick and full recovery.",
      "Take it easy and focus on getting better. We’re all wishing you a speedy recovery!",
      "I’m so sorry you’re not feeling well. I’m wishing you a quick recovery and good health soon.",
      "May each day bring you closer to being fully healthy. Get well soon!",
      "Rest well and take it slow. I’m hoping for a fast recovery for you.",
      "I hope you’re feeling better soon. Take care of yourself and get well quickly.",
      "Sending you healing thoughts and hoping for a smooth and speedy recovery.",
      "Take all the time you need to get back to full health. Wishing you a speedy recovery!",
      "Sending you strength and good energy as you recover. Get well soon!",
      "I’m sending my love and best wishes your way for a fast and full recovery.",
      "Hope you’re feeling better every day! Wishing you a quick and easy recovery.",
      "Get well soon and don’t rush it. Your health is the most important thing.",
      "Wishing you a quick recovery filled with lots of rest and peace.",
      "Take care of yourself, rest well, and get better soon!",
      "I’m sending positive thoughts your way. Wishing you a fast recovery.",
      "May your recovery be smooth and speedy. Get well soon!",
      "Take good care of yourself! Wishing you a quick recovery and full health.",
      "Wishing you a speedy recovery and sending healing thoughts your way.",
      "Get well soon! I hope you’re back to full health in no time.",
      "Wishing you strength and patience as you heal. Get well soon!",
      "Take care of yourself and rest. Wishing you a fast recovery!",
      "Wishing you a quick recovery and looking forward to seeing you feel better soon.",
      "May your recovery be swift and your health be restored soon. Get well soon!",
      "Sending you all my best wishes for a speedy recovery and better health.",
      "Rest and get well soon! Wishing you a fast recovery and great health.",
      "Take good care of yourself. Wishing you a speedy and smooth recovery.",
      "Hoping that you feel better soon and are back to your old self in no time.",
      "Take care of yourself and get better soon! Wishing you a full recovery.",
      "Sending love and healing thoughts your way for a speedy recovery.",
      "May you have a quick and smooth recovery. We’re all thinking of you!",
      "I hope each day brings you more energy and healing. Get well soon!",
      "Sending positive vibes and hoping for a speedy recovery for you.",
      "Rest up and take care of yourself! Wishing you a fast and complete recovery.",
      "Get well soon! I hope you’re back to feeling your best very soon.",
      "Take it easy and focus on getting better. Wishing you a quick recovery.",
      "Wishing you a speedy recovery! Take care and feel better soon.",
      "Sending you a lot of healing thoughts and wishing you a quick recovery.",
      "I hope you’re feeling better and stronger every day. Get well soon!",
      "Take care and get well soon. Wishing you a speedy and smooth recovery.",
      "Sending you positive thoughts and wishing you a quick and easy recovery.",
      "Rest well and focus on getting better. Wishing you a fast recovery.",
      "Wishing you strength and healing as you recover. Get well soon!",
      "Take your time to heal. Wishing you a speedy and smooth recovery!",
      "Wishing you a speedy recovery and looking forward to seeing you up and about soon.",
      "Sending you healing energy for a quick recovery. Take care!",
      "Get well soon! I know you’ll be feeling better soon enough.",
      "Take it easy and focus on your recovery. Wishing you a quick recovery.",
    ],

    farewellParty: [
      "Wishing you all the best in your new adventure. Farewell and good luck!",
      "It's hard to say goodbye, but I'm wishing you success in everything you do. Farewell!",
      "Saying goodbye is never easy, but I'm excited for your future. Farewell and take care!",
      "Your presence will be greatly missed. Wishing you a bright future ahead. Farewell!",
      "Goodbye and good luck on your next journey. May success follow you wherever you go!",
      "It’s been a pleasure working with you. Wishing you all the best in your new chapter!",
      "Goodbye, my friend. May your future be as bright as your heart. Best of luck!",
      "Farewell and best wishes for your new adventure. You will always be remembered!",
      "You’ll be missed, but I know you’re going on to bigger and better things. Farewell!",
      "It’s time to say goodbye, but not forever. Wishing you all the best in your future endeavors.",
      "Your time here has been unforgettable. Wishing you success in your next chapter. Farewell!",
      "Although we are parting ways, you will always have a special place in my heart. Farewell!",
      "I’m sad to see you go, but I know you’ll do amazing things. Farewell and take care!",
      "Goodbye, my friend. May your future be filled with endless possibilities!",
      "Saying goodbye is hard, but I know great things await you. Wishing you the best!",
      "Your journey here has been inspiring. Wishing you all the success in your new adventure. Farewell!",
      "Farewell and good luck in everything you do. You will be missed!",
      "It’s not goodbye, it’s see you later. Wishing you all the best in your new chapter!",
      "Farewell, but not forever. I wish you all the happiness and success in the world!",
      "I’ll miss you, but I’m excited for what lies ahead for you. Farewell and good luck!",
      "Saying goodbye is never easy, but I wish you all the best for your future. Farewell!",
      "It’s been a joy working with you. Best of luck in your new role, and farewell!",
      "Goodbye, and may your next journey bring you all the success and happiness you deserve!",
      "Wishing you the best as you start this new chapter. Farewell and good luck!",
      "You’ve been a great colleague and friend. I wish you nothing but the best for the future. Farewell!",
      "Goodbye for now! May your new path be filled with wonderful experiences and success!",
      "Parting is such sweet sorrow. Wishing you success in your new journey. Farewell!",
      "Wishing you nothing but the best as you embark on this new adventure. Farewell!",
      "You will always be missed. Farewell and may your future be filled with success and happiness!",
      "It’s been a pleasure working with you. Best of luck in everything you do. Farewell!",
      "Goodbye, and I wish you success in your new role. You’ll do amazing things!",
      "Farewell, and may your future be filled with exciting opportunities and growth!",
      "Parting is never easy, but I’m wishing you all the best in your next adventure. Farewell!",
      "Goodbye and good luck! May your future be bright and full of opportunities!",
      "Wishing you the best as you embark on this exciting new journey. Farewell and take care!",
      "You will always be remembered fondly. Farewell and best wishes for your future endeavors!",
      "Wishing you success and happiness as you start your next chapter. Farewell!",
      "Goodbye, my friend! May your new journey bring you all the success and happiness you deserve!",
      "You’ll be missed, but I know you’re going to do amazing things. Farewell and good luck!",
      "Goodbye, but not forever. Wishing you all the best as you begin your new adventure!",
      "Farewell, and may your next chapter be even better than the last. Best of luck to you!",
      "I’m sad to see you go, but I’m excited for your future. Farewell and good luck!",
      "Wishing you a bright and successful future. Farewell and take care!",
      "Goodbye, and may your future be filled with nothing but success and happiness!",
      "It’s not the end, just a new beginning. Wishing you the best in your next chapter!",
      "Farewell, and may your journey ahead be filled with exciting adventures and opportunities!",
      "I’ll miss you! Wishing you nothing but success and happiness in all that you do. Farewell!",
      "Goodbye for now! I hope your next chapter is full of joy and success. Farewell!",
      "Sending you all my best wishes as you embark on this exciting new chapter. Farewell!",
      "Goodbye, and may your next adventure be filled with happiness and success!",
      "Wishing you all the best in your new role. Farewell and good luck!",
      "Goodbye, and may your future be full of amazing opportunities and successes!",
      "Farewell, and may your new journey be everything you’ve hoped for and more!",
      "Best of luck to you in all your future endeavors. Farewell, and stay in touch!",
      "Goodbye, and I wish you nothing but success and happiness as you start this new chapter!",
      "Wishing you success in everything you do. Farewell, and all the best for your future!",
      "Goodbye for now, and may your future be filled with wonderful adventures and success!",
      "Farewell, and may you achieve all your dreams in your next chapter. Best of luck to you!",
      "It’s hard to say goodbye, but I know you’re going to do amazing things. Farewell!",
      "Goodbye, my friend. Wishing you success and happiness in your new journey!",
      "Farewell, and may your next chapter bring you everything you’ve been wishing for!",
      "Wishing you all the best as you move on to bigger and better things. Farewell!",
      "Goodbye, and I hope your new journey is filled with success, joy, and fulfillment!",
      "It’s been an honor working with you. Farewell, and may your next adventure be even better!",
      "Saying goodbye is never easy, but I’m excited to see all the amazing things you’ll do next. Farewell!",
      "Goodbye, and may your future be as bright as your smile. Best of luck on your new adventure!",
      "Wishing you the best as you move on to your next chapter. Farewell and take care!",
      "Goodbye for now! Wishing you success, happiness, and lots of adventures ahead!",
      "Farewell, and may your future be as bright as your determination and passion. Good luck!",
      "It’s not goodbye, it’s just ‘see you later.’ Best wishes on your next chapter. Farewell!",
      "Wishing you all the best as you embark on this exciting new journey. Farewell, and good luck!",
      "You’ll be missed, but I know you’re going to do amazing things. Farewell and best of luck!",
      "Goodbye, and may your future be filled with endless opportunities and success. Farewell!",
      "Saying goodbye is hard, but I know you’ll make the most of your next chapter. Farewell!",
      "Goodbye, my friend! Wishing you nothing but success and happiness in your future!",
      "Farewell and good luck with your next adventure. You’ve got this!",
      "Goodbye, and may your journey be filled with exciting new opportunities. Take care!",
      "Farewell, and wishing you nothing but the best in your future endeavors!",
      "Goodbye for now, and may your next journey bring you all the success and happiness you deserve.",
      "Farewell, and may your future be filled with joy, success, and endless possibilities!",
      "Goodbye and best of luck in your next chapter. We’ll miss you!",
      "Wishing you all the success and happiness as you embark on this exciting new journey. Farewell!",
      "Goodbye, and may your path ahead be filled with amazing adventures and achievements!",
      "Farewell! May your next chapter bring you joy, success, and fulfillment.",
      "Wishing you the best as you start this new chapter. Farewell, and take care!",
      "Goodbye, and may all your dreams come true in your next adventure. Best of luck!",
      "Farewell, and may your future be as bright as your enthusiasm and dedication!",
      "Goodbye for now, and wishing you success in everything you do. Farewell!",
      "Sending you all my best wishes for your new journey. Farewell and take care!",
      "Goodbye, and may you continue to shine in everything you do. Farewell!",
      "Farewell, and I wish you a future filled with success, joy, and happiness!",
    ],

    workAnniversary: [
      "Congratulations on your work anniversary! Your hard work and dedication are truly appreciated!",
      "Happy Work Anniversary! It’s a pleasure working with you. Here’s to many more years of success together!",
      "Wishing you a wonderful work anniversary. Your dedication is an inspiration to us all!",
      "Happy Work Anniversary! May your journey continue to be filled with success and happiness!",
      "Celebrating your work anniversary today! Your contributions have made a big difference to the team!",
      "Happy Work Anniversary! We appreciate all that you do and are so lucky to have you as part of the team!",
      "Congratulations on reaching this milestone! Here’s to many more years of growth and success!",
      "Happy Work Anniversary! Your commitment to excellence is an inspiration to us all!",
      "Wishing you all the best on your work anniversary. Your hard work doesn’t go unnoticed!",
      "Happy Work Anniversary! Thanks for being such an integral part of our team’s success!",
      "Congratulations on your work anniversary! May you continue to shine and achieve great things!",
      "Happy Work Anniversary! We are grateful for your hard work and dedication. Keep shining!",
      "Wishing you a wonderful work anniversary! Your passion and dedication are a huge asset to our team!",
      "Happy Work Anniversary! Your journey here has been incredible, and we look forward to many more years of success with you!",
      "Congratulations on your work anniversary! Your efforts have made a significant impact on our success!",
      "Happy Work Anniversary! May this milestone be a stepping stone toward even greater accomplishments in your career!",
      "Wishing you a fantastic work anniversary! Your hard work and positive attitude make you a valuable team member!",
      "Happy Work Anniversary! It’s been a pleasure working alongside you. Here’s to many more years of success!",
      "Congratulations on another year of excellence! Your contributions are deeply appreciated by all of us!",
      "Happy Work Anniversary! Your dedication and drive are what make this company thrive!",
      "Wishing you a very Happy Work Anniversary! Your growth and accomplishments here are an inspiration!",
      "Cheers to another year of success! Happy Work Anniversary, and here's to more years of teamwork and achievement!",
      "Happy Work Anniversary! May your career continue to flourish and inspire others along the way!",
      "Wishing you a Happy Work Anniversary! Your hard work, determination, and enthusiasm are truly invaluable to the team!",
      "Happy Work Anniversary! We’re lucky to have someone as dedicated as you on our team. Here’s to more success ahead!",
      "Congratulations on your work anniversary! Your contributions make a real difference, and we look forward to more years together!",
      "Happy Work Anniversary! You are an essential part of our success, and we appreciate everything you do!",
      "Happy Work Anniversary! Your drive and commitment are an inspiration to everyone around you!",
      "Wishing you a Happy Work Anniversary! Your hard work and effort truly make a difference in the team’s success!",
      "Happy Work Anniversary! We appreciate your passion, dedication, and commitment to excellence!",
      "Congratulations on your work anniversary! You make the workplace better with your positive attitude and hard work!",
      "Wishing you the happiest of work anniversaries! Your contributions and hard work make all the difference!",
      "Happy Work Anniversary! Your dedication and enthusiasm are an asset to the entire team!",
      "Congratulations on your work anniversary! Your contributions have been invaluable, and we look forward to continuing this journey with you!",
      "Happy Work Anniversary! It’s a joy working with you, and we’re looking forward to more success together!",
      "Wishing you a Happy Work Anniversary! Your hard work, positivity, and commitment make our team stronger each day!",
      "Happy Work Anniversary! Your passion and dedication inspire all of us. Here's to your continued success!",
      "Congratulations on your work anniversary! Your determination, skills, and hard work make a difference every day!",
      "Happy Work Anniversary! Your effort and dedication are truly inspiring. Here’s to many more years of success!",
      "Wishing you a very Happy Work Anniversary! Your impact and contributions to the team are truly appreciated!",
      "Happy Work Anniversary! Your commitment to excellence makes this company a better place every day!",
      "Congratulations on your work anniversary! We are lucky to have you as part of our team. Your hard work does not go unnoticed!",
      "Wishing you a fantastic work anniversary! Your dedication and passion have truly made a mark in our company!",
      "Happy Work Anniversary! Your hard work and commitment are what keep us moving forward. Here's to more success together!",
      "Congratulations on your work anniversary! Your perseverance and dedication have been instrumental in our growth!",
      "Happy Work Anniversary! Your growth and success inspire us all. May your journey continue to be full of accomplishments!",
      "Wishing you a Happy Work Anniversary! You’ve contributed so much to this team. Here's to many more years of success!",
      "Happy Work Anniversary! Your passion and dedication help drive the team to new heights every day!",
      "Congratulations on another year of excellence! Your contributions are valued and appreciated by everyone!",
      "Happy Work Anniversary! You make work enjoyable for everyone, and we’re so glad to have you on the team!",
      "Wishing you a Happy Work Anniversary! Your dedication and contributions are what make this company great!",
      "Happy Work Anniversary! You’ve made a huge impact here, and we look forward to seeing what you do next!",
      "Congratulations on your work anniversary! Your enthusiasm, dedication, and work ethic are truly inspiring!",
      "Happy Work Anniversary! You continue to set the bar high for all of us, and we are lucky to have you on our team!",
      "Wishing you a Happy Work Anniversary! Your hard work and determination have been key to the company’s success!",
      "Happy Work Anniversary! It’s been a pleasure working with someone as talented and dedicated as you!",
      "Congratulations on your work anniversary! You have been an invaluable asset to the team, and we look forward to working with you for many more years!",
      "Wishing you a very Happy Work Anniversary! Your positive attitude and dedication inspire everyone around you!",
      "Happy Work Anniversary! You make the workplace brighter, and your work ethic motivates all of us!",
      "Congratulations on your work anniversary! Here’s to more milestones and successes ahead!",
      "Happy Work Anniversary! You’ve shown nothing but excellence in your work. Here's to many more years of achievements!",
      "Wishing you the happiest of work anniversaries! Your dedication and hard work are truly appreciated!",
      "Happy Work Anniversary! You’ve shown exceptional commitment and work ethic. Here’s to more years of success!",
      "Congratulations on another successful year! Your contributions have been vital, and we look forward to your continued success!",
      "Wishing you a Happy Work Anniversary! Your positivity, dedication, and commitment to excellence are what make this company thrive!",
      "Happy Work Anniversary! You've done incredible work, and we can't wait to see what you achieve next!",
      "Congratulations on your work anniversary! You are a key member of our team, and your hard work does not go unnoticed!",
      "Wishing you a fantastic work anniversary! Your efforts and achievements have made a lasting impact!",
      "Happy Work Anniversary! Your hard work and passion for excellence are truly inspiring!",
      "Congratulations on your work anniversary! You have accomplished so much, and your potential is limitless!",
      "Happy Work Anniversary! Your commitment to excellence helps our company grow and succeed every day!",
      "Wishing you the best work anniversary! Your hard work is an inspiration to the entire team!",
      "Happy Work Anniversary! Your dedication and work ethic set the standard for everyone here!",
      "Congratulations on your work anniversary! You have made such a positive impact, and we can’t wait to see what’s next!",
      "Happy Work Anniversary! Your dedication is inspiring, and we are so fortunate to have you on our team!",
      "Wishing you a Happy Work Anniversary! Your growth, contributions, and leadership are deeply appreciated!",
      "Happy Work Anniversary! Thank you for your dedication and hard work that make this place amazing!",
      "Congratulations on your work anniversary! Your positive energy and enthusiasm make a difference every day!",
      "Wishing you a very Happy Work Anniversary! Your contributions have made a lasting impact on the team!",
      "Happy Work Anniversary! You’ve shown incredible growth, and I’m excited to see what the future holds for you!",
      "Congratulations on your work anniversary! Your passion and drive have been a true asset to the team!",
      "Happy Work Anniversary! You’ve been an invaluable part of our team’s success. Looking forward to more achievements!",
      "Wishing you a wonderful work anniversary! Your contributions are essential to our continued success!",
      "Happy Work Anniversary! Your dedication is evident, and we are so lucky to have you on the team!",
      "Congratulations on your work anniversary! You make the workplace better for everyone. Here's to many more!",
      "Wishing you the best on your work anniversary! Your hard work and dedication inspire us all!",
      "Happy Work Anniversary! Thank you for everything you do. We look forward to celebrating many more milestones with you!",
    ],

    newYearEve: [
      "Cheers to a new year and another chance for us to get it right. Happy New Year’s Eve 2025!",
      "Wishing you a Happy New Year’s Eve filled with love, laughter, and all the things that bring you joy in 2025!",
      "May the year ahead bring new happiness, new goals, and a lot of new opportunities your way. Happy New Year’s Eve 2025!",
      "As the year comes to an end, I wish you a joyful and prosperous New Year 2025! Let’s make it count.",
      "Happy New Year’s Eve 2025! May the coming year bring endless possibilities and success to you.",
      "Goodbye, 2024, and welcome, 2025! Here’s to a year of new beginnings and unforgettable memories.",
      "Cheers to new adventures, new opportunities, and new beginnings in 2025. Happy New Year’s Eve!",
      "Let’s ring in 2025 with gratitude, love, and joy. Happy New Year’s Eve!",
      "Wishing you peace, love, and laughter in the New Year 2025. May it be your best one yet!",
      "As we say goodbye to the old and welcome in the new, may your heart be full of hope and excitement for 2025.",
      "Goodbye, old year. Hello, 2025! May this New Year’s Eve bring endless joy to your heart!",
      "May this New Year’s Eve bring you closer to your goals and dreams. Wishing you success and happiness in 2025!",
      "Here’s to a year of wonderful memories, exciting opportunities, and all the good things in life. Happy New Year’s Eve 2025!",
      "Let’s celebrate the past year with gratitude and embrace the new year with hope. Cheers to a fantastic 2025!",
      "Wishing you good health, happiness, and all the success in the world in 2025. Happy New Year’s Eve!",
      "May this New Year’s Eve be the start of something amazing. Wishing you love, peace, and joy in 2025!",
      "Out with the old, in with the new! Happy New Year’s Eve to you and your loved ones as we step into 2025!",
      "Here’s to a sparkling 2025 filled with happiness, success, and love. Happy New Year’s Eve!",
      "Let the countdown to 2025 begin! Wishing you a night filled with joy, laughter, and unforgettable moments.",
      "May the new year bring you nothing but the best. Happy New Year’s Eve 2025 to you and your family!",
      "Wishing you a magical New Year’s Eve and a year ahead full of love and happiness in 2025.",
      "The best is yet to come in 2025. May your New Year’s Eve be as amazing as the year ahead!",
      "May the new year be filled with joy, peace, and endless opportunities. Cheers to the future of 2025!",
      "Happy New Year’s Eve 2025! May the coming year be brighter than the last, full of hope and possibilities.",
      "As we bid farewell to 2024, let’s make room for the wonderful opportunities that 2025 holds. Happy New Year’s Eve!",
      "Wishing you a New Year’s Eve full of celebration and a New Year filled with happiness and success in 2025.",
      "Celebrate the moments, cherish the memories, and look forward to the endless possibilities in 2025. Happy New Year’s Eve!",
      "Sending you warm wishes for a Happy New Year’s Eve and a year full of love, health, and prosperity in 2025.",
      "As the clock strikes midnight, may all your dreams and wishes come true. Happy New Year’s Eve 2025!",
      "Cheers to the year that has been and the year ahead in 2025! Happy New Year’s Eve to you and yours.",
      "Wishing you peace, joy, and love as you celebrate the end of this year and the beginning of 2025. Happy New Year’s Eve!",
      "Goodbye, 2024! May the new year bring endless happiness, success, and love your way. Happy New Year’s Eve 2025!",
      "Let’s leave behind the worries of the past and step into 2025 with positivity and hope. Happy New Year’s Eve!",
      "Here’s to an incredible year ahead in 2025! May your New Year’s Eve be filled with laughter, love, and celebration.",
      "Wishing you a year full of laughter, love, and new memories. Happy New Year’s Eve to you and your family in 2025!",
      "As we step into the new year, may our hearts be filled with hope and gratitude for all that’s ahead. Happy New Year’s Eve 2025!",
      "May this New Year bring new hopes, new dreams, and endless possibilities in 2025. Cheers to a fresh start!",
      "Sending you my best wishes for a year filled with love, success, and happiness in 2025. Happy New Year’s Eve!",
      "May your New Year’s Eve be filled with fun, laughter, and special moments with those you love in 2025.",
      "A new year is a fresh start, a blank page waiting to be written. Wishing you all the best this New Year’s Eve 2025!",
      "Happy New Year’s Eve 2025! May the year ahead bring you nothing but love, laughter, and great success.",
      "Here’s to another year of making memories, chasing dreams, and living life to the fullest. Happy New Year’s Eve 2025!",
      "May the upcoming year bring you good health, peace, and prosperity. Happy New Year’s Eve to you and yours in 2025!",
      "Wishing you all the joy, peace, and success that the New Year 2025 has to offer. Happy New Year’s Eve!",
      "May your New Year be as bright and beautiful as the fireworks in the sky tonight. Happy New Year’s Eve 2025!",
      "Cheers to new beginnings, new opportunities, and new experiences in 2025. Happy New Year’s Eve!",
      "Wishing you a peaceful New Year’s Eve and a year ahead full of endless possibilities and success in 2025.",
      "Let’s make this New Year the best one yet in 2025. Cheers to new adventures and lasting memories!",
      "Goodbye, 2024! Thank you for all the lessons, and here’s to a brighter, better, and more successful 2025.",
      "May this New Year be the start of something beautiful. Wishing you love, joy, and peace in 2025.",
      "Happy New Year’s Eve 2025! Let’s welcome 2025 with open hearts and excited minds.",
      "May the new year bring happiness, love, and new opportunities your way. Cheers to a fresh start in 2025!",
      "As the clock strikes midnight, I wish you nothing but joy, peace, and endless possibilities. Happy New Year’s Eve 2025!",
      "Wishing you a wonderful New Year’s Eve and a year ahead filled with health, happiness, and success in 2025.",
      "Here’s to ringing in the new year with a heart full of gratitude and a spirit full of hope. Happy New Year’s Eve 2025!",
      "May the New Year bless you with a year of growth, success, and new opportunities. Happy New Year’s Eve 2025!",
      "May the new year bring peace, joy, and prosperity to you and your loved ones. Happy New Year’s Eve 2025!",
      "Cheers to a new year filled with hope, love, and the possibility of all things new. Happy New Year’s Eve 2025!",
      "Wishing you the happiest New Year’s Eve and a year filled with love, laughter, and success in 2025.",
      "Let’s celebrate the past and look forward to a bright future. Happy New Year’s Eve 2025!",
      "Happy New Year’s Eve 2025! May the coming year be full of new beginnings and countless blessings.",
      "Wishing you a spectacular New Year’s Eve and an even better year ahead in 2025.",
      "Out with the old, in with the new! Let’s make this New Year one to remember in 2025. Happy New Year’s Eve!",
      "May your New Year’s Eve be filled with joy, laughter, and all the things that bring you happiness in 2025.",
      "Let’s make every moment count as we welcome in a new year full of hope and promise in 2025.",
      "May the year ahead be full of adventure, success, and new opportunities. Happy New Year’s Eve 2025!",
      "Wishing you a New Year’s Eve that’s as amazing as the year to come. Cheers to new beginnings in 2025!",
      "May the New Year bring you everything you’ve dreamed of and more. Happy New Year’s Eve 2025!",
      "Goodbye, old year. Welcome, new year! May this year be filled with more blessings and joy in 2025.",
      "Wishing you all the best this New Year’s Eve and a year ahead full of love, laughter, and success in 2025.",
      "As we celebrate the arrival of the new year, may it bring you peace, love, and good fortune in 2025.",
      "Happy New Year’s Eve 2025! Let’s embrace the coming year with excitement and hope.",
      "Wishing you a year filled with new goals, new dreams, and new opportunities. Happy New Year’s Eve 2025!",
      "Here’s to a fabulous 2025! May this year bring you closer to your dreams and fill your heart with joy.",
    ],

    christmas: [
      "Wishing you a Merry Christmas and a Happy New Year filled with love, joy, and peace! 🎄🎅",
      "May the spirit of Christmas fill your heart with love and warmth. Merry Christmas! 🎁🎄",
      "Here’s to a joyful Christmas surrounded by the love of family and friends. Merry Christmas! 🌟🎅",
      "May your Christmas sparkle with moments of love, laughter, and goodwill. 🎄✨ Merry Christmas!",
      "Wishing you peace, love, and joy this holiday season. Merry Christmas and Happy New Year! 🎉🎄",
      "Merry Christmas! May your heart be light and your stockings be full of love and happiness. 🎅❤️",
      "May your Christmas be blessed with lots of love, happiness, and warmth. Merry Christmas! 🎄❤️",
      "Sending love and best wishes for a Merry Christmas and a Happy New Year! 🎁🎄",
      "May this Christmas bring you everything you’ve hoped for, and more. Wishing you love and happiness. 🎄🌟",
      "Wishing you a Merry Christmas filled with joy and laughter. Enjoy every moment with your loved ones! 🎅✨",
      "May the season of Christmas fill your heart with peace and your home with love. Merry Christmas! 🎄❤️",
      "Merry Christmas! May your home be filled with love, laughter, and all the magic of the season. 🌟🎁",
      "Sending you warm wishes for a Merry Christmas and a Happy New Year full of love and blessings! 🎄🎉",
      "Merry Christmas! May your days be merry, bright, and full of happiness with your loved ones. 🎄🎅",
      "Wishing you a Christmas that’s merry and bright, filled with love, joy, and holiday cheer! 🎁✨",
      "Have yourself a merry little Christmas, and may the new year bring you all the happiness you deserve! 🎄🎅",
      "Wishing you a blessed Christmas and a New Year filled with happiness, good health, and love. 🎄🎉",
      "Merry Christmas to you and your family! May your holidays be filled with warmth, laughter, and love. 🎄❤️",
      "May your Christmas be as wonderful as you are! Wishing you a joyful and blessed holiday season. 🎅🎄",
      "May the joy of Christmas bring love and light to your heart and home. Merry Christmas! 🎁🎄",
      "Wishing you peace, love, and happiness this Christmas and throughout the New Year! 🎅🎄",
      "Merry Christmas! May the holiday season fill your heart with joy and your life with blessings. 🎄🎁",
      "Wishing you all the magic of Christmas, and may it bring you endless joy and happiness. 🎄✨",
      "Merry Christmas! May the holiday season bring you everything you’ve been wishing for. 🎁🎄",
      "May your Christmas be filled with laughter, love, and memories to last a lifetime. Merry Christmas! 🎅🎄",
      "Wishing you a very Merry Christmas and a New Year filled with love, laughter, and happiness! 🎄🎉",
      "Merry Christmas! May the holidays bring you peace, joy, and everything you’ve hoped for. 🎁🎄",
      "Wishing you a Merry Christmas and a Happy New Year full of wonderful moments and memories. 🎅🎄",
      "May your Christmas shine bright with love, laughter, and joy. Merry Christmas! 🎄🎉",
      "Sending you warm wishes and joyful blessings this Christmas season. Merry Christmas! 🎁✨",
      "Merry Christmas to you and your family! May this season of joy bring endless blessings into your life. 🎄🎅",
      "May your Christmas be filled with love, light, and happiness. Merry Christmas! 🎄✨",
      "Wishing you a Merry Christmas and a Happy New Year filled with all the good things life has to offer. 🎁🎄",
      "Merry Christmas! May you have a holiday season as magical as the season itself! 🎅🎄",
      "Wishing you all the best this Christmas and in the year to come. May it be filled with joy! 🎄🎉",
      "Merry Christmas! May this holiday bring you closer to those you love and make beautiful memories together. 🎁🎄",
      "Wishing you love, peace, and happiness this Christmas and throughout the New Year! 🎅🎄",
      "Merry Christmas! May the season be bright, and the new year be full of happiness and success. 🎄🎉",
      "May this Christmas season fill your home with love, peace, and joy. Merry Christmas to you and your family! 🎄🎅",
      "Sending you warm Christmas wishes and blessings for a New Year full of happiness and health. 🎁🎄",
      "Wishing you a Merry Christmas filled with fun, love, and happiness, and may the New Year be even better! 🎄✨",
      "May the Christmas season bring you closer to those you love and fill your heart with peace. Merry Christmas! 🎁🎄",
      "Merry Christmas! May this holiday bring you everything you need for a joyful season and a happy year. 🎄🎉",
      "Wishing you a festive Christmas surrounded by warmth, joy, and love. Merry Christmas and Happy New Year! 🎄🎅",
      "Sending you all my love this Christmas season. May your holidays be filled with peace and joy! 🎄❤️",
      "Merry Christmas! May your home be filled with happiness, your heart with love, and your life with success. 🎁🎄",
      "May your Christmas sparkle with moments of love, joy, and goodwill. Merry Christmas to you and your family! 🎅✨",
      "Wishing you all the best this Christmas season and throughout the coming year! Merry Christmas! 🎄🎉",
      "Merry Christmas! May your life be full of love and your heart full of happiness this season. 🎁🎄",
      "Wishing you a Merry Christmas and a Happy New Year filled with wonderful moments and beautiful memories! 🎄🎉",
      "May your Christmas be merry and bright! Wishing you warmth, joy, and laughter this holiday season. 🎅🎄",
      "Merry Christmas! May your days be filled with peace, joy, and love. Sending you blessings this holiday season. 🎁🎄",
      "Wishing you a wonderful Christmas season filled with warmth, joy, and love. Merry Christmas! 🎄🎅",
      "May the magic of Christmas bring you peace and joy. Wishing you a season of blessings and happiness. 🎄✨",
      "Merry Christmas to you and your loved ones! May this season bring you endless happiness and peace. 🎅🎄",
      "Wishing you a Merry Christmas filled with all the things you love most. Have a wonderful holiday season! 🎁🎄",
      "May your Christmas be filled with joy, laughter, and beautiful moments that last forever. Merry Christmas! 🎄❤️",
      "Merry Christmas! Wishing you the gift of love, the blessing of hope, and the joy of family this season. 🎅🎄",
      "Sending you warm Christmas wishes for a season filled with love, joy, and laughter. Merry Christmas! 🎄🎁",
    ],

    eidMubarak: [
      "Eid Mubarak! May this blessed day bring peace, happiness, and prosperity to you and your family. 🌙✨",
      "Eid Mubarak! May Allah’s blessings shine upon you and your loved ones today and always. 🌙💫",
      "Wishing you a joyous Eid full of love, peace, and happiness. Eid Mubarak! 🌙❤️",
      "Eid Mubarak! May your prayers be answered, your dreams come true, and your life be filled with peace and joy. 🌙🙏",
      "Eid Mubarak! May this Eid bring endless happiness and blessings into your life and fill your heart with love. 🌙💖",
      "Eid Mubarak! May the light of the moon fill your life with joy and happiness, and may Allah’s mercy guide you always. 🌙✨",
      "On this blessed occasion of Eid, I pray that Allah showers you with His blessings. Eid Mubarak! 🌙🙏",
      "Eid Mubarak to you and your family! May your life be filled with joy, prosperity, and Allah’s endless blessings. 🌙💫",
      "Wishing you a joyous Eid, full of laughter, peace, and the love of your dear ones. Eid Mubarak! 🌙💖",
      "Eid Mubarak! May Allah’s grace and mercy be with you and your loved ones today and forever. 🌙✨",
      "May Allah’s blessings fill your heart with peace, your home with joy, and your life with success. Eid Mubarak! 🌙🙏",
      "Eid Mubarak! May this beautiful day bring you closer to your dreams and bring you happiness and contentment. 🌙💫",
      "On this special day, I wish you peace, prosperity, and success. Eid Mubarak to you and your family! 🌙✨",
      "Eid Mubarak! May the magic of this special day bring peace, joy, and love to your heart and home. 🌙❤️",
      "Eid Mubarak! May Allah bless you with health, happiness, and success in every step of your life. 🌙🙏",
      "Wishing you a blessed Eid filled with peace, love, and joy. Eid Mubarak! 🌙💖",
      "Eid Mubarak! May this day bring countless blessings to your life and may your heart always be filled with happiness. 🌙✨",
      "Eid Mubarak! May Allah’s mercy shine upon you, and may this Eid be the beginning of new and exciting chapters in your life. 🌙💫",
      "Wishing you a wonderful and blessed Eid. May Allah’s grace fill your home with peace and happiness. 🌙❤️",
      "Eid Mubarak! May Allah’s light guide you through every step of your journey and grant you success in all your endeavors. 🌙🙏",
      "Eid Mubarak! May this day bring you and your family peace, happiness, and prosperity. 🌙✨",
      "On this blessed occasion of Eid, I pray for your happiness, success, and good health. Eid Mubarak! 🌙💖",
      "Eid Mubarak! May your home be filled with love, laughter, and the warmth of Allah’s blessings. 🌙❤️",
      "May this Eid bring you joy, peace, and prosperity, and may all your wishes come true. Eid Mubarak! 🌙💫",
      "Eid Mubarak to you and your loved ones! May your life be filled with Allah’s guidance and blessings. 🌙🙏",
      "Eid Mubarak! May the joy of Eid fill your heart with love, your home with peace, and your life with success. 🌙✨",
      "Wishing you a happy and blessed Eid! May Allah grant you success in all aspects of your life. 🌙💖",
      "Eid Mubarak! May this beautiful day bring peace and happiness to your heart and home. 🌙❤️",
      "Eid Mubarak! May Allah grant you the strength and wisdom to achieve your dreams and make your life full of blessings. 🌙🙏",
      "On this Eid, I pray that Allah fills your life with joy, prosperity, and endless blessings. Eid Mubarak! 🌙✨",
      "Eid Mubarak! May this festive day bring peace and joy to you and your family, and may all your prayers be answered. 🌙💫",
      "Wishing you a very happy Eid filled with blessings, joy, and endless love. Eid Mubarak! 🌙💖",
      "Eid Mubarak! May the blessings of Allah be with you always, filling your heart with peace and your life with joy. 🌙❤️",
      "May this Eid bring you closer to your loved ones and may all your dreams come true. Eid Mubarak! 🌙✨",
      "Eid Mubarak! May Allah bless you with happiness, good health, and long-lasting peace. 🌙🙏",
      "On this Eid, may your heart be filled with gratitude and your life with blessings. Eid Mubarak! 🌙💫",
      "Eid Mubarak! Wishing you and your family a joyous Eid full of laughter, love, and blessings. 🌙❤️",
      "Eid Mubarak! May the light of Allah shine upon you and bring you peace, happiness, and prosperity. 🌙✨",
      "May this Eid bring peace, joy, and love to your life. Wishing you all the best on this blessed day. Eid Mubarak! 🌙💖",
      "Eid Mubarak! May your life always be filled with love, joy, and the blessings of Allah. 🌙💫",
      "Wishing you a wonderful Eid filled with blessings, love, and happiness. Eid Mubarak! 🌙❤️",
      "Eid Mubarak to you and your family! May this beautiful day fill your heart with love and your life with success. 🌙✨",
    ],

    diwaliWishList: [
      "Wishing you a Diwali filled with love, joy, and prosperity. May the lights of Diwali brighten your path. 🪔✨",
      "May this Diwali bring happiness, peace, and prosperity to your life. Have a sparkling and joyous celebration! 🪔🎆",
      "Happy Diwali! May your home be filled with the light of Diwali, and may your life be as beautiful as the Diwali lights. 🪔💖",
      "On this auspicious occasion, may you be blessed with love, wealth, and health. Wishing you a joyful Diwali! 🪔✨",
      "May the festival of lights bring you endless happiness and prosperity. Have a joyous and blessed Diwali! 🪔🎇",
      "Wishing you a Diwali full of fun, joy, and laughter. May this festival light up your life and bring you success. 🪔💫",
      "Happy Diwali! May the light of the diyas bring peace and prosperity to you and your family. 🪔🙏",
      "On this Diwali, may your life be as bright and beautiful as the lights that adorn your home. 🪔🌟",
      "Wishing you all the joy and happiness this Diwali brings. May the year ahead be full of good health and success. 🪔🎇",
      "May this Diwali light up new dreams, fresh hopes, and new beginnings for you. Have a wonderful Diwali! 🪔💖",
      "Wishing you a Diwali filled with blessings, peace, and joy. May your heart and home be filled with light! 🪔🎆",
      "Let the lights of Diwali guide you to success, peace, and prosperity. Wishing you a blessed Diwali! 🪔✨",
      "Happy Diwali! May this festival of lights bring joy and happiness to your home and fill your heart with love. 🪔❤️",
      "May your Diwali be as bright and joyful as the sparkling lights that decorate your home. 🪔🌟",
      "On this special day, may the glow of Diwali lights fill your life with warmth, happiness, and positivity. 🪔💫",
      "Wishing you a Diwali that is full of prosperity, good health, and all the happiness in the world. 🪔🎇",
      "May your Diwali be filled with sweet moments, delicious treats, and the warmth of family and friends. 🪔🍬",
      "Happy Diwali! May the beauty of the lights fill your life with love, success, and happiness. 🪔💖",
      "Wishing you a prosperous and joyous Diwali! May your life be filled with the light of happiness. 🪔🌟",
      "This Diwali, may the divine lights bring peace and joy to your home and heart. Wishing you a blessed festival! 🪔🙏",
      "On this Diwali, I pray for your good health, happiness, and success. Have a prosperous Diwali! 🪔🎆",
      "May the light of the Diwali diyas guide your path to success and happiness. Have a blessed Diwali! 🪔✨",
      "Happy Diwali! May your life be as colorful and vibrant as the Diwali lights around you. 🪔🎇",
      "Wishing you a Diwali that is full of joy, warmth, and celebration. May it be a new beginning for you! 🪔🌟",
      "May the festival of Diwali bring light to your life and brighten every corner of your home. 🪔💫",
      "May the happiness and light of Diwali fill your heart and home with peace, joy, and love. 🪔💖",
      "Happy Diwali! Let the brightness of the festival bring you success and fulfillment in everything you do. 🪔🌟",
      "This Diwali, may your heart be filled with joy and your home with the light of peace. Wishing you all the best! 🪔💫",
      "Wishing you and your family a Diwali full of joy, laughter, and love. May the festival bring you closer to your dreams! 🪔🎇",
      "May the divine light of Diwali fill your life with love, peace, and happiness. Wishing you a prosperous Diwali! 🪔✨",
      "Happy Diwali! May the lights of the festival illuminate your life and bring success and happiness to your door. 🪔🎆",
      "May this Diwali bring you all the success you deserve and the happiness you’ve always wanted. 🪔💖",
      "On this Diwali, let the lights of the diyas fill your heart with love, your life with joy, and your home with peace. 🪔✨",
      "Wishing you a joyful Diwali filled with warmth, love, and the fragrance of success. 🪔🌟",
      "May the light of Diwali bring you wisdom, peace, and love. Wishing you a happy and prosperous Diwali! 🪔🎇",
      "Happy Diwali to you and your family! May this festival of lights bring new beginnings and endless joy to your life. 🪔💖",
      "Let this Diwali fill your life with joy, success, and peace. Wishing you all the best in the coming year! 🪔✨",
      "On this special day, may the glow of the Diwali lights fill your life with positivity and love. 🪔💫",
      "May the light of Diwali guide you through challenges and illuminate your path to success. Have a blessed Diwali! 🪔🌟",
    ],

    valentinesDay: [
      "Happy Valentine's Day! 💖 May your day be filled with love, joy, and everything beautiful. 💐",
      "Wishing you a day as sweet as you are. Happy Valentine's Day, my love! 🌹💘",
      "Happy Valentine's Day! May this day bring you as much happiness as you bring into my life. 💝🌷",
      "You're my heart, my soul, and my everything. Happy Valentine's Day! 💖💋",
      "To the love of my life, Happy Valentine's Day! I’m so grateful for every moment we share. 💘🌹",
      "Wishing you a Valentine's Day full of love, laughter, and unforgettable memories! 💌💐",
      "Happy Valentine's Day to the one who holds my heart! 💝 You mean the world to me. 🌍",
      "You're the reason my heart beats. Happy Valentine's Day to my forever love. 💖💕",
      "On this special day, I just want to remind you how much I love you. Happy Valentine's Day! 💘💐",
      "Happy Valentine's Day! May love and happiness surround you today and always. 🌹💖",
      "You're the most beautiful part of my life. Wishing you a Valentine's Day as wonderful as you. 💝💐",
      "Happy Valentine's Day! You're the best thing that ever happened to me. I love you! 💘🌸",
      "To my valentine: Thank you for filling my life with love and happiness. 💖🌷",
      "Happy Valentine's Day, my love! You complete me in every way. 💘🌹",
      "May this Valentine's Day be the start of many more to come, filled with love, joy, and bliss. 💝💖",
      "I love you more with every passing day. Happy Valentine's Day to my heart's desire! 💘🌺",
      "You are my sunshine on even the darkest days. Wishing you a Valentine's Day filled with light and love. ☀️💖",
      "Happy Valentine's Day to the one who makes my heart skip a beat! 💘💐",
      "You and me, together forever. Happy Valentine's Day, my darling! 💖💋",
      "Happy Valentine's Day to the love of my life! I feel so blessed to have you by my side. 💘🌹",
      "Sending you all my love today and always. Happy Valentine's Day! 💝💐",
      "Happy Valentine's Day! You're my soulmate, my best friend, and my true love. 💖💘",
      "You are my forever Valentine. Wishing you all the love in the world today and always. 💘🌹",
      "Happy Valentine's Day, sweetheart! You make every day special, and I'm so lucky to have you. 💖💌",
      "To the one who holds my heart, Happy Valentine's Day! I love you endlessly. 💘💖",
      "May your heart be filled with all the love and happiness you deserve. Happy Valentine's Day! 💝🌷",
      "Happy Valentine's Day to the one who makes my world go round. I love you more than words can say. 💘💖",
      "You are my heart, my love, and my life. Wishing you a Valentine's Day full of love and sweetness! 💝💐",
      "I’m so grateful to spend another Valentine's Day with you. You are my everything. 💘💖",
      "Happy Valentine's Day! Your love fills my heart with joy and my life with meaning. 💝🌹",
      "To my forever love, Happy Valentine's Day! My love for you is eternal. 💖💘",
      "Happy Valentine's Day to the one who makes my life so beautiful. I love you more every day. 💘🌷",
      "Wishing you a day filled with love, happiness, and laughter. Happy Valentine's Day! 💝💐",
      "You are my heart’s deepest desire. Wishing you a Valentine's Day filled with love and magic! 💖✨",
      "Happy Valentine's Day, my love! Every moment with you is a treasure. 💘💝",
      "To the love of my life, you make every day worth living. Happy Valentine's Day! 💖🌹",
      "Happy Valentine's Day! Thank you for making my life so beautiful and filled with love. 💝💐",
      "I never knew what true love was until I met you. Happy Valentine's Day! 💘💖",
      "Wishing you a day full of love and happiness. Happy Valentine's Day to the one I adore! 💝🌷",
      "You're my Valentine today and always. Wishing you a day filled with love and joy. 💘💖",
      "Happy Valentine’s Day! You are the most important person in my life, and I love you dearly. 💝💖",
      "May our love continue to grow and blossom. Wishing you the happiest Valentine's Day! 💘🌹",
      "Happy Valentine's Day! You are the best part of my life, and I love you to the moon and back. 🌙💖",
      "To my heart’s one true love, Happy Valentine’s Day! 💘💝",
      "Happy Valentine's Day to the one who makes my heart sing with joy. 💖🎶",
      "You are my heart's desire, now and forever. Happy Valentine's Day, my love! 💘💐",
      "I am forever grateful for your love. Happy Valentine's Day! 💝💖",
      "Wishing you a Valentine's Day full of love, laughter, and all things wonderful. 💘🌹",
      "To the love of my life, Happy Valentine's Day! You make every day special. 💖💋",
    ],

    heartfeltThanks: [
      "Thank you from the bottom of my heart for all your kindness and support. 💖",
      "Your generosity means the world to me. I’m truly grateful for your help. 🙏",
      "A heartfelt thank you for everything you’ve done for me. You’ve made a huge difference in my life. 💝",
      "Thank you for your unwavering support and love. I appreciate you more than words can express. 💐",
      "I am deeply thankful for your kindness. You’ve made my life brighter. 🙏💖",
      "Thank you for always being there for me, in good times and bad. You are a true blessing. 🌟",
      "From the depths of my heart, thank you for all that you’ve done for me. 💖",
      "Your kindness and generosity are greatly appreciated. I’m so lucky to have you in my life. 💐",
      "Thank you for your constant support and encouragement. You’ve made a big impact on my life. 🙏",
      "I can’t express enough how much I appreciate everything you’ve done for me. Thank you! 💝",
      "A big thank you for your help, guidance, and understanding. I’m forever grateful. 🙏",
      "I’m so grateful for your constant love and support. Thank you from the bottom of my heart. 💖",
      "Thank you for being my rock, my confidant, and my source of strength. I am forever thankful. 💐",
      "Words can’t fully express how grateful I am for everything you’ve done. Thank you so much! 🙏💝",
      "Thank you for your kindness and generosity. You’ve truly touched my heart. 💖",
      "I can’t thank you enough for all the ways you’ve helped and supported me. 💐",
      "From the core of my being, thank you for your love and care. You mean the world to me. 💝",
      "Thank you for believing in me when no one else did. Your faith has made all the difference. 🙏💖",
      "I appreciate you more than you will ever know. Thank you for being there for me. 💐",
      "I’m incredibly thankful for your thoughtful gesture. It truly means the world to me. 💖",
      "Thank you for being so kind-hearted and generous. I’m blessed to have you in my life. 🙏",
      "Your support has made a huge difference in my life. I will forever be grateful. 💝",
      "Thank you for going out of your way to help me. Your kindness will never be forgotten. 💖",
      "I can’t find the right words to express how thankful I am for all that you’ve done. 💐",
      "Your kindness and compassion have touched my heart in ways I can’t express. Thank you! 💖",
      "Thank you for your continuous love and support. You make life easier and brighter. 🌟",
      "From the bottom of my heart, thank you for being there for me. You are truly appreciated. 💐",
      "Thank you for all the little things you do. They mean more than you know. 💝",
      "I’m eternally grateful for your kindness. Your generosity has changed my life. 🙏",
      "Thank you for your amazing support. Your kindness will never be forgotten. 💖",
      "I appreciate everything you’ve done for me. Thank you for making a difference in my life. 💐",
      "Thank you so much for your kindness and patience. You’ve been a true blessing in my life. 🙏💝",
      "I am deeply thankful for your continued support and encouragement. You’ve made all the difference. 💖",
      "Your heartfelt gestures of kindness mean more to me than words can express. Thank you! 💝",
      "I will forever be grateful for everything you’ve done for me. Thank you from the bottom of my heart. 💐",
      "Thank you for your kindness and for always being there when I needed you most. 💖",
      "Your generosity and thoughtfulness have made such an impact on me. Thank you! 🙏💝",
      "I can’t thank you enough for everything you’ve done. You’ve made my world a better place. 💖",
      "Thank you for helping me grow and become better. Your guidance has made a world of difference. 💐",
      "I am so grateful for everything you’ve done for me. Thank you for making my life easier and better. 💖",
      "Your thoughtfulness has made such an impression on me. Thank you for your kindness. 🙏💝",
      "Thank you for your loving support and encouragement. You are a true gift in my life. 💐",
      "Words can’t express how grateful I am for your constant care and support. Thank you! 💖",
      "You have no idea how much your kindness means to me. Thank you for everything. 💝",
      "Thank you for always being a shoulder to lean on. Your support means the world to me. 🙏💖",
      "I am truly grateful for all you’ve done. Your generosity has touched my heart in ways words cannot express. 💝",
      "Thank you for being a source of strength and encouragement. I appreciate you more than you know. 💖",
      "Your kindness has made a lasting impact on my heart. Thank you for everything. 💐",
      "Thank you for being my biggest cheerleader and helping me every step of the way. 💝",
      "I can’t thank you enough for your amazing love and support. You’ve made a huge difference in my life. 💖",
      "From the bottom of my heart, thank you for being there for me through thick and thin. 💐",
      "Thank you for your unwavering support. Your belief in me has inspired me to be the best I can be. 💝",
      "Thank you for your kindness and generosity. You have touched my heart deeply. 💖",
      "I appreciate your help and support more than words can say. Thank you so much. 💐",
      "Thank you for being a true friend and for always being there for me. I’m so grateful for you. 💝",
      "I am forever thankful for your kindness and support. You make my life better in every way. 💖",
      "I am so thankful for your friendship and all the love you’ve shown me. Thank you! 💐",
      "Thank you for all your patience, understanding, and support. I’m truly grateful to have you in my life. 💖",
      "You’ve been such a blessing in my life. Thank you for everything you’ve done for me. 💝",
      "I’ll forever be grateful for your generosity and the way you’ve impacted my life. Thank you! 💖",
    ],
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Text copied");
      })
      .catch((err) => {
        alert(`Error copying text: ${err}`);
      });
  };

  return (
    <>
      <NavBar />
      <div className="main-container-hindi">
        <div className="uper-cont">
          <div className="box-containt-up">
            {wishList.map((event) => (
              <div
                key={event.id}
                className={`box-up ${
                  selectCat === event.category ? "selected" : ""
                }`}
                onClick={() => handleSelecttab(event.category)}
              >
                <h3>{event.msg}</h3>
              </div>
            ))}
          </div>

          <div className="res-selection">
            <select
              name="selecttab"
              id="selecttab"
              onChange={handleCategoryChange}
            >
              <option value="default" disabled>
                Please select
              </option>
              {wishList.map((option) => (
                <option key={option.value} value={option.category}>
                  {option.msg}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="lower-container-wish">
          <ul>
            {categories[selectCat].map((item, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                {item}
                <span
                  onClick={() => copyText(item)}
                  style={{
                    color: "white",
                    display: "block",
                    borderRadius: "5px",
                    fontSize: "14px",
                    justifyContent: "flex-end",
                    background: "#2F327D",
                    padding: "1px 10px 1px 10px",
                    textAlign: "center",
                    width: "60px",
                    height: "26px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                >
                  Copy
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* --------------responsive-------------- */}
        <div className="lower-container-wish-res">
          <ul>
            {categories[selectedCategory].map((item, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                {item}
                <span
                  onClick={() => copyText(item)}
                  style={{
                    color: "white",
                    display: "block",
                    borderRadius: "5px",
                    fontSize: "14px",
                    justifyContent: "flex-end",
                    background: "#2F327D",
                    padding: "1px 10px 1px 10px",
                    textAlign: "center",
                    width: "60px",
                    height: "26px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                >
                  Copy
                </span>
              </li>
            ))}
          </ul>
        </div>
        {/* ---------------end---------------------- */}
      </div>
      <div className="footer-punjabi">
        <InnerFooter />
      </div>
    </>
  );
}
