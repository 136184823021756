import React from "react";
import "../css/Footer.css";
import logo from "../assets/app_icon.png";
import instaLogo from "../assets/instagram.png";
import youtube from "../assets/youtube.png";
import { useNavigate } from "react-router-dom";

export default function InnerFooter() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div class="footer-container">
        <div class="footer-left">
          <div className="footer-left-name">
            <img src={logo} alt="App Logo" class="logo" />
            <p class="app-name">Sanj - Basic Learning App</p>
          </div>

          <div className="footer-left-li">
            <ul class="footer-links">
              {/* <li>
                <a href="AboutUs">Our vision</a>
              </li>
              <li>
                <a href="ContactUs">Attributions and Credits</a>
              </li>
              <li>
                <a href="Privacy">Upcoming Update</a>
              </li> */}
              {/* <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/OurVision")}
              >
                - Our vision
              </p> */}
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/AttributesAndCredits")}
              >
                - Attributions and Credits
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/UpcomingUpdates")}
              >
                - Upcoming Update
              </p>
            </ul>
          </div>
        </div>

        <div class="footer-right">
          <div className="footer-right-name">
            <p class="app-name-right">Follow Us on</p>
            <a href="https://youtube.com/@sanj-basic-learning" target="_blank">
              <img src={youtube} alt="App Logo" class="logo-right" />
            </a>
            <a
              href="https://www.instagram.com/sanj_learing_app?igsh=MWhyM2ltY2ZmZ3o4dg%3D%3D&utm_source=qr"
              target="_blank"
            >
              <img
                src={instaLogo}
                alt="App Logo"
                class="logo-right"
                style={{ marginLeft: "10px" }}
              />
            </a>
          </div>

          <div className="footer-right-li">
            <ul class="footer-links">
              {/* <li>
                <a href="AboutUs">About Sanj</a>
              </li>
              <li>
                <a href="ContactUs">Contact Us</a>
              </li>
              <li>
                <a href="Privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="Terms">Terms and Condition</a>
              </li> */}
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/AboutUs")}
              >
                - About Sanj
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/ContactUs")}
              >
                - Contact Us
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/Privacy")}
              >
                - Privacy Policy
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/TermsAndConditions")}
              >
                - Terms and Condition
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import logo from "../assets/app_icon.png";
// import '../css/InnerFooter.css'

// export default function InnerFooter() {
//   return (
//     <div className="footer-inner">
//       <div class="footer-container-inner">
//         <div class="footer-left-inner">
//           <img src={logo} alt="App Logo" class="logo-inner" />
//           <p class="app-name-inner">Sanj - Basic Learning App</p>
//         </div>
//         <div class="footer-right-inner">
//           <ul class="footer-links-inner">
//             <li>
//               <a href="#about-us">About Us</a>
//             </li>
//             <li>
//               <a href="#contact-us">Contact Us</a>
//             </li>
//             <li>
//               <a href="#contact-us">Privacy Policy</a>
//             </li>
//             <li>
//               <a href="#contact-us">Terms and Condition</a>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }
