import React from "react";
import "../css/Aboutus.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";

export default function Privacy() {
  return (
    <>
      <NavBar />
      <div className="about-main">
        <h2>Privacy Policy for Sanj App</h2>
        <p>
          At Sanj, the privacy of our users is of utmost importance to us. This
          Privacy Policy document outlines the types of personal information
          collected by our app and how we use it.
        </p>

        <h3>Information Collection and Use:</h3>

        <p>
          Sanj does not collect any personal information from its users. We do
          not require users to register or provide any personal data to use our
          app. We do not track, store, or share any information about our users.
        </p>

        <h3>Log Data:</h3>

        <p>
          Like many mobile applications, Sanj app may collect non-personally
          identifiable information that your device sends whenever you use our
          app. This may include information such as the type of device you are
          using, your device operating system version, usage data, and other
          diagnostic data. However, we do not associate this information with
          any specific user.
        </p>

        <h3>Third-party Services:</h3>

        <p>
          Sanj app may contain links to external websites or services that are
          not operated by us. Please be aware that we have no control over the
          content and practices of these websites or services, and we cannot be
          held responsible for their privacy policies.
        </p>

        <h3>Changes to This Privacy Policy:</h3>

        <p>
          Sanj app may update this Privacy Policy from time to time. We will
          notify users of any changes by posting the new Privacy Policy on this
          page. Users are advised to review this Privacy Policy periodically for
          any changes.
        </p>

        <h3>Contact Us:</h3>

        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <a href="mailto:app92innovation@gmail.com">
            app92innovation@gmail.com
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
}
