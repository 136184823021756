import React from "react";
import banner from "../assets/home_banner.png";
import abc from "../assets/ic_abc.png";
import appIcon from "../assets/app_icon.png";
import math from "../assets/ic_math.png";
import game from "../assets/ic_game.png";
import hindi from "../assets/ic_hindi.png";
import mango from "../assets/mango.png";
import slid2 from "../assets/slid1.png";
import slid3 from "../assets/slid2.png";
import slid1 from "../assets/slid3.png";
import testing from "../assets/hm_right_img.png";
import gk from "../assets/ic_splash.png";

import greenAndroid from "../assets/green_android.png";

import "../css/Home.css";
import NavBar from "../utils/NavBar";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../utils/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    img: slid1,
  },

  {
    img: slid3,
  },
];

export default function Home() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <NavBar />
      <div className="main-container-h">
        <div className="banner-h">
          <Slider {...settings}>
            {data.map((d) => (
              <div className="background-h">
                <img src={d.img} alt="" />
              </div>
            ))}
          </Slider>
        </div>

        <div className="midle-h">
          <div className="midle-left">
            <h1>
              <span style={{ color: "red" }}>DIVE INTO</span> THE GREATNESS OF
              <span style={{ color: "green" }}> LEARNING</span>
            </h1>
            <p>
              Explore the ultimate Punjabi, Hindi, and English learning app that
              makes language learning fun and engaging! This app is designed for
              kids and learners of all ages, combining interactive lessons,
              vocabulary-building activities, and pronunciation guides to master
              these languages effortlessly. Dive into exciting fun games like
              word puzzles, matching challenges, and memory games to make
              learning enjoyable and effective. Whether you're enhancing your
              mother tongue or picking up a new language, this app ensures a
              seamless learning experience while keeping you entertained. Start
              your journey of language discovery today!
            </p>
          </div>

          <div className="midle-right">
            <img src={testing} alt="" />
          </div>
        </div>

        <div className="lower-container-h">
          <h3
            className="main-heading-h"
            style={{ marginTop: "20px", textAlign: "justify" }}
          >
            EXPLORE & LEARN
          </h3>

          <div className="box-containt-h">
            <div className="box-home" onClick={() => navigate("Punjabi")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>Punjabi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("Hindi")}>
              <img className="icon" src={hindi} alt="" />
              <h2>Hindi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("English")}>
              <img className="icon" src={abc} alt="" />
              <h2>English</h2>
            </div>
            <div className="box-home" onClick={() => navigate("Maths")}>
              <img className="icon" src={math} alt="" />
              <h2>Math</h2>
            </div>
            {/* <div className="box-home">
              <img className="icon" src={gk} alt="" />
              <h2>GK</h2>
            </div> */}
          </div>
        </div>

        <div className="lower-container-h">
          <h3
            className="main-heading-h"
            style={{ marginTop: "20px", textAlign: "justify" }}
          >
            SOCIAL VIBES
          </h3>

          <div className="box-containt-h">
            <div className="box-home" onClick={() => navigate("Wish")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>WISHES</h2>
            </div>
            <div className="box-home" onClick={() => navigate("Quotes")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>QUOTES</h2>
            </div>
            {/* <div className="box-home" onClick={() => navigate("English")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>COMING SOON</h2>
            </div> */}
          </div>
        </div>

        <div className="download-h">
          <div className="download-left">
            <h3>DOWNLOAD ANDROID APP NOW!</h3>
            <div className="down-li">
              <ul>
                <li>
                  <b>Sanj App:</b> Learn the basics in a simple and enjoyable
                  way with engaging content for everyone.{" "}
                </li>
                <li>
                  <b>Social Vibes:</b> Discover inspring, meaningful messages,
                  and thoughtful wishes to share with friends and family.
                </li>
              </ul>
            </div>
            <p style={{ marginTop: "20px", fontSize: "18px" }}>
              Start your journey of learning and spreading positivity today!
            </p>
          </div>

          <div className="download-right">
            <div className="down-img">
              <img src={greenAndroid} alt="" />
            </div>

            <div className="down-button">
              <a href="https://play.google.com/store/apps/details?id=com.kids.punjabi&hl=en">
                <button>SANJ - BASIC LEARNING APP</button>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.appinnovation.socialvibes&hl=en">
                <button id="lower-btn">SOCIAL VIBES</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
