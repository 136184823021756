import React, { useEffect, useState } from "react";
import "../css/Maths.css";
import NavBar from "../utils/NavBar";
import InnerFooter from "../utils/InnerFooter";

export default function Maths() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  const counting = Array.from({ length: 100 }, (_, index) => index + 1);

  const [num1, setNum1] = useState(null);
  const [num2, setNum2] = useState(null);
  const [sum, setSum] = useState(null);

  const [numToSub1, setNumToSub1] = useState(null);
  const [numToSub2, setNumToSub2] = useState(null);
  const [subNumber, setSubNumber] = useState(null);

  const [numToMul1, setNumToMul1] = useState(null);
  const [numToMul2, setNumToMul2] = useState(null);
  const [mulNumber, setMulNumbert] = useState(null);

  const [numToDiv1, setNumToDiv1] = useState(null);
  const [numToDiv2, setNumToDiv2] = useState(null);
  const [divNumber, setDivNumber] = useState(null);

  useEffect(() => {
    generateAndAddNumbers();
    generateAndSubNumbers();
    generateAndMulNumbers();
    generateAndDivNumbers();
  }, []);

  const generateAndAddNumbers = () => {
    const randomNum1 = Math.floor(Math.random() * 100) + 1;
    const randomNum2 = Math.floor(Math.random() * 100) + 1;
    console.log("Generated Numbers:", randomNum1, randomNum2);
    setNum1(randomNum1);
    setNum2(randomNum2);
    const result = randomNum1 + randomNum2;
    setSum(result);
    console.log("Sum of two numbers:", result);
  };

  const generateAndSubNumbers = () => {
    const randomNum1 = Math.floor(Math.random() * 100) + 1;
    const randomNum2 = Math.floor(Math.random() * 100) + 1;
    console.log("Generated Numbers:", randomNum1, randomNum2);
    setNumToSub1(randomNum1);
    setNumToSub2(randomNum2);
    const result = randomNum1 - randomNum2;
    setSubNumber(result);
    console.log("Sum of two numbers:", result);
  };

  const generateAndMulNumbers = () => {
    const randomNum1 = Math.floor(Math.random() * 100) + 1;
    const randomNum2 = Math.floor(Math.random() * 100) + 1;
    console.log("Generated Numbers:", randomNum1, randomNum2);
    setNumToMul1(randomNum1);
    setNumToMul2(randomNum2);
    const result = randomNum1 * randomNum2;
    setMulNumbert(result);
    console.log("Sum of two numbers:", result);
  };

  const generateAndDivNumbers = () => {
    const randomNum1 = Math.floor(Math.random() * 100) + 1;
    const randomNum2 = Math.floor(Math.random() * 100) + 1;
    console.log("Generated Numbers:", randomNum1, randomNum2);
    setNumToDiv1(randomNum1);
    setNumToDiv2(randomNum2);
    const result = randomNum1 / randomNum2;
    setDivNumber(result.toFixed(1));
    console.log("Sum of two numbers:", result);
  };

  const generateMultiplicationTable = (num) => {
    const table = [];
    for (let i = 1; i <= 10; i++) {
      table.push(
        <tr key={`${num}x${i}`}>
          <td>
            {num} x {i}
          </td>
          <td>=</td>
          <td>{num * i}</td>
        </tr>
      );
    }
    return table;
  };

  const [currentTable, setCurrentTable] = useState(2); // Start from table of 2

  // Function to handle the button click
  const showNextTable = () => {
    if (currentTable < 20) {
      setCurrentTable(currentTable + 1);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="uper-cont">
          <div className="box-containt-up">
            {/* <div
              className={`box-up ${showtab === 1 ? "selected" : ""}`}
              onClick={() => handletab(1)}
            >
              <h3>ਵਰਣਮਾਲਾ</h3>
            </div> */}
            <div
              className={`box-up ${showtab === 1 ? "selected" : ""}`}
              onClick={() => handletab(1)}
            >
              <h3>Counting(1-100)</h3>
            </div>

            <div
              className={`box-up ${showtab === 2 ? "selected" : ""}`}
              onClick={() => handletab(2)}
            >
              <h3>Table(2-20)</h3>
            </div>

            <div
              className={`box-up ${showtab === 3 ? "selected" : ""}`}
              onClick={() => handletab(3)}
            >
              <h3>Addition (+)</h3>
            </div>
            <div
              className={`box-up ${showtab === 4 ? "selected" : ""}`}
              onClick={() => handletab(4)}
            >
              <h3>Subtraction (-)</h3>
            </div>
            <div
              className={`box-up ${showtab === 5 ? "selected" : ""}`}
              onClick={() => handletab(5)}
            >
              <h3>Multiplication (x)</h3>
            </div>
            <div
              className={`box-up ${showtab === 6 ? "selected" : ""}`}
              onClick={() => handletab(6)}
            >
              <h3>Division (÷)</h3>
            </div>
          </div>

          <div className="res-selection">
            <select name="selecttab" id="selecttab" onChange={handleselect}>
              <option value="default" disabled>
                Please select
              </option>
              <option value="1">Counting(1-100)</option>
              <option value="2">Table(2-20)</option>
              <option value="3">Addition (+)</option>
              <option value="3">Subtraction (-)</option>
              <option value="3">Multiplication (x)</option>
              <option value="3">Division (÷)</option>
            </select>
          </div>
        </div>

        <div className="lower-container">
          <div className={showtab == 1 ? "box-containt" : "hide"}>
            {counting.map((letter) => (
              <div className="box-content">
                <div className="box">
                  <div className="in-box">
                    <h2>{letter}</h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 2 ? "table-container" : "hide"}>
            <div>
              {/*   <h2>Tables</h2> */}
              <div
                style={{
                  padding: "0px 10px 5px 10px",
                  fontSize: "25px",
                  borderBottom: "2px solid grey",
                  marginBottom: "10px",
                }}
              >
                Table of {currentTable}
              </div>
              <table className="math-table">
                <tbody>{generateMultiplicationTable(currentTable)}</tbody>
              </table>
              <div className="next-btn-maths" onClick={() => showNextTable()}>
                Next
              </div>
            </div>
          </div>
          <div className={showtab == 3 ? "box-containt" : "hide"}>
            <div className="box-content-number">
              <h1>Additions</h1>
              <h2>{num1}</h2>
              <h2>{num2}</h2>
              <div
                style={{
                  height: 4,
                  width: 200,
                  color: "grey",
                  marginBottom: 20,
                }}
              >
                ______________________
              </div>
              <h2>{sum}</h2>
              <div
                className="next-btn-maths"
                onClick={() => generateAndAddNumbers()}
              >
                Next
              </div>
            </div>
          </div>
          <div className={showtab == 4 ? "box-containt" : "hide"}>
            <div className="box-content-number">
              <h1>Substraction</h1>
              <h2>{numToSub1}</h2>
              <h2>{numToSub2}</h2>
              <div
                style={{
                  height: 4,
                  width: 200,
                  color: "grey",
                  marginBottom: 20,
                }}
              >
                ______________________
              </div>
              <h2>{subNumber}</h2>
              <div
                className="next-btn-maths"
                onClick={() => generateAndSubNumbers()}
              >
                Next
              </div>
            </div>
          </div>
          <div className={showtab == 5 ? "box-containt" : "hide"}>
            <div className="box-content-number">
              <h1>Multiplication</h1>
              <h2>{numToMul1}</h2>
              <h2>{numToMul2}</h2>
              <div
                style={{
                  height: 4,
                  width: 200,
                  color: "grey",
                  marginBottom: 20,
                }}
              >
                ______________________
              </div>
              <h2>{mulNumber}</h2>
              <div
                className="next-btn-maths"
                onClick={() => generateAndMulNumbers()}
              >
                Next
              </div>
            </div>
          </div>
          <div className={showtab == 6 ? "box-containt" : "hide"}>
            <div className="box-content-number">
              <h1>Division</h1>
              <h2>{numToDiv1}</h2>
              <h2>{numToDiv2}</h2>
              <div
                style={{
                  height: 4,
                  width: 200,
                  color: "grey",
                  marginBottom: 20,
                }}
              >
                ______________________
              </div>
              <h2>{divNumber}</h2>
              <div
                className="next-btn-maths"
                onClick={() => generateAndDivNumbers()}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </div>
      <InnerFooter />
    </>
  );
}
