import React from "react";
import "../css/Aboutus.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";

export default function AttributesAndCredits() {
  return (
    <>
      <NavBar />
      <div className="about-main">
        <h2>Attributes And Credits</h2>

        <h3>
          <strong>Attributes:</strong>
        </h3>
        <p>Icons and graphics provided by:</p>
        <ul>
          <li>
            <a href="https://www.freepngimg.com" target="_blank">
              https://www.freepngimg.com
            </a>
          </li>
          <li>
            <a href="https://www.freepik.com" target="_blank">
              https://www.freepik.com
            </a>
          </li>
          <li>
            <a href="https://www.flaticon.com" target="_blank">
              https://www.flaticon.com
            </a>
          </li>
        </ul>

        <p>
          <h3>Credits:</h3>
        </p>
        <p>
          Developed by <b>Sarabjeet Singh</b>
          <br />
          Inspired by <b>Gursanj Kaur</b>
        </p>
        <br />
      </div>
      <Footer />
    </>
  );
}
