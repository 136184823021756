import React, { useEffect, useState } from "react";
import "../css/Wishes.css";
import InnerFooter from "../utils/InnerFooter";

import NavBar from "../utils/NavBar";

export default function Quotes() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  const [selectCat, setSelectCat] = useState("inspireQuote");

  // Step 2: Handle the click to change the category
    const handleSelecttab = (categoryId) => {
      setSelectCat(categoryId);
    };
  
    const [selectedCategory, setSelectedCategory] = useState("inspireQuote");
  
    const handleCategoryChange = (event) => {
      setSelectedCategory(event.target.value);
      console.log(event.target.value);
    };

  const quoteList = [
    { id: 101, msg: "Inspirational Quotes", category: "inspireQuote" },
    { id: 102, msg: "Motivational Quotes", category: "motivationalQuote" },
    { id: 103, msg: "Love Quotes", category: "loveQuote" },
    { id: 104, msg: "Friendship Quotes", category: "friendshipQuote" },
    { id: 105, msg: "Success Quotes", category: "successQuote" },
    { id: 106, msg: "Happiness Quotes", category: "happinessQuote" },
    { id: 107, msg: "Life Quotes", category: "lifeQuote" },
    { id: 108, msg: "Wisdom Quotes", category: "wisdomQuote" },
    // { id: 109, msg: "Positive Thinking Quotes", category: "" },
    // { id: 110, msg: "Courage Quotes", category: "" },
    // { id: 111, msg: "Faith Quotes", category: "" },
    // { id: 112, msg: "Attitude Quotes", category: "" },
    // { id: 113, msg: "Peace Quotes", category: "" },
    // { id: 114, msg: "Gratitude Quotes", category: "" },
    // { id: 115, msg: "Leadership Quotes", category: "" },
    // { id: 116, msg: "Patience Quotes", category: "" },
    // { id: 117, msg: "Forgiveness Quotes", category: "" },
    // { id: 118, msg: "Family Quotes", category: "" },
    // { id: 119, msg: "Relationship Quotes", category: "" },
    // { id: 120, msg: "Self-Love Quotes", category: "" },
    // { id: 121, msg: "Hard Work Quotes", category: "" },
    // { id: 122, msg: "Humor Quotes", category: "" },
    // { id: 123, msg: "Resilience Quotes", category: "" },
    // { id: 124, msg: "Dreams Quotes", category: "" },
    // { id: 125, msg: "Mindfulness Quotes", category: "" },
    // { id: 126, msg: "Kindness Quotes", category: "" },
    // { id: 127, msg: "Change Quotes", category: "" },
    // { id: 128, msg: "Adventure Quotes", category: "" },
    // { id: 129, msg: "Hope Quotes", category: "" },
    // { id: 130, msg: "Determination Quotes", category: "" },
  ];

  const copyText = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert("Text copied");
      })
      .catch((err) => {
        alert(`Error copying text: ${err}`);
      });
  };

  const categories = {
    inspireQuote: [
      "The only way to do great work is to love what you do.",
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
      "Believe you can and you're halfway there.",
      "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
      "The future belongs to those who believe in the beauty of their dreams.",
      "It does not matter how slowly you go as long as you do not stop.",
      "The only limit to our realization of tomorrow is our doubts of today.",
      "Hardships often prepare ordinary people for an extraordinary destiny.",
      "Your time is limited, so don’t waste it living someone else’s life.",
      "The best way to predict the future is to create it.",
      "You miss 100% of the shots you don’t take.",
      "It always seems impossible until it’s done.",
      "In the middle of every difficulty lies opportunity.",
      "The journey of a thousand miles begins with one step.",
      "Don’t watch the clock; do what it does. Keep going.",
      "Your only limit is your mind.",
      "The harder you work for something, the greater you’ll feel when you achieve it.",
      "Don’t wait for opportunity. Create it.",
      "Dream big and dare to fail.",
      "The difference between who you are and who you want to be is what you do.",
      "Keep your face always toward the sunshine—and shadows will fall behind you.",
      "Act as if what you do makes a difference. It does.",
      "Life is 10% what happens to us and 90% how we react to it.",
      "It always seems impossible until it’s done.",
      "Opportunities don't happen, you create them.",
      "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
      "Success is the sum of small efforts, repeated day in and day out.",
      "You are never too old to set another goal or to dream a new dream.",
      "Everything you can imagine is real.",
      "It is never too late to be what you might have been.",
      "Start where you are. Use what you have. Do what you can.",
      "Don't stop when you're tired. Stop when you're done.",
      "The secret of getting ahead is getting started.",
      "Success is not how high you have climbed, but how you make a positive difference to the world.",
      "Success is not in what you have, but who you are.",
      "You are braver than you believe, stronger than you seem, and smarter than you think.",
      "The best way to get started is to quit talking and begin doing.",
      "Failure will never overtake me if my determination to succeed is strong enough.",
      "It does not matter how many times you get knocked down, but how many times you get up.",
      "The difference between ordinary and extraordinary is that little extra.",
      "The only place where success comes before work is in the dictionary.",
      "Success doesn’t just find you. You have to go out and get it.",
      "Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart.",
      "Keep going. Everything you need will come to you at the perfect time.",
      "Success is not the key to happiness. Happiness is the key to success.",
      "Great things never come from comfort zones.",
      "If you can dream it, you can do it.",
      "Do not wait to strike till the iron is hot, but make it hot by striking.",
      "Success is not just about making money. It’s about making a difference.",
      "Do one thing every day that scares you.",
      "The future depends on what you do today.",
      "Success usually comes to those who are too busy to be looking for it.",
      "Don't limit your challenges. Challenge your limits.",
      "Nothing will work unless you do.",
      "The only way to achieve the impossible is to believe it is possible.",
      "Do what you can with all you have, wherever you are.",
      "Life is not about waiting for the storm to pass, but about learning to dance in the rain.",
      "Life begins at the end of your comfort zone.",
      "It’s not whether you get knocked down, it’s whether you get up.",
      "You don’t have to be great to start, but you have to start to be great.",
      "Everything you’ve ever wanted is on the other side of fear.",
      "Hard work beats talent when talent doesn’t work hard.",
      "If you want to achieve greatness stop asking for permission.",
      "If you’re going through hell, keep going.",
      "Success is walking from failure to failure with no loss of enthusiasm.",
      "Great things are not done by impulse, but by a series of small things brought together.",
      "The harder the battle, the sweeter the victory.",
      "Success is a journey, not a destination.",
      "You must be the change you wish to see in the world.",
      "The way to get started is to quit talking and begin doing.",
      "Don’t be afraid to give up the good to go for the great.",
      "The harder you work, the luckier you get.",
      "Be yourself; everyone else is already taken.",
      "To live a creative life, we must lose our fear of being wrong.",
      "Dream big and dare to fail.",
      "Courage is one step ahead of fear.",
      "It’s never too late to be what you might have been.",
      "You’re braver than you believe, and stronger than you seem, and smarter than you think.",
      "A champion is afraid of losing. Everyone else is afraid of winning.",
      "Success is a state of mind. If you want success, start thinking of yourself as a success.",
      "Don’t wait for opportunity. Create it.",
      "Strive not to be a success, but rather to be of value.",
      "In the middle of difficulty lies opportunity.",
      "Believe in yourself and all that you are.",
      "Every great dream begins with a dreamer.",
      "Nothing in the world can take the place of Persistence.",
      "The secret of getting ahead is getting started.",
      "Success doesn’t come from what you do occasionally, it comes from what you do consistently.",
      "Do what you love and you’ll never work a day in your life.",
      "Don’t stop when you’re tired. Stop when you’re done.",
      "The best revenge is massive success.",
      "The only time you should ever look back is to see how far you’ve come.",
      "Great things never come from comfort zones.",
      "Learn as if you will live forever, live like you will die tomorrow.",
      "You have to expect things of yourself before you can do them.",
      "Your success is determined by your own efforts.",
    ],

    motivationalQuote: [
      "The only way to achieve the impossible is to believe it is possible.",
      "You are never too old to set another goal or to dream a new dream.",
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
      "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
      "The future belongs to those who believe in the beauty of their dreams.",
      "It does not matter how slowly you go as long as you do not stop.",
      "Everything you can imagine is real.",
      "The only limit to our realization of tomorrow is our doubts of today.",
      "The journey of a thousand miles begins with one step.",
      "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
      "Your time is limited, so don’t waste it living someone else’s life.",
      "Don’t wait for the perfect moment. Take the moment and make it perfect.",
      "Keep your face always toward the sunshine—and shadows will fall behind you.",
      "What you get by achieving your goals is not as important as what you become by achieving your goals.",
      "The best way to predict the future is to create it.",
      "Hardships often prepare ordinary people for an extraordinary destiny.",
      "Your only limit is your mind.",
      "Act as if what you do makes a difference. It does.",
      "Success is not how high you have climbed, but how you make a positive difference to the world.",
      "Success is not in what you have, but who you are.",
      "The harder you work for something, the greater you’ll feel when you achieve it.",
      "Do not wait to strike till the iron is hot, but make it hot by striking.",
      "It always seems impossible until it’s done.",
      "In the middle of every difficulty lies opportunity.",
      "Dream big and dare to fail.",
      "The difference between who you are and who you want to be is what you do.",
      "Keep going. Everything you need will come to you at the perfect time.",
      "Do one thing every day that scares you.",
      "Life is 10% what happens to us and 90% how we react to it.",
      "Don’t stop when you’re tired. Stop when you’re done.",
      "The secret of getting ahead is getting started.",
      "Success is the sum of small efforts, repeated day in and day out.",
      "The way to get started is to quit talking and begin doing.",
      "Everything you’ve ever wanted is on the other side of fear.",
      "Nothing will work unless you do.",
      "Success usually comes to those who are too busy to be looking for it.",
      "Believe you can and you’re halfway there.",
      "The only place where success comes before work is in the dictionary.",
      "Keep going. You’re closer than you think.",
      "Don’t be afraid to give up the good to go for the great.",
      "The harder the battle, the sweeter the victory.",
      "Don’t wait for opportunity. Create it.",
      "Life begins at the end of your comfort zone.",
      "If you can dream it, you can do it.",
      "Great things never come from comfort zones.",
      "Success doesn’t just find you. You have to go out and get it.",
      "Opportunities don't happen, you create them.",
      "Success is not just about making money. It’s about making a difference.",
      "Great things are not done by impulse, but by a series of small things brought together.",
      "You must be the change you wish to see in the world.",
      "Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart.",
      "If you’re going through hell, keep going.",
      "The only way to do great work is to love what you do.",
      "Success doesn’t come from what you do occasionally, it comes from what you do consistently.",
      "Don’t limit your challenges. Challenge your limits.",
      "Nothing in the world can take the place of Persistence.",
      "The secret of getting ahead is getting started.",
      "Success is walking from failure to failure with no loss of enthusiasm.",
      "Great things are not done by impulse, but by a series of small things brought together.",
      "If you can dream it, you can do it.",
      "You are braver than you believe, stronger than you seem, and smarter than you think.",
      "The best revenge is massive success.",
      "Do what you love and you’ll never work a day in your life.",
      "Do what you can with all you have, wherever you are.",
      "Success is a state of mind. If you want success, start thinking of yourself as a success.",
      "The harder you work, the luckier you get.",
      "To live a creative life, we must lose our fear of being wrong.",
      "Dream big and dare to fail.",
      "Courage is one step ahead of fear.",
      "It’s never too late to be what you might have been.",
      "You’re braver than you believe, and stronger than you seem, and smarter than you think.",
      "A champion is afraid of losing. Everyone else is afraid of winning.",
      "Success is a journey, not a destination.",
      "You have to expect things of yourself before you can do them.",
      "Success is not in what you have, but who you are.",
      "Life is not about waiting for the storm to pass, but about learning to dance in the rain.",
      "The way to get started is to quit talking and begin doing.",
      "If you want to achieve greatness stop asking for permission.",
      "If you’re going through hell, keep going.",
      "Success is walking from failure to failure with no loss of enthusiasm.",
      "Great things never come from comfort zones.",
      "Learn as if you will live forever, live like you will die tomorrow.",
      "You have to expect things of yourself before you can do them.",
      "Your success is determined by your own efforts.",
      "The best way to predict the future is to create it.",
    ],

    loveQuote: [
      "Love is not about how many days, months, or years you have been together. Love is about how much you love each other ❤️.",
      "The best thing to hold onto in life is each other ❤️.",
      "In all the world, there is no heart for me like yours ❤️.",
      "I love you not only for what you are but for what I am when I am with you ❤️.",
      "You are my heart, my life, my one and only thought ❤️.",
      "Where there is love, there is life ❤️.",
      "Love is composed of a single soul inhabiting two bodies ❤️.",
      "Being deeply loved by someone gives you strength, while loving someone deeply gives you courage ❤️.",
      "I am yours, don't give myself back to me ❤️.",
      "Every love story is beautiful, but ours is my favorite ❤️.",
      "To love and be loved is to feel the sun from both sides ❤️.",
      "You are my today and all of my tomorrows ❤️.",
      "In your smile I see something more beautiful than the stars ❤️.",
      "You are the source of my joy, the center of my world, and the whole of my heart ❤️.",
      "I love you more than I have ever found a way to say to you ❤️.",
      "You are the love of my life, my soulmate, my everything ❤️.",
      "When I saw you, I fell in love, and you smiled because you knew ❤️.",
      "I never want to stop making memories with you ❤️.",
      "True love is eternal, infinite, and always like itself ❤️.",
      "I love you to the moon and back ❤️.",
      "My heart is, and always will be, yours ❤️.",
      "I would rather spend one lifetime with you, than face all the ages of this world alone ❤️.",
      "You are my heart, my life, my one and only thought ❤️.",
      "Being in love with you makes every morning worth waking up to ❤️.",
      "The best thing in life is finding someone who knows all your mistakes and weaknesses, and still thinks you are completely amazing ❤️.",
      "You don’t have to be perfect to be amazing, just be yourself and I’ll love you with all my heart ❤️.",
      "The way you make me feel is the best part of every day ❤️.",
      "I never want to stop holding your hand ❤️.",
      "Every moment spent with you is a moment I cherish ❤️.",
      "You are my forever and always ❤️.",
      "I can’t imagine my life without you in it ❤️.",
      "I love you for all that you are, all that you have been, and all you are yet to be ❤️.",
      "You are my everything, and I’m so grateful for your love ❤️.",
      "You make my world a better place, just by being in it ❤️.",
      "I love you more than words can say ❤️.",
      "You will forever be my always ❤️.",
      "Your love is the best gift I have ever received ❤️.",
      "In your eyes, I see the love I have always dreamed of ❤️.",
      "I’m yours, no refunds ❤️.",
      "You have my whole heart, for my whole life ❤️.",
      "I fall in love with you every single day ❤️.",
      "With you, every moment is special ❤️.",
      "My love for you is a journey, starting at forever and ending at never ❤️.",
      "I will love you until the stars go out and the tides no longer turn ❤️.",
      "You complete me in every way possible ❤️.",
      "You are my favorite hello and my hardest goodbye ❤️.",
      "Every day I love you more and more ❤️.",
      "I will always choose you ❤️.",
      "Your love makes everything in life beautiful ❤️.",
      "Love isn’t just a feeling, it’s a choice, and I choose you every day ❤️.",
      "With you, I feel complete ❤️.",
      "I will love you always and forever ❤️.",
      "You are my heart, my home, my forever ❤️.",
      "I love you not only for what you are, but for what I am when I am with you ❤️.",
      "When I’m with you, I feel at home ❤️.",
      "You are the reason I smile every day ❤️.",
      "There is no greater happiness than being in love with you ❤️.",
      "You’re my everything, always and forever ❤️.",
      "Every time I see you, I fall in love with you all over again ❤️.",
      "When we are together, nothing else matters ❤️.",
      "You are my today and all of my tomorrows ❤️.",
      "I love you, not just for who you are, but for who I am when I am with you ❤️.",
      "You are my happily ever after ❤️.",
      "Your love is like a dream come true ❤️.",
      "I love you more than there are stars in the sky ❤️.",
      "My love for you grows with each passing day ❤️.",
      "There is no one else I’d rather be with than you ❤️.",
      "My life is so much better with you in it ❤️.",
      "I have found the one whom my soul loves ❤️.",
      "I will never stop choosing you ❤️.",
      "You are my everything and more ❤️.",
      "I love you with all my heart ❤️.",
      "You are the love of my life ❤️.",
      "When I’m with you, I’m home ❤️.",
      "You make my heart skip a beat ❤️.",
      "You are the best part of my day ❤️.",
      "You’re the reason I can’t stop smiling ❤️.",
      "I love you more than yesterday, but less than tomorrow ❤️.",
      "In your arms, I find my peace and my happiness ❤️.",
      "You are the love of my life, and that will never change ❤️.",
      "I would rather be with you than with anyone else ❤️.",
      "You make every moment special just by being with me ❤️.",
      "You are my dream come true ❤️.",
      "You make my heart beat faster ❤️.",
      "I love you more than words can describe ❤️.",
      "I am so lucky to have you by my side ❤️.",
      "With you, everything feels possible ❤️.",
      "You are the love of my dreams, and I will cherish you forever ❤️.",
      "Together, we make the perfect team ❤️.",
      "I’m grateful for your love and everything you do ❤️.",
      "Every day with you is a reminder of how lucky I am ❤️.",
      "You are my forever and always ❤️.",
      "Your love makes my world go round ❤️.",
      "You are the best thing that’s ever happened to me ❤️.",
      "I am so in love with you ❤️.",
      "You make my life complete ❤️.",
      "No one will ever love you the way I do ❤️.",
      "I love you for eternity ❤️.",
      "You will forever be my always ❤️.",
      "In your love, I find everything I need ❤️.",
      "You are my greatest blessing, and I thank God for you every day ❤️.",
      "You have my heart, forever and always ❤️.",
      "When I’m with you, I feel at home ❤️.",
    ],

    friendshipQuote: [
      "A friend is someone who knows all about you and still loves you ❤️.",
      "Friendship is born at that moment when one person says to another, 'What! You too? I thought I was the only one!' ❤️.",
      "True friends stab you in the front ❤️.",
      "A friend is someone who gives you total freedom to be yourself ❤️.",
      "Friendship is a single soul dwelling in two bodies ❤️.",
      "Good friends are like stars. You don’t always see them, but you know they’re always there ❤️.",
      "A true friend is somebody who can make us feel better no matter how bad things may be ❤️.",
      "A friend to all is a friend to none ❤️.",
      "Friendship is not about who you’ve known the longest; it’s about who walked into your life, said ‘I’m here for you,’ and proved it ❤️.",
      "True friends are never apart, maybe in distance but never in heart ❤️.",
      "The most beautiful discovery true friends make is that they can grow separately without growing apart ❤️.",
      "A good friend knows all your stories. A best friend helped you write them ❤️.",
      "There are no strangers here; Only friends you haven’t yet met ❤️.",
      "Friendship is the only cement that will ever hold the world together ❤️.",
      "Friends are the siblings God never gave us ❤️.",
      "A friend is someone who makes it easy to believe in yourself ❤️.",
      "A real friend is one who walks in when the rest of the world walks out ❤️.",
      "Friendship is a rainbow between two hearts ❤️.",
      "Friendship isn’t a big thing, it’s a million little things ❤️.",
      "A true friend is the greatest of all blessings ❤️.",
      "A friend is someone who can see the truth and pain in you even when you are fooling everyone else ❤️.",
      "No matter how much time passes, a true friend will never leave your side ❤️.",
      "A good friend will help you move. But a best friend will help you move a dead body ❤️.",
      "A friend is someone who gives you their full attention, even when the world is trying to take it from you ❤️.",
      "Friendship is born when two people meet and say, 'You too? I thought I was the only one!' ❤️.",
      "Best friends are the people you can do anything and nothing with and still have the best time ❤️.",
      "Friendship is not about who you’ve known the longest, it’s about who came and never left your side ❤️.",
      "A friend is one who strengthens you with love, leads you with wisdom, and comforts you with their presence ❤️.",
      "The best way to destroy an enemy is to make him a friend ❤️.",
      "A friend is someone who understands your past, believes in your future, and accepts you just the way you are ❤️.",
      "Life is better with friends ❤️.",
      "You don’t have to be crazy to be my friend, but it helps ❤️.",
      "Friendship is a ship that sails through the roughest storms ❤️.",
      "A true friend never gets in your way unless you happen to be going down ❤️.",
      "There is nothing on this earth more to be prized than true friendship ❤️.",
      "I would rather walk with a friend in the dark, than alone in the light ❤️.",
      "Friendship isn’t about being inseparable, it’s being separated and nothing changes ❤️.",
      "True friends are the ones who are there for you when they’d rather be anywhere else ❤️.",
      "A real friend is one who walks in when the rest of the world walks out ❤️.",
      "A true friend is somebody who can make us feel better no matter how bad things may be ❤️.",
      "Friendship is the only ship that never sinks ❤️.",
      "A friend is one who believes in you even when you’ve lost belief in yourself ❤️.",
      "Friends are the family you choose ❤️.",
      "A true friend is someone who is there for you when they would rather be anywhere else ❤️.",
      "Friendship is a sheltering tree ❤️.",
      "A friend is someone who makes your life easier just by being part of it ❤️.",
      "Friendship is the comfort of knowing that even when you feel alone, you’re not ❤️.",
      "In the cookie of life, friends are the chocolate chips ❤️.",
      "The most valuable gift you can give someone is your friendship ❤️.",
      "Best friends are the ones who make your problems their own and try to help you solve them ❤️.",
      "True friends are never apart, maybe in distance but never in heart ❤️.",
      "A real friend is someone who does their best to lift you up when you’re feeling down ❤️.",
      "Friendship is like a plant that needs to be nurtured with time ❤️.",
      "A friend is someone who makes you laugh even when you feel like crying ❤️.",
      "A true friend is somebody who can make us feel better no matter how bad things may be ❤️.",
      "The language of friendship is not words but meanings ❤️.",
      "You don’t need a best friend, you need a true friend who’ll stand by you ❤️.",
      "Friendship is one mind in two bodies ❤️.",
      "You can always count on a friend to have your back, even when things are tough ❤️.",
      "A friend who understands your tears is much more valuable than a lot of friends who only know your smile ❤️.",
      "True friends stab you in the front ❤️.",
      "Friends make you smile, but best friends make you laugh until you pee your pants ❤️.",
      "A true friend is somebody who can make us feel better no matter how bad things may be ❤️.",
      "A true friend sees the hurt in your eyes while everyone else believes the smile on your face ❤️.",
      "Friendship isn’t about who you’ve known the longest. It’s about who walked into your life and said, ‘I’m here for you’ ❤️.",
      "A friend is someone who knows the song in your heart and sings it back to you when you have forgotten the words ❤️.",
      "Best friends are those who make your world a better place just by being in it ❤️.",
      "There is nothing like a friend, unless it is a friend with chocolate ❤️.",
      "Friends are like stars, they come and go, but the ones that stay are the ones that glow ❤️.",
      "A true friend will never judge you ❤️.",
      "Friends are the ones who help you discover who you really are ❤️.",
      "There’s not a word in the dictionary to explain how much I appreciate your friendship ❤️.",
      "Friendship is like a rainbow; you need both the sun and the rain to make it appear ❤️.",
      "Friendship is a gift you give yourself ❤️.",
      "True friendship is a plant of slow growth ❤️.",
      "No road is long with good company ❤️.",
      "A friend is someone who gives you total freedom to be yourself ❤️.",
      "True friends are always together in spirit ❤️.",
      "A friend is someone who makes it easy to believe in yourself ❤️.",
      "It’s not what we have in life, but who we have in our life that matters ❤️.",
      "True friends are never apart, maybe in distance but never in heart ❤️.",
      "There’s nothing like a friend, unless it’s a friend with cake ❤️.",
      "A good friend knows all your stories, a best friend helped you write them ❤️.",
      "Your friend is the person who knows everything about you and still loves you ❤️.",
      "A real friend is someone who walks in when the rest of the world walks out ❤️.",
      "Friendship is born when two people discover they have something in common ❤️.",
    ],

    successQuote: [
      "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
      "Success usually comes to those who are too busy to be looking for it.",
      "Don’t wait for opportunity. Create it.",
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
      "The way to get started is to quit talking and begin doing.",
      "Success is the sum of small efforts, repeated day in and day out.",
      "The only limit to our realization of tomorrow is our doubts of today.",
      "Success is walking from failure to failure with no loss of enthusiasm.",
      "The harder you work for something, the greater you’ll feel when you achieve it.",
      "Don’t be afraid to give up the good to go for the great.",
      "Success doesn’t just find you. You have to go out and get it.",
      "Success is not in what you have, but who you are.",
      "Believe you can and you’re halfway there.",
      "The secret to success is to know something nobody else knows.",
      "Opportunities don’t happen, you create them.",
      "Success is not how high you have climbed, but how you make a positive difference to the world.",
      "Success is a journey, not a destination.",
      "Success is not about how many times you win, but how you rise when you fall.",
      "Your limitation—it’s only your imagination.",
      "The key to success is to focus on goals, not obstacles.",
      "Dream it. Wish it. Do it.",
      "Don’t stop when you’re tired. Stop when you’re done.",
      "Success is not for the chosen few. It’s for the few who choose it.",
      "I find that the harder I work, the more luck I seem to have.",
      "Success is achieved and maintained by those who try and keep trying.",
      "Success is not about what you accomplish in life, but what you inspire others to do.",
      "You don’t have to be great to start, but you have to start to be great.",
      "To be successful, you must accept all challenges that come your way. You can’t just accept the ones you like.",
      "Success is not the absence of failure; it’s the persistence through failure.",
      "If you are not willing to risk the usual, you will have to settle for the ordinary.",
      "Success is not about being the best. It’s about always getting better.",
      "It always seems impossible until it’s done.",
      "Success comes from having the courage, determination, and the will to become the person you believe you were meant to be.",
      "If you want to achieve greatness stop asking for permission.",
      "Success is the result of preparation, hard work, and learning from failure.",
      "Success is to be measured not so much by the position that one has reached in life as by the obstacles which he has overcome.",
      "The only place where success comes before work is in the dictionary.",
      "Do not wait to strike till the iron is hot, but make it hot by striking.",
      "The only way to do great work is to love what you do.",
      "Don’t wait for the perfect moment. Take the moment and make it perfect.",
      "The successful warrior is the average man, with laser-like focus.",
      "Success is about creating value.",
      "Success is liking yourself, liking what you do, and liking how you do it.",
      "Success is not about the destination, it’s about the journey.",
      "If you want to be successful, you have to be willing to fail a few times.",
      "Success is not in never falling, but in rising every time we fall.",
      "The road to success is always under construction.",
      "Success requires a single-minded focus on the goal and perseverance through every setback.",
      "You are never too old to set another goal or to dream a new dream.",
      "Success isn’t just about what you accomplish in your life; it’s about what you inspire others to do.",
      "Success is doing what you love and loving what you do.",
      "Success isn’t always about greatness. It’s about consistency.",
      "Hardships often prepare ordinary people for an extraordinary destiny.",
      "The best way to predict the future is to create it.",
      "Success doesn’t come from what you do occasionally, it comes from what you do consistently.",
      "Success is not how much money you make. It’s about the difference you make in people’s lives.",
      "Success is a mindset. If you believe you are successful, you will be.",
      "Success is the result of clear goals, determination, and hard work.",
      "To succeed in life, you need two things: ignorance and confidence.",
      "Success is the progressive realization of a worthy goal or ideal.",
      "Success means doing the best we can with what we have.",
      "Success is when your signature becomes an autograph.",
      "Do not wait for success to come to you, go out and get it.",
      "Success is when you’ve done all you can and still keep going.",
      "Success is the ability to go from one failure to another with no loss of enthusiasm.",
      "Success comes to those who hustle wisely.",
      "Success is not the absence of failure; it’s the persistence through failure.",
      "A real decision is measured by the fact that you’ve taken a new action. If there’s no action, you haven’t truly decided.",
      "The key to success is to start before you are ready.",
      "Success is what happens after you have survived all your mistakes.",
      "Success is a state of mind. If you want success, start thinking of yourself as a success.",
      "The more you praise and celebrate your life, the more there is in life to celebrate.",
      "Success does not consist in never making mistakes, but in never making the same one a second time.",
      "Success is not about what you do, but how you do it.",
      "Success is about going from failure to failure without losing your enthusiasm.",
      "Success is the result of preparation, hard work, and learning from failure.",
      "To succeed, we must first believe that we can.",
      "Success is a process, not an event.",
      "Success is the good fortune that comes from aspiration, desperation, perspiration, and inspiration.",
      "Success is when your heart and your brain are both working for you.",
      "The key to success is to focus on goals, not obstacles.",
      "The only time success comes before work is in the dictionary.",
      "Success is not a destination, it’s a journey.",
      "Success is going from failure to failure without losing your enthusiasm.",
      "Success is to be measured not by what you have, but by what you have overcome.",
      "Success is the sum of small efforts, repeated day in and day out.",
      "Success isn’t about how high you have climbed, but how you make a positive difference to the world.",
      "The first step toward success is taken when you refuse to be a captive of the environment in which you first find yourself.",
      "Success is found in the journey, not the destination.",
      "Success comes from doing what you love, and loving what you do.",
      "Success is when you don’t know what’s possible until you make it possible.",
      "Success is not just a goal, but a journey of growth and learning.",
      "Success is waking up every day knowing you’re doing what you love.",
      "Success is not what you have, but what you’ve achieved through hard work and perseverance.",
      "Success is the pursuit of excellence.",
      "Success is the ability to go from failure to failure without losing your enthusiasm.",
    ],

    happinessQuote: [
      "Happiness is not something ready made. It comes from your own actions.",
      "For every minute you are angry you lose sixty seconds of happiness.",
      "Happiness is a warm puppy.",
      "Happiness depends upon ourselves.",
      "Happiness is not by chance, but by choice.",
      "The purpose of life is not to be happy. It is to be useful, to be honorable, to be compassionate, to have it make some difference that you have lived and lived well.",
      "Happiness is the highest good.",
      "Happiness is when what you think, what you say, and what you do are in harmony.",
      "The most important thing is to enjoy your life – to be happy – it’s all that matters.",
      "Happiness is an inside job.",
      "Happiness is a journey, not a destination.",
      "The best way to cheer yourself is to try to cheer someone else up.",
      "Happiness is not the absence of problems, it's the ability to deal with them.",
      "The secret to happiness is freedom, and the secret to freedom is courage.",
      "Happiness is only real when shared.",
      "The only way to find true happiness is to risk being completely cut open.",
      "Happiness lies in the joy of achievement and the thrill of creative effort.",
      "Do not dwell in the past, do not dream of the future, concentrate the mind on the present moment.",
      "Happiness is a choice, not a result. Nothing will make you happy until you choose to be happy.",
      "Be happy for this moment. This moment is your life.",
      "Happiness is not a goal...it's a by-product of a life well lived.",
      "Happiness is the secret to all beauty. There is no beauty without happiness.",
      "The only thing that will make you happy is being happy with who you are.",
      "The most wasted of all days is one without laughter.",
      "Happiness is not a state to arrive at, but a manner of traveling.",
      "If you want to be happy, be.",
      "It is not how much we have, but how much we enjoy, that makes happiness.",
      "Happiness often sneaks in through a door you didn’t know you left open.",
      "Happiness is a form of courage.",
      "You don't have to be rich to be happy.",
      "Happiness is the absence of the striving for happiness.",
      "It’s not how much we have, but how much we enjoy, that makes happiness.",
      "Happiness is a warm blanket.",
      "If you want happiness for an hour, take a nap. If you want happiness for a year, inherit a fortune. If you want happiness for a lifetime, help someone else.",
      "Happiness is a state of mind. It's just according to the way you look at things.",
      "True happiness comes from within.",
      "Happiness is not in the mere possession of money; it lies in the joy of achievement, in the thrill of creative effort.",
      "Happiness is a choice, not a result.",
      "The happiness of your life depends upon the quality of your thoughts.",
      "Happiness is the best revenge.",
      "To be happy, we must be true to nature and carry our age along with us.",
      "Happiness depends on your mindset and attitude.",
      "Happiness is like a butterfly; the more you chase it, the more it will elude you.",
      "For it is in giving that we receive.",
      "Happiness is when what you think, what you say, and what you do are in harmony.",
      "A person’s happiness depends on the quality of their thoughts.",
      "Money can buy you a house, but not a home. Money can buy you a bed, but not sleep.",
      "There is no path to happiness. Happiness is the path.",
      "Happiness is the secret to all beauty. There is no beauty without happiness.",
      "If you don’t feel it, you’re not living it.",
      "Happiness is found when you stop comparing yourself to others.",
      "Happiness is the highest good.",
      "A smile is the simplest way to be happy.",
      "Happiness is not about getting all you want, it is about enjoying all you have.",
      "True happiness comes from the joy of deeds well done, the zest of creating things new.",
      "Happiness is a warm puppy and a good cup of tea.",
      "Happiness is not something you postpone for the future; it is something you design for the present.",
      "You are responsible for your own happiness.",
      "No one can make you feel inferior without your consent.",
      "Being happy never goes out of style.",
      "It’s not about being rich, it’s about being happy.",
      "Happiness can only exist in acceptance.",
      "People are as happy as they make up their minds to be.",
      "The most important thing is to enjoy your life – to be happy – it’s all that matters.",
      "The happiness of life is made up of minute fractions.",
      "Happiness is not something ready made. It comes from your own actions.",
      "Happiness is only real when shared.",
      "Happiness is when you believe it, you’re ready to receive it.",
      "Happiness is an attitude of gratitude.",
      "We don’t stop playing because we grow old; we grow old because we stop playing.",
      "You can never have too much happiness.",
      "Happiness doesn’t depend on what you have or who you are. It solely relies on what you think.",
      "The happiest people don’t have the best of everything, they make the best of everything.",
      "Happiness is an inside job.",
      "People are as happy as they decide to be.",
      "The best way to cheer yourself is to try to cheer someone else up.",
      "Happiness is not the absence of problems, but the ability to deal with them.",
      "Happiness is achieved when you stop waiting for it and make the most of the moment you are in.",
      "When you wish yourself happy, your whole world becomes happy.",
      "Don’t wait for happiness to come, create it.",
      "Happiness is the key to everything else.",
      "To be happy, you must first love yourself.",
      "The only way to find true happiness is to risk being completely cut open.",
      "Happiness comes when your work and words are of benefit to others.",
      "A happy family is but an earlier heaven.",
      "Happiness is a butterfly, which when pursued, is always just beyond your grasp, but which, if you will sit down quietly, may alight upon you.",
      "Happiness is the highest form of health.",
      "A day without laughter is a day wasted.",
      "You cannot protect yourself from sadness without protecting yourself from happiness.",
      "Happiness is always a choice, even when you face challenges.",
      "Happiness depends on what you can give, not on what you can get.",
      "When you have a reason to be happy, you will never miss a reason to smile.",
    ],

    lifeQuote: [
      "Life is what happens when you're busy making other plans.",
      "In the end, it's not the years in your life that count. It's the life in your years.",
      "Good things come to those who wait, but better things come to those who go out and get them.",
      "Life is 10% what happens to us and 90% how we react to it.",
      "Don’t watch the clock; do what it does. Keep going.",
      "Life is really simple, but we insist on making it complicated.",
      "Live life as if everything is rigged in your favor.",
      "It’s not about how hard you hit. It’s about how hard you can get hit and keep moving forward.",
      "Life is too important to be taken seriously.",
      "The purpose of life is not to be happy. It is to be useful, to be honorable, to be compassionate, to have it make some difference that you have lived and lived well.",
      "Live life with no regrets.",
      "Life is a beautiful struggle.",
      "The only impossible journey is the one you never begin.",
      "Life is what we make it, always has been, always will be.",
      "To live is the rarest thing in the world. Most people exist, that is all.",
      "In three words I can sum up everything I've learned about life: it goes on.",
      "Life isn’t about waiting for the storm to pass, it’s about learning how to dance in the rain.",
      "Life is a gift, and it offers us the privilege, opportunity, and responsibility to give something back by becoming more.",
      "You have within you right now, everything you need to deal with whatever the world can throw at you.",
      "Live your life in such a way that if someone spoke badly of you, no one would believe it.",
      "The best way to predict your future is to create it.",
      "Life is either a daring adventure or nothing at all.",
      "The longer I live, the more I realize the impact of attitude on life.",
      "In the end, we will remember not the words of our enemies, but the silence of our friends.",
      "Don’t go through life, grow through life.",
      "Sometimes the hardest thing and the right thing are the same.",
      "Life begins at the end of your comfort zone.",
      "Life is 10% what happens to you and 90% how you react to it.",
      "The purpose of life is to contribute in some way to making things better.",
      "Life is a journey that must be traveled no matter how bad the roads and accommodations.",
      "You only live once, but if you do it right, once is enough.",
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
      "Life’s most persistent and urgent question is, ‘What are you doing for others?’",
      "Life without love is like a tree without blossoms or fruit.",
      "Life is not measured by the number of breaths we take, but by the moments that take our breath away.",
      "The good life is one inspired by love and guided by knowledge.",
      "Life is like riding a bicycle. To keep your balance, you must keep moving.",
      "A life is not important except in the impact it has on other lives.",
      "Life is the flower for which love is the honey.",
      "You only live once, but if you do it right, once is enough.",
      "The purpose of life is not to be happy, but to be worthy of happiness.",
      "Life is short, and it's up to you to make it sweet.",
      "Life is an adventure, it’s not a package tour.",
      "Life is a succession of lessons which must be lived to be understood.",
      "Do not take life too seriously. You will never get out of it alive.",
      "Life isn’t about finding yourself. Life is about creating yourself.",
      "The best way to live a good life is to live with good people.",
      "Life is not about waiting for the storm to pass, it’s about learning to dance in the rain.",
      "A journey of a thousand miles begins with a single step.",
      "Life is about creating and living experiences that are worth sharing.",
      "It’s not the length of life, but the depth of life.",
      "Life isn’t measured by the breaths you take, but by the moments that take your breath away.",
      "You must be the change you wish to see in the world.",
      "Life is a question and how we live it is our answer.",
      "Life is too short to wait.",
      "Life is a canvas, and you are the artist.",
      "Life is what you make of it.",
      "Life’s challenges are not supposed to paralyze you, they’re supposed to help you discover who you are.",
      "Life is an opportunity, benefit from it.",
      "Life can only be understood backwards; but it must be lived forwards.",
      "Every man dies. Not every man really lives.",
      "Life isn’t about finding yourself. It’s about creating yourself.",
      "The good life is one that is lived in the moment, with joy, love, and peace.",
      "Life has no limitations, except the ones you make.",
      "The biggest adventure you can take is to live the life of your dreams.",
      "Don’t count the days, make the days count.",
      "Life is about the people you meet and the things you create with them.",
      "Life is a beautiful thing, and there’s so much to smile about.",
      "Life is better when you’re laughing.",
      "Keep your face always toward the sunshine—and shadows will fall behind you.",
      "When life gives you lemons, make lemonade.",
      "If life were predictable it would cease to be life, and be without flavor.",
      "Life is a one-time offer, use it well.",
      "You don’t have to be perfect to be amazing in life.",
      "Every life is a story. Make yours a bestseller.",
      "The life in front of you is more important than the life behind you.",
      "Life is too important to be taken seriously.",
      "The only way to live is to accept each minute as an unrepeatable miracle.",
      "Life is what you make it, so make it count.",
      "Life is not about waiting for the storm to pass, it’s about learning how to dance in the rain.",
      "The goal of life is not to be happy. It is to be useful, to be honorable, to be compassionate, to have it make some difference that you have lived and lived well.",
      "Don't wait for the perfect moment. Take the moment and make it perfect.",
      "Sometimes you have to let go of the life you planned, to make room for the life that is waiting for you.",
      "Life isn’t perfect, but it has perfect moments.",
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
      "Life is made up of moments, and each moment is precious.",
      "If life gives you lemons, make lemonade and then find someone whose life has given them vodka.",
      "To live a life of purpose, start by believing in yourself.",
      "Life is too short to be anything but happy.",
      "Live life to the fullest, and focus on the positive.",
      "Every life has its ups and downs. It’s how you handle the challenges that count.",
      "Life is what you make of it. Don't waste it.",
    ],

    wisdomQuote: [
      "Wisdom is not a product of schooling but of the lifelong attempt to acquire it.",
      "The only true wisdom is in knowing you know nothing.",
      "Wisdom begins in wonder.",
      "Wisdom is the reward you get for a lifetime of listening when you'd rather talk.",
      "Knowing others is intelligence; knowing yourself is true wisdom.",
      "Do not seek to follow in the footsteps of the wise. Seek what they sought.",
      "A fool thinks himself to be wise, but a wise man knows himself to be a fool.",
      "Wisdom is the daughter of experience.",
      "Wisdom is the ability to see the world as it is, and choose to act in a way that serves the greatest good.",
      "The wise man does at once what the fool does finally.",
      "A wise man can learn more from a foolish question than a fool can learn from a wise answer.",
      "The only real wisdom is knowing you know nothing.",
      "Time is the most valuable thing a man can spend.",
      "It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.",
      "He who has a why to live can bear almost any how.",
      "The unexamined life is not worth living.",
      "Do not go where the path may lead, go instead where there is no path and leave a trail.",
      "The greatest wisdom is in simplicity.",
      "Wise men speak because they have something to say; fools because they have to say something.",
      "A wise person should have money in their head, but not in their heart.",
      "Silence is one of the great arts of conversation.",
      "The wise adapt themselves to circumstances, as water molds itself to the pitcher.",
      "Knowledge speaks, but wisdom listens.",
      "Do not argue with an idiot. He will drag you down to his level and beat you with experience.",
      "Wisdom comes from experience. Experience is often a result of lack of wisdom.",
      "It is not length of life, but depth of life.",
      "The only way to deal with this life meaningfully is to find one's passion.",
      "He who learns but does not think, is lost. He who thinks but does not learn is in great danger.",
      "Knowledge is learning something every day. Wisdom is letting go of something every day.",
      "It is better to be a fool for a moment than to be a fool for a lifetime.",
      "Wisdom is not a destination; it's a journey.",
      "The fool doth think he is wise, but the wise man knows himself to be a fool.",
      "It’s not what happens to you, but how you react to it that matters.",
      "A wise man does not seek to control his circumstances, but adapts to them.",
      "A man’s true wealth is the good he does in the world.",
      "The older I grow, the more I realize the importance of the people who take time for me.",
      "In the end, we only regret the chances we didn’t take.",
      "True wisdom lies in understanding how little we know.",
      "Patience is a key element of success.",
      "Wisdom is knowing the right path to take. Integrity is taking it.",
      "A problem is a chance for you to do your best.",
      "It is wise to direct your mind towards the positive, for the negative leads nowhere.",
      "The more I know, the more I realize I know nothing.",
      "Never be deceived that the rich will allow you to vote away their wealth.",
      "There is no remedy for love but to love more.",
      "The wisdom of life consists in the elimination of non-essentials.",
      "The more you know, the more you realize you don’t know.",
      "Wisdom is not a matter of age. It's about experience.",
      "Wise men don't need advice. Fools won’t take it.",
      "The greatest wisdom is kindness.",
      "The only source of knowledge is experience.",
      "Wisdom is the daughter of experience, but experience is the daughter of life.",
      "Wisdom is to listen more than you speak.",
      "A wise person knows that there is always more to learn.",
      "The highest form of wisdom is kindness.",
      "A wise man can always learn more from a fool than a fool can learn from a wise man.",
      "Learning never exhausts the mind.",
      "To be wise is to be forever learning.",
      "It is a wise man who knows that he knows nothing.",
      "It is not how old you are, but how you are old.",
      "Wisdom is not in how much you know, but how you apply what you know.",
      "A wise person knows their limitations.",
      "He who is wise in heart will be called discerning.",
      "The fool looks for happiness in the future, but the wise enjoy the present.",
      "The fool is wise in his own eyes, but the wise are humble in their judgment.",
      "A wise person is someone who is not swayed by emotional influences but stays calm and collected in times of turmoil.",
      "Wisdom comes from experience and reflection.",
      "A person who is wise will act in the best interest of others, not just themselves.",
      "The more you teach, the more you learn.",
      "It is better to know nothing than to know a little.",
      "A wise man does not say everything he thinks, but thinks everything he says.",
      "Wisdom is the ability to think and act using knowledge, experience, understanding, common sense, and insight.",
      "A wise person does not just accumulate knowledge, but makes use of it.",
      "A wise man never knows all, only fools know everything.",
      "Time reveals the truth, and the wise are patient enough to see it.",
      "True wisdom is knowing the right time to speak and the right time to remain silent.",
      "Wisdom is the ability to choose correctly when faced with difficult decisions.",
      "The true wisdom is in knowing that you don’t have to have all the answers.",
      "Wisdom is the ability to use knowledge and experience for the benefit of others.",
      "Don’t judge a man until you have walked a mile in his shoes.",
      "The older I get, the more I realize that true happiness comes from finding peace with yourself.",
      "Wisdom is choosing your thoughts carefully and focusing on the good.",
      "A wise man learns more from his enemies than a fool from his friends.",
      "Wisdom is realizing that you don’t need to control everything around you.",
      "Sometimes wisdom comes from knowing when to ask for help.",
      "Wisdom is the art of living with grace, humility, and patience.",
      "A fool never learns, but a wise man will learn from his mistakes.",
      "True wisdom is not learned, it is earned.",
      "To acquire wisdom, one must often go through hardship and struggle.",
      "A wise person lives in the moment, but learns from the past and plans for the future.",
      "Wisdom is realizing that life is a journey, not a destination.",
      "A wise person is someone who seeks to understand before being understood.",
      "Wisdom is knowing how to handle success with humility and failure with grace.",
      "The path to wisdom is never straight, but always worth the journey.",
      "True wisdom is understanding that we don’t always have control over the events in our lives, but we do have control over how we react to them.",
    ],
  };

  return (
    <>
      <NavBar />
      <div className="main-container-hindi">
      <div className="uper-cont">
          <div className="box-containt-up">
            {quoteList.map((event) => (
              <div
                key={event.id}
                className={`box-up ${
                  selectCat === event.category ? "selected" : ""
                }`}
                onClick={() => handleSelecttab(event.category)}
              >
                <h3>{event.msg}</h3>
              </div>
            ))}
          </div>

          <div className="res-selection">
            <select
              name="selecttab"
              id="selecttab"
              onChange={handleCategoryChange}
            >
              <option value="default" disabled>
                Please select
              </option>
              {quoteList.map((option) => (
                <option key={option.value} value={option.category}>
                  {option.msg}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="lower-container-quotes">
          <ul>
            {categories[selectCat].map((item, index) => (
              <li key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"end"}}>{item}  
              <span onClick={() => copyText(item)}  style={{color:'white', display:"block", borderRadius:'5px', fontSize:'14px',
                 justifyContent: 'flex-end', background:'#2F327D', padding:"1px 10px 1px 10px", textAlign:'center', width:"60px", height:"26px", marginLeft:"20px", cursor:"pointer"}}>Copy</span></li>
            ))}
          </ul>
        </div>

         {/* --------------responsive-------------- */}
        <div className="lower-container-quotes-res">
          <ul>
            {categories[selectedCategory].map((item, index) => (
        <li key={index} style={{display:"flex", justifyContent:"space-between", alignItems:"end"}}>{item}  
        <span onClick={() => copyText(item)}  style={{color:'white', display:"block", borderRadius:'5px', fontSize:'14px',
           justifyContent: 'flex-end', background:'#2F327D', padding:"1px 10px 1px 10px", textAlign:'center', width:"60px", height:"26px", marginLeft:"20px", cursor:"pointer"}}>Copy</span></li>
        
            ))}
          </ul>
        </div>
        {/* ---------------end---------------------- */}

      </div>
      <div className="footer-punjabi">
        <InnerFooter />
      </div>
    </>
  );
}
