import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import English from './screens/English'
import Punjabi from './screens/Punjabi';
import Hindi from './screens/Hindi'
import Scrolltotop from './utils/Scrolltotop';
import Maths from './screens/Maths';
import ContactUs from './screens/ContactUs';
import AboutUs from './screens/AboutUs';
import TermsAndConditions from './screens/TermsAndConditions';
import Privacy from './screens/Privacy';
import AttributesAndCredits from './screens/AttributesAndCredits';
import UpcomingUpdates from './screens/UpcomingUpdates';
import OurVision from './screens/OurVision';
import Quotes from './screens/Quotes';
import Wish from './screens/Wish';
import Learning from './screens/Learning';
import Socialvibes from './screens/Socialvibes';

function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <Scrolltotop />
        <Routes>
          <Route element={<Home to="/" />} path="/" />
          <Route path="/English" element={<English />} />
          <Route path="/Punjabi" element={<Punjabi />} />
          <Route path="/Hindi" element={<Hindi />} />
          <Route path="/Maths" element={<Maths />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/AttributesAndCredits" element={<AttributesAndCredits />} />
          <Route path="/UpcomingUpdates" element={<UpcomingUpdates />} />
          <Route path="/OurVision" element={<OurVision />} />
          <Route path="/Wish" element={<Wish />}></Route>
          <Route path="/Quotes" element={<Quotes />}></Route>
          <Route path="/Learning" element={<Learning />}></Route>
          <Route path="/Socialvibes" element={<Socialvibes />}></Route>

        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;